
// https://angular-maps.com/api-docs/agm-core/components/agminfowindow
import { Component, OnInit,OnDestroy, Input } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
import { Subscription } from 'rxjs';

import {SqlserviceService} from '../../services/sql/sqlservice.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LISTA_TRANSPORTES_INFOTRAK } from '../../util/tablas/lista_transportes_infotrak';
import { TransporteInfotrak } from '../../util/objetos/transportes_infotrak';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit, OnDestroy {
   @Input() idInfotrak;
  vehiculos:any=null;
  zoom: number = 12;   /* 15 */
  lat: number = -35.464475; 
  lng: number = -60.8467927;
  label:string="";
  etiquetaMapa:string='NutralMix';
  timerSuscription:Subscription=null;

    icono:any= {
        // url: '../../assets/images/Truck-icon.ico',
        url: '../../assets/images/general/ic_mappin.png',
        scaledSize: {
            width: 28,
            height: 36,
        }
        // ,
        //  'background-color': 'blue',

        // transform: 'rotate(45deg)'

      };
      icono_def:any= {
       
        url: '../../assets/images/general/ic_mappin.png',
        scaledSize: {
            width: 28,
            height: 36,
        }
      };
      icono_N:any= {
       
        url: '../../assets/images/iconos/camion/N.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_S:any= {
       
        url: '../../assets/images/iconos/camion/S.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
       icono_E:any= {
       
        url: '../../assets/images/iconos/camion/E.png',
        scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_O:any= {
       
        url: '../../assets/images/iconos/camion/O.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };



      icono_NO:any= {
       
        url: '../../assets/images/iconos/camion/NO.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
       icono_NE:any= {
       
        url: '../../assets/images/iconos/camion/NE.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_SE:any= {
       
        url: '../../assets/images/iconos/camion/SE.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };

      icono_SO:any= {
       
        url: '../../assets/images/iconos/camion/SO.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };

   mensajeSpinner = "mensaje spinner";
   showSpinner:boolean = true;
   mensajeError:String = '';

ListaTransporteInfotrak:TransporteInfotrak[]=LISTA_TRANSPORTES_INFOTRAK;
    //  icono= {
    //     path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145  5,90 95,90 z',
    //     scale: 1,
    //     fillColor: "red",
    //     fillOpacity: 0.8,
    //     strokeWeight: 2,
    //     rotation: 150 //this is how to rotate the pointer
    // };

    // icono triangulo
    //  icono=  {
    //   // path: 'M 0 0 L -35 -100 L 35 -100 z',
    //   // path: '../../assets/images/Truck-icon.svg',  (esto no funciona)
    //   path :'M156 1368 c-4 -13 -25 -222 -22 -225 0 0 145 -28 321 -60 l320 -60 6 56 c4 31 7 91 8 133 1 76 1 76 -26 82 -16 3 -145 22 -288 41 -143 20 -272 38 -288 41 -18 4 -28 1 -31 -8z',
    //   fillColor: '#3884ff',
    //   fillOpacity: 0.7,
    //   scale: 1,
    //   strokeColor: '#356cde',
    //   rotation: 90, //rota
    //   strokeWeight: 1
    // };

  constructor(public sql:SqlserviceService,public modal: NgbActiveModal) { }

  ngOnInit() {
      console.log("idinfotrack",this.idInfotrak);
        const source = timer(500,180000); // se dispara cada 10000 ms (10seg) el primero es a los 50 ms
      //output: 0,1,2,3,4,5......               // 1000->1 seg, 60000-> 1min , 180000-> 3min
 
    this.timerSuscription = source.subscribe(val =>{
      console.log(val);
      this.getVehiculos();
    });
   
  }

getVehiculos(){
    console.log("getVehiculos");
      const s= this.sql.getVehiculos().subscribe(data=>
         {
        console.log(data);
        console.log("getVehiculos",data);
        this.vehiculos=data;
        this.getUbicacionTransporte(this.idInfotrak);
 
        this.showSpinner=false;
        console.log("getVehiculos showSpinner", this.showSpinner);
        s.unsubscribe();
          },
          error =>{console.log("getVehiculos",error);
              this.showSpinner=false;
              console.log("getVehiculos showSpinner", this.showSpinner);
              this.mensajeError="Se produjo un error: /n getVehiculos: " +error.name+"/n Mensaje: "+error.message+"/n";
        s.unsubscribe();
         });
}

getUbicacionTransporte(idInfotrak){
  console.log("idInfotrak",idInfotrak);
  console.log("idInfotrak lat",this.lat);
  console.log("idInfotrak lng",this.lng);
  let vehiculoSeleccionado=null
  this.vehiculos.forEach(function(vehiculo,index){

    if(vehiculo.id==idInfotrak){

       vehiculoSeleccionado=vehiculo;


    }
    });
  for (var i = 0; i < this.ListaTransporteInfotrak.length; i++) {
      if(this.ListaTransporteInfotrak[i].id_infotrak==idInfotrak){
        this.etiquetaMapa=this.ListaTransporteInfotrak[i].chofer + ' - ' +this.ListaTransporteInfotrak[i].telefono;
      } 
  }
  
  console.log("vehiculoSeleccionado vehiculoSeleccionado",vehiculoSeleccionado);
  if(vehiculoSeleccionado){
      this.lat=vehiculoSeleccionado.latitud;
      this.lng=vehiculoSeleccionado.longitud;


      switch (vehiculoSeleccionado.sentido) {
        
        case "Norte":
          this.icono=this.icono_N;
          break;
        case "Sur":
          this.icono=this.icono_S;
          break;
        case "Este":
          this.icono=this.icono_E;
          break;
        case "Oeste":
          this.icono=this.icono_O;
          break;  
        case "NorEste":
          this.icono=this.icono_NE;
          break;    
        case "NorOeste":
          this.icono=this.icono_NO;
          break;
        case "SurEste":
          this.icono=this.icono_SE;
          break;
        case "SurOeste":
          this.icono=this.icono_SO;
          break;

        default:

          this.icono=this.icono_def;
          break;
      }
    }

      // patente
      // sentido
      // velocidad
}
  ngOnDestroy(){
    console.log("OnDestroy");
    if(this.timerSuscription){
      this.timerSuscription.unsubscribe();
    }
  }
}
