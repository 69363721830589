
 

    <div class="modal-content " style="padding:8px;">
        <div class="modal-header state modal-primary">
            <h4 class="modal-title">{{ options.title}}</h4>
          <button type="button" style="float: right;
          font-size: 21px;
          font-weight: 700;
          line-height: 1;
          color: #000;
          padding: 6px;
          text-shadow: 0 1px 0 #fff;
          filter: alpha(opacity=20);
          opacity: .2;" aria-label="Close" (click)="close()">

            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span style="white-space: pre-line">{{ options.message }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="ok()" translate>ok</button>
        </div>  
        
        </div>