<div class="container-fluid">
    <div class="row">

        <!-- BIENVENIDA -->
        <div class="col-lg-8 order-lg-2 bienvenida bg-2"><!-- .bg-1 / .bg-2-->
            <div class="texto">
                <h3>Bienvenido de vuelta!</h3>
                <p>Para mantenerte conectado con nosotros, por favor logueate con tus credenciales.</p>
            </div>
        </div>
        <!-- FIN de BIENVENIDA -->

        <!-- ESTRUCTURA LOGIN/REG -->
        <div class="col-lg-4 order-lg-1 login">
            <div class="inner-login">

                <a href="#" class="logo">Nutral Mix &reg;</a>

                <!-- LOGIN -->
                <h3>Login</h3>
                <form [formGroup]="crearLabelForm">
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Ingrese su e-mail"  formControlName="email" autocomplete="off">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña"  formControlName="clave" autocomplete="off">
                </div>
                </form>
                <div class="form-group clearfix">
                    <a href="#" class="forgot">¿Olvidaste tu contraseña?</a>
                    <button class="btn btn-primary float-right" (click)="signIn()">Login</button>
                </div>
                <span class="separator">o</span>
                <a href="#" class="google">Login con Google</a>
                <p>Todavía no tenés cuenta? <a href="#">Registrarme</a></p>
                <!-- FIN de LOGIN -->

                <!-- REGISTRACION -->
                <h3>Registración</h3>
                <form [formGroup]="crearLabelForm">
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Ingrese su e-mail"  formControlName="email" autocomplete="off">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña"  formControlName="clave" autocomplete="off">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Repetir Contraseña"  formControlName="clave" autocomplete="off">
                </div>
                </form>
                <div class="form-group clearfix">
                    <button class="btn btn-primary" (click)="signIn()">Enviar</button>
                </div>
                <p>Ya tenés un cuenta? <a href="#">Login</a></p>
                <!-- FIN de REGISTRACION -->

            </div>
        </div>
        <!-- FIN de ESTRUCTURA LOGIN/REG -->

    </div>
</div>
