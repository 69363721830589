<div class="component pedidos" *ngIf="editar==false" >

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

           <!--  <p (click)="enviarMail()" >envio mail   </p> -->
            <h2>Pedidos Tango 

                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']==null  ">({{listadoPrincipal.length  }}) </span>
                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']=='Entregado'  ">({{cantidadPedidosEntregados  }}) </span>
                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']!='Entregado' ">({{listadoPrincipal.length-cantidadPedidosEntregados  }}) </span>

                <!-- <span *ngIf="listadoPrincipal && buscarEnTransporte && buscarEntregados  ">({{listadoPrincipal.length  }}) </span>
                <span *ngIf="listadoPrincipal && !buscarEnTransporte && buscarEntregados  ">({{cantidadPedidosEntregados  }}) </span>
                <span *ngIf="listadoPrincipal && buscarEnTransporte && !buscarEntregados  ">({{listadoPrincipal.length-cantidadPedidosEntregados  }}) </span> -->

            </h2>

            <select class="form-control selectpicker float-right selectEmpresa" id="empresaSelected"
                    (change)="onChangeEmpresaSelected()"
                    [(ngModel)]="empresaSelected" 
                    [compareWith]="compareEmpresas">
                <option  [ngValue]=''>Empresa</option>
                <option  *ngFor="let empresa of perfilUsuario?.data.empresas" [ngValue]="empresa" >{{empresa?.RAZON_SOCI}}</option>
            </select>

            <!-- <span class="etiqueta black" *ngIf="empresaSelected">{{empresaSelected.RAZON_SOCI  }}</span> -->
            <!-- <div class="text-center">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"  [(ngModel)]=buscarEnTransporte (change)="buscarEnTransporteCambio(buscarEnTransporte)">
                    <label class="form-check-label" for="inlineCheckbox1">En Transporte</label>
                </div>
                 <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2"  [(ngModel)]="buscarEntregados" (change)="buscarEntregadosCambio(buscarEntregados)">
                    <label class="form-check-label" for="inlineCheckbox2">Entregados</label>
                </div>
            </div> -->
        </div>



        <div class="row inner-estructura">

            <div class="col-sm-12">

                
                <div class="text-center" > <div class="loading" *ngIf="showSpinner"> </div></div>

                <div *ngIf="mensaje" >{{mensaje}}</div>
          

                <!-- iniciolistado -->
                <!-- inicio listado -->
                
                

                <table *ngIf="listadoPrincipal.length>0" class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt" 
                       style=" position:relative; margin:auto;   width:100%;">
                    <thead>
                        <tr>
                            
                            <th (click)="setOrden('NRO_PEDIDO')">
                                
                                <span class="" *ngIf="ordenCampo!='NRO_PEDIDO'">Pedido</span>
                                <span class="text-primary" *ngIf="ordenCampo=='NRO_PEDIDO'">Pedido</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='NRO_PEDIDO' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='NRO_PEDIDO' && !ordenReverse" ></i>
                            </th>
                        
                            <th (click)="setOrden('FECHA_PEDI')">
                                
                                <span class="" *ngIf="ordenCampo!='FECHA_PEDI'">F. Ped</span>
                                <span class="text-primary" *ngIf="ordenCampo=='FECHA_PEDI'">F. Ped</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='FECHA_PEDI' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='FECHA_PEDI' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('FECHA_ENTR')">
                                
                                <span class="" *ngIf="ordenCampo!='FECHA_ENTR'">F. Entrega</span>
                                <span class="text-primary" *ngIf="ordenCampo=='FECHA_ENTR'">F. Entrega</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='FECHA_ENTR' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='FECHA_ENTR' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('N_REMITO')">
                                
                                <span class="" *ngIf="ordenCampo!='N_REMITO'">Remito</span>
                                <span class="text-primary" *ngIf="ordenCampo=='N_REMITO'">Remito</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='N_REMITO' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='N_REMITO' && !ordenReverse" ></i>
                            </th>
                        
                            <th (click)="setOrden('FECHA_REMITO')">
                                
                                <span class="" *ngIf="ordenCampo!='FECHA_REMITO'">F. Rem</span>
                                <span class="text-primary" *ngIf="ordenCampo=='FECHA_REMITO'">F. Rem</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='FECHA_REMITO' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='FECHA_REMITO' && !ordenReverse" ></i>
                            </th>
                           
 
                            <th (click)="setOrden('NOMBRE_TRA')">

                                <span class="" *ngIf="ordenCampo!='NOMBRE_TRA'">Transportista</span>
                                <span class="text-primary" *ngIf="ordenCampo=='NOMBRE_TRA'">Transportista</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='NOMBRE_TRA' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='NOMBRE_TRA' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('ACEPTA_ENTREGA_SIN_CLIENTE')"  style="width: 155px;">

                                <span class="" *ngIf="ordenCampo!='ACEPTA_ENTREGA_SIN_CLIENTE'">Modo</span>
                                <span class="text-primary" *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE'">Modo Entrega</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE' && !ordenReverse" ></i>
                            </th>

                            <th (click)="setOrden('ESTADO_ENTREGA_TRANSPORTISTA')"  style="width: 165px;" >

                                <span class="" *ngIf="ordenCampo!='ESTADO_ENTREGA_TRANSPORTISTA'">Estado</span>
                                <span class="text-primary" *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA'">Estado</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA' && !ordenReverse" ></i>
                            </th>

                            <th  style="width: 165px;" >

                                <span class="" >Acciones</span>
                                
                            </th>

                        </tr>

                        <tr>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="NRO_PEDIDO"  
                                        [(ngModel)]="filtro['NRO_PEDIDO']" 
                                        placeholder="Pedido"/>
                            </th>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="FECHA_PEDI"  
                                        [(ngModel)]="filtro['FECHA_PEDI']" 
                                        placeholder="F. Ped"/>
                            </th>    
                            <th>
                                <input  class="form-control" 
                                        type="text" id="FECHA_ENTR"  
                                        [(ngModel)]="filtro['FECHA_ENTR']" 
                                        placeholder="F. Entr"/>
                            </th>    
                            <th>
                                <input  class="form-control" 
                                        type="text" id="N_REMITO"  
                                        [(ngModel)]="filtro['N_REMITO']" 
                                        placeholder="Remito"/>
                            </th>
                        <th>
                            <input  class="form-control" 
                                    type="text" id="FECHA_REMITO"  
                                    [(ngModel)]="filtro['FECHA_REMITO']" 
                                    placeholder="F. Rem"/>
                        </th>
                      
                       
                        <th>
                            <input  class="form-control" 
                                    type="text" id="NOMBRE_TRA"  
                                    [(ngModel)]="filtro['NOMBRE_TRA']" 
                                    placeholder="Transportista"/>
                        </th>
       
                        <th>
                            <select class="form-control selectpicker" id="ACEPTA_ENTREGA_SIN_CLIENTE"
                                        [(ngModel)]="filtro['ACEPTA_ENTREGA_SIN_CLIENTE']">
                                    <option  [ngValue]=null>Modo</option>
                                    <option  [ngValue]=true>C.Cliente</option>
                                    <option  [ngValue]=false>S.Cliente</option>
                            </select>
                        
                        </th>
                    
                        <th>
                            
                            <select class="form-control selectpicker" id="ESTADO_FILTRO"
                                    (change)="cambiofiltro()"
                                    [(ngModel)]="filtro['ESTADO_FILTRO']" >
                                <option  value=''>Estado</option>
                                <option  value='NoAprobado'>No Aprob</option>
                                <option  value='Produccion'>Producción</option>
                                <option  value='Transporte'>Transporte</option>
                                <option  value='Entregado'>Entregado</option>
                                
                            </select>

                        </th>

                        <th class="text-center" >
                            <span class="mdi mdi-24px mdi-broom"  title="Limpiar filtros" (click)="limpiarfiltros()"  ></span>
                    
                        </th>
                    </tr>
             </thead>
  
             <tbody class="accordion" id="accordionTable">    
              
                <ng-container *ngFor="let documento of listadoPrincipal
                         | orderBy :ordenCampo :ordenReverse    :'case-insensitive'  
                         | searchFiltro:'local':'NRO_PEDIDO'    :filtro['NRO_PEDIDO'] 
                         | searchFiltro:'local':'FECHA_PEDI'    :filtro['FECHA_PEDI'] 
                         | searchFiltro:'local':'FECHA_ENTR'    :filtro['FECHA_ENTR'] 
                         | searchFiltro:'local':'N_REMITO'      :filtro['N_REMITO'] 
                         | searchFiltro:'local':'FECHA_REMITO'  :filtro['FECHA_REMITO'] 
                         | searchFiltro:'local':'NOMBRE_TRA'    :filtro['NOMBRE_TRA'] 
                         | searchFiltro:'local':'ESTADO_FILTRO' :filtro['ESTADO_FILTRO'] 
                         | searchFiltro:'local':'ACEPTA_ENTREGA_SIN_CLIENTE':filtro['ACEPTA_ENTREGA_SIN_CLIENTE']:'boolean'
                         | paginate: { itemsPerPage: grilla.paginadoCantidad, 
                             currentPage : pagePaginado,
                             totalItems  : cantidadFiltro}; let cantidadFiltro=count ;
                             
                         let i=index    " >
                
                     <tr [ngClass]="{'filaSeleccionada': mostrarDetalle[documento.N_REMITO]}" >

                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.NRO_PEDIDO}}<br>  {{documento['Desc_Estado']}}     
                        </td>
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.FECHA_PEDI}}
                        </td>
                    
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.FECHA_ENTR}}
                        </td>
                  
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
 
                            <a href="javascript:;" *ngIf="documento.N_REMITO !=' 0000000000000' " ngbTooltip="Ver Remito" (click)="setPedidoSelected(documento)" >{{documento.N_REMITO}} </a>

                        </td>        
 
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                           {{documento.FECHA_REMITO}}
                        </td>
                        <!-- style="  overflow:hidden;    white-space:nowrap; text-overflow: ellipsis;" -->
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.NOMBRE_TRA}}
                        </td>
                        <td class="text-center" (click)="visualizarDetalle(documento.N_REMITO)" >
                            <i  title="Entrega con el cliente presente" *ngIf="!documento.ACEPTA_ENTREGA_SIN_CLIENTE"    class="text-danger  mdi mdi-24px mdi-boom-gate-outline"></i>
                            <i  title="Entregar sin el cliente" *ngIf="documento.ACEPTA_ENTREGA_SIN_CLIENTE"     class="text-success mdi mdi-24px mdi-boom-gate-up-outline"></i>
                        </td>
                        
                        <td   class="text-center">
                          
                            <i title="en Procucción"  *ngIf="documento.N_REMITO==' 0000000000000' " class="text-warning mdi mdi-24px mdi-factory"></i>
                            <i title="sin Id Infotrack"  *ngIf="documento.N_REMITO!=' 0000000000000' && !documento.ID_INFOTRAK "  class="text-danger mdi mdi-24px mdi-crosshairs-gps"></i>
                            <i title="Entregar"  *ngIf="documento.N_REMITO!=' 0000000000000' && documento.ID_INFOTRAK && !documento.ESTADO_ENTREGA_TRANSPORTISTA" (click)="confirmarNotificarEntrega(documento,i)" class="mdi mdi-24px mdi-truck-fast"></i>
                            <i title="Entregado" *ngIf="documento.N_REMITO!=' 0000000000000' && documento.ID_INFOTRAK &&  documento.ESTADO_ENTREGA_TRANSPORTISTA" class="text-success mdi mdi-24px mdi-truck-check"></i>
                        </td>
                        <td  title="Mapa" class="text-center" >
                            <span (click)="mostrarMapa(documento.ID_INFOTRAK)"  *ngIf="documento.ID_INFOTRAK && documento.ESTADO_ENTREGA_TRANSPORTISTA!='Entregado '"   class="mr-1 mdi mdi-24px mdi-map-marker-multiple"></span>
                            <span title="Clonar Pedido Web" (click)="clonarPedido(documento)"   class="text-primary ml-1 mdi mdi-24px mdi-content-copy"></span>
        
                        </td>
                    </tr>
                    <tr *ngIf="mostrarDetalle[documento.N_REMITO]">
                        <td colspan="9"  style="background-color: white;">
                         
                            <div class="tarjetaDetalle">
                        
                            
                                <div class="row tarjetaTitulo ">
                                    <div class="col-1"></div>
                                    <div class="col-4" style="font: bold;">PRODUCTO</div>
                                    <div class="col-3 text-center" style="font: bold;">CANTIDAD PEDIDO</div>
                                    <div class="col-3 text-center" style="font: bold;">CANTIDAD REMITO</div>
                                </div>

                                <div class="row tarjetaDetalleRow " *ngFor="let detalle of documento.detalle">
                                    <div class="col-1"></div>
                                    <div class="col-4">{{detalle?.DESCRIPCIO}}</div>
                                    <div class="col-3 text-center">{{detalle?.CANT_PEDID}}  {{detalle?.ID_MEDIDA_STOCK}}</div>
                                    <div class="col-3 text-center">{{detalle?.CANT_REMITO}} {{detalle?.ID_MEDIDA_STOCK}}</div>
                                </div>
                            </div>   
                
                        </td>                            
                    </tr>
                </ng-container>    
             </tbody>
         </table>

 
         <!-- nuevo Paginado -->
         <div class="row" style="border-top:1px solid #ccc;" *ngIf="listadoPrincipal.length>0">
             <div class="col-3" style="padding:10px;">
                 <div class="bg-white text-center">
                     <select class="form-control selectpicker" 
                             id="grilla.paginadoCantidad"                                         
                             [(ngModel)]="grilla.paginadoCantidad"
                             (change)="onChangeGrilla('paginadoCantidad',grilla.paginadoCantidad)"> 
                         <ng-container *ngFor="let opcion of grilla.paginadoDropDown">
                             <option [ngValue]="opcion">Items x Página {{opcion}}</option>
                         </ng-container>
                     </select>
                 </div>    
             </div>    
             <div class="col-9" style="padding:10px;">
                 <div class="bg-white text-center">

         
                        <pagination-controls 
                         autoHide="true" 
                         previousLabel="{{'anterior' }}" 
                         nextLabel="{{'siguiente'}}" 
                         (pageChange)="pagePaginado = $event" 
                         maxSize="15"></pagination-controls>

                    </div>
                </div>                        
            </div>
      
                
                
                
                
                <!-- fin listado -->
                <!-- fin listado -->







            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
<app-pedido-creado-detalle *ngIf="editar==true" (setEditar)="setEditar($event)">

</app-pedido-creado-detalle>