



import { Component, Injectable, Directive, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

/**
 * Options passed when opening a confirmation modal
 */
interface ConfirmOptions {
  /**
   * The title of the confirmation modal
   */
  title: string,

  /**
   * The message in the confirmation modal
   */
  message: string
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class AlertState {
  /**
   * The last options passed ConfirmService.confirm()
   */
  options: ConfirmOptions;

  /**
   * The last opened confirmation modal
   */
  modal: NgbModalRef;

  /**
   * The template containing the confirmation modal component
   */
  template: TemplateRef<any>;
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable()
export class AlertService {

  constructor(private modalService: NgbModal, 
              private state: AlertState,
             ) {
     //console.log('conf-ConfirmService constructor');

  }

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */


  confirm(options: ConfirmOptions): Promise<any> {
     //console.log('conf-ConfirmService options',options);

    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template);
    return this.state.modal.result;
  }
}

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
  selector: 'alert-modal-component',
  template: 

  `
 

    <div class="modal-content " style="padding:8px;">
        <div class="modal-header state modal-primary">
            <h4 class="modal-title">{{ options.title}}</h4>
          <button type="button" style="float: right;
          font-size: 21px;
          font-weight: 700;
          line-height: 1;
          color: #000;
          padding: 6px;
          text-shadow: 0 1px 0 #fff;
          filter: alpha(opacity=20);
          opacity: .2;" aria-label="Close" (click)="close()">

            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span style="white-space: pre-line">{{ options.message }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="ok()" translate>ok</button>
        </div>  
        
        </div>`


})
export class AlertModalComponent {

  options: ConfirmOptions;

  constructor(private state: AlertState) {
    console.log('conf-ConfirmModalComponent', this.options);
    console.log('conf-ConfirmModalComponent', this.state);
    this.options = state.options;
  }

  ok() {
     console.log('conf-ConfirmModalComponent yes');
    this.state.modal.close('ok');
  }

  close() {
    console.log('conf-ConfirmModalComponent yes');
   this.state.modal.close('close');
 }
 
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
  // selector: "template[confirm]"
    selector: "[alert]"
})
export class AlertTemplateDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: AlertState) {
    state.template = confirmTemplate;
    //  console.log('conf-ConfirmTemplateDirective state.template',state.template);
  }
}

