
import { Component, OnInit,OnDestroy,ChangeDetectorRef ,NgZone ,AfterViewInit} from '@angular/core';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalMensajeComponent }    from '../../services/modal-mensaje/modal-mensaje.component';
import { ModalSiNoComponent }       from '../../services/modal-si-no/modal-si-no.component';
import { ModalGetData }             from '../../services/modal-getData/modal-getData.component';
import {FiredatabaseService}        from '@proyecto/servicios/firebase/firedatabase.service';
import { MensajesService }          from '../../services/mensajes/mensajes.service';
import { Router,NavigationEnd }     from "@angular/router";
import { Subscription }             from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl,ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import { NgbDateCustomParserFormatter}  from '../../util/dateformat/dateformat';
import {GrillaGenerica}                 from '../../generico/grillaGenerica'

@Component({
  selector: 'app-pedidos-gestor-listado',
  templateUrl: './pedidos-gestor-listado.component.html',
  styleUrls: ['./pedidos-gestor-listado.component.css']
})
export class PedidosGestorListadoComponent   extends GrillaGenerica implements OnInit, AfterViewInit {

 

perfilUsuario:any=null;
empresaSelected:any=null;
listadoPrincipal:any[]=[];
mensajeError:string=null;
showSpinner:boolean=false;
getPedidosSuscription:Subscription;
getPedidosGestorSuscription:Subscription;
refTango:string[]=[];
ProcesarPedidoMarca:boolean[]=[];
buscarProcesados:boolean=false;
buscarCreados:boolean=false;
buscarCancelado:boolean=false;
navigationSubscription;
// Formulario
  pedidoForm = this.fb.group({
 
  fechaInicio: ['', Validators.required],
  fechaHasta: ['', Validators.required],
  buscarCreados: ['',],
  buscarProcesados: ['', ],
  buscarCancelado: ['', ],
 
 
    });
errorFechaInicio:String;
errorFechaHasta:String;
minDate:any;
maxDate:any;
dfin:Date;
dIni:Date;
editar:boolean=false;
  constructor( private fireBaseService: FiredatabaseService,
  	private fb: FormBuilder,
      private mensageService:MensajesService,
      private router:Router,
      private cd: ChangeDetectorRef,
      private ngZone: NgZone,
      private _modal: NgbModal) {
              super();
             }

    ngOnInit() {
      console.log("pedidowebGestor Init");

    this.filtro['estado']=null;
    

    this.showSpinner=true;  
    this.perfilUsuario=null;
   
    
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();
       // this.router.navigated = false;
       // this.router.navigate([this.router.url]);
     }
   });


    this.dfin = new Date();
      console.log('date ',this.dfin.getFullYear()+'-'+this.dfin.getMonth()+'-'+this.dfin.getDate());
    this.dIni= new Date();
      this.dIni.setDate( this.dfin.getDate()  -360 );// son 30 dias
     this.minDate= {
            "year": this.dIni.getFullYear(),
            "month": this.dIni.getMonth()+1, // get Month retonra de 0- 11
            "day":this.dIni.getDate()
          };
 	this.maxDate= {
            "year": this.dfin.getFullYear(),
            "month": this.dfin.getMonth()+1, // get Month retonra de 0- 11
            "day":this.dfin.getDate()
          };
          
     this.pedidoForm.get('fechaInicio').setValue(this.minDate);
     this.pedidoForm.get('fechaHasta').setValue({
            "year": this.dfin.getFullYear(),
            "month": this.dfin.getMonth()+1, // get Month retonra de 0- 11
            "day":this.dfin.getDate()
          });

    console.log(this.pedidoForm.controls); 
      this.pedidoForm.valueChanges.subscribe(val => {
          this.mensajeError=null;
          this.errorFechaInicio=null;
          this.errorFechaHasta=null;
          console.log('pedidowebGestor valueChanges',val);
          console.log('pedidowebGestor valueChanges',val.fechaInicio);
          console.log('pedidowebGestor valueChanges',val.fechaHasta);
         
          // var fechaEnt = new Date(val.fechadeEntrega.year+'-'+val.fechadeEntrega.month+'-'+val.fechadeEntrega.day).getTime();
          var fechaB = new Date(val.fechaHasta.month+'/'+val.fechaHasta.day+'/'+val.fechaHasta.year);
          var fechaA = new Date(val.fechaInicio.month+'/'+val.fechaInicio.day+'/'+val.fechaInicio.year);
          
          var diff = Math.trunc(( fechaB.getTime()-fechaA.getTime())/86400000);//1000*60*60*24
          console.log('fechaB',fechaB);
          console.log('fechaA',fechaA);
        
          console.log('diff',diff);
          if(isNaN(fechaA.getTime())){
            this.errorFechaInicio='Fecha erronea';
          }
          if(isNaN(fechaB.getTime())){
            this.errorFechaHasta='Fecha erronea';
          }

          if (diff<0){
          		 this.pedidoForm.get('fechaInicio').setValue(val.fechaHasta);
          		 this.errorFechaInicio='Fecha de inicio mayor que fecha Fin ';
          }
          if (diff>365){
             this.errorFechaHasta='Esta buscando mas de 365 días';
          }
         
      


          this.getPedidosGestor();

      });


	this.getPerfil(); // llamar a esta funcion al final para que el formulario tenga datos.
   }

   ngAfterViewInit(){
   	 console.log("pedidowebGestor ngAfterViewInit");
   	 	console.log('ngAfterViewInit', this.pedidoForm.value.fechaInicio);
	console.log('ngAfterViewInit',this.pedidoForm.value.fechaHasta);

   }

getPerfil():void{
    console.log("pedidowebGestor pedido lista get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
      console.log("pedido lista perfil",perfil);
    this.perfilUsuario=perfil;
    this.filtro['NRO_PEDIDO'];
    if (this.perfilUsuario.data.perfil== "cliente" || this.perfilUsuario.data.perfil== "distribuidor"){
    
      if(!this.perfilUsuario.data.EmpresaSelected ){
        this.mensajeError="Seleccione un Cliente "
         this.alertService.confirm({ 
          title:   'Lista de Pedidos', 
          message:  'Seleccione un Cliente', 
       
        }).then(data=>{console.log('ok confirm',data);  });
       
      }else{
        
        this.empresaSelected=this.perfilUsuario.data.EmpresaSelected;
        this.getPedidosWebEmpresa(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);
     
     }
      
    } else{
      this.getPedidosGestor( );
    
    }
     
    
   }) ;
}


getPedidosGestor(){

  console.log("getPedidosGestor  this.listadoPrincipal fechaInicio", this.pedidoForm.value.fechaInicio);
  console.log("getPedidosGestor  this.listadoPrincipal fechaHasta",this.pedidoForm.value.fechaHasta);
	
     this.getPedidosGestorSuscription= this.fireBaseService
     .getPedidosWebGestorXFechaEstado(
                  this.pedidoForm.value.fechaInicio
     							,this.pedidoForm.value.fechaHasta
     							,this.filtro['estado']).subscribe(data=>{
      console.log("getPedidosGestor  this.listadoPrincipal lista", this.listadoPrincipal);
      console.log("getPedidosGestor  this.listadoPrincipal data",data);
      this.ngZone.run(() => { // <== added
        this.listadoPrincipal=data;
        // if(this.listadoPrincipal.length==0){
        //   this.mensajeError="No hay Pedidos Web para mostrar";
        // } else{
        //    this.mensajeError=null;
        // }
        this.showSpinner=false;      
        for (var i = 0; i < this.listadoPrincipal.length; i++) {
          this.refTango.push("");
          this.ProcesarPedidoMarca.push(false);
        }
        
        console.log("pedido lista  getPedidosGestor lista run", this.listadoPrincipal);
        if (!this.cd['destroyed']) {
         this.cd.detectChanges();
       }
      });
      });
   }

   getPedidosWebEmpresa(codEmpresa){
    console.log("pedido lista  getPedidosWeb codEmpresa");
   
    this.getPedidosSuscription= this.fireBaseService.getPedidosWeb(codEmpresa).subscribe(data=>{
  
      console.log("pedido lista  this.listadoPrincipal lista", this.listadoPrincipal);
      console.log("pedido lista  this.listadoPrincipal data",data);
      this.ngZone.run(() => { // <== added
        this.listadoPrincipal=data;
        if(this.listadoPrincipal.length==0){
          this.mensajeError="No hay Pedidos Web para mostrar";
        }else{
           this.mensajeError=null;
        }
        this.showSpinner=false;      
        for (var i = 0; i < this.listadoPrincipal.length; i++) {
          this.refTango.push(''+i);
           this.ProcesarPedidoMarca.push(false);
        }
        console.log("pedido lista  this.listaPedidos lista run", this.listadoPrincipal);
        if (!this.cd['destroyed']) {
         this.cd.detectChanges();
       }
      });
   
       
  });

}
  nuevoPedido(){
    this.mensageService.setPedidoWebSelectedObs(null);
    // this.router.navigate(['/pedidoCrearDetalles']);
    this.editar=true;
  }

  
  editarPedido(pedidoParaEditar){
    console.log("pedido lista pedidoParaEditar",pedidoParaEditar);
    this.mensageService.setPedidoWebSelectedObs(pedidoParaEditar);
    // this.ngZone.run(() => this.router.navigate(['/pedidoCrearDetalles']));
    this.editar=true;
  }

grabarRefTango(refTango,pedidoParaProcesar){
 console.log("pedido lista refTango",refTango);
 console.log("pedido lista ProcesarPedido",pedidoParaProcesar);


 this.fireBaseService.procesarPedidoWeb(this.perfilUsuario,refTango,'procesado',pedidoParaProcesar.NRO_PEDIDO).subscribe(
      next=>{
        console.log('next',next);
        this.refTango=[]; 
        this.ProcesarPedidoMarca=[];
        this.mostrarMensajeModal('Procesar Pedido', 'Se ha procesado Correctamete el Pedido Nro: '+pedidoParaProcesar.NRO_PEDIDO,'');
        //limpio el array
        // var r = confirm("Se creo el pedido correctamente");
        // this.cd.detach();
        this.router.navigate(['/pedidoGestorListado']);
      },
      error=>{
        console.log('error',error);
        // var r = confirm("error al crear el pedido: "+error);
      });

}



cancelarPedido(pedidoParaProcesar){

  console.log("cancelarPedido",pedidoParaProcesar);

  const modalRef =    this._modal.open(ModalSiNoComponent);
  modalRef.componentInstance.titulo = "Pedidos Web - Borrar";
  modalRef.componentInstance.mensaje = "Confirma la eliminación del Pedido Web";
  modalRef.componentInstance.dato = "Pedido Nro: "+ pedidoParaProcesar.NRO_PEDIDO;
  modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            if(result.cause="SI"){
              this.cancelarPedidoEnBase(pedidoParaProcesar);
            }
            
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );


 
}
ProcesarPedidoModal(pedidoParaProcesar){

  console.log("cancelarPedido",pedidoParaProcesar);

  const modalRef =    this._modal.open(ModalGetData);
  modalRef.componentInstance.titulo = "Procesando Pedidos Web ";
  modalRef.componentInstance.mensaje = "Ingrese el Nro de Pedido Tango";
  modalRef.componentInstance.dato = "Pedido Nro: "+ pedidoParaProcesar.NRO_PEDIDO;
  modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.data: "+result.data);
            if(result.cause="SI"){
              this.grabarRefTango(result.data,pedidoParaProcesar)
              // this.cancelarPedidoEnBase(pedidoParaProcesar);
            }
            
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );


 
}


pedidoConfirmar(pedidoParaProcesar){

  console.log("cancelarPedido",pedidoParaProcesar);

  const modalRef =    this._modal.open(ModalSiNoComponent);
  modalRef.componentInstance.titulo = "Pedidos Web - Borrar";
  modalRef.componentInstance.mensaje = "Confirma la eliminación del Pedido Web";
  modalRef.componentInstance.dato = "Pedido Nro: "+ pedidoParaProcesar.NRO_PEDIDO;
  modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            if(result.cause="SI"){
              this.cancelarPedidoEnBase(pedidoParaProcesar);
            }
            
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );


 
}

cancelarPedidoEnBase(pedidoParaProcesar){



 this.fireBaseService.cancelarPedidoWeb(this.perfilUsuario,pedidoParaProcesar.NRO_PEDIDO).subscribe(
      next=>{
        console.log('next',next);
        this.refTango=[];
        this.ProcesarPedidoMarca=[];
        // this.mostrarMensajeModal('Procesar Pedido', 'Se ha Cancelado el Pedido Nro'+ pedidoParaProcesar.NRO_PEDIDO,'');
        //limpio el array
        // var r = confirm("Se creo el pedido correctamente");
        // this.cd.detach();
        this.router.navigate(['/pedidoGestorListado']);
      },
      error=>{
        console.log('error',error);
        this.mostrarMensajeModal('Procesar Pedido', 'Se ha Prducido un Error al borrar: '+ pedidoParaProcesar.NRO_PEDIDO,error);
        // var r = confirm("error al crear el pedido: "+error);
      });

}


mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}








onPostLimpiarFiltros(){
  this.getPedidosGestor();
  super.onPostLimpiarFiltros();
}


  ngOnDestroy(){
    console.log("on destroy");
    if(this.getPedidosSuscription){
    this.getPedidosSuscription.unsubscribe();
    }
    if(this.getPedidosGestorSuscription){
    this.getPedidosGestorSuscription.unsubscribe();
    }


    
    console.log(this.navigationSubscription);
    if (this.navigationSubscription) {  

       this.navigationSubscription.unsubscribe();
    
    }
  }

  setEditar(editar:boolean){
    console.log('setEditar',editar);
    this.editar=editar;
  }

}


