import { Component, OnInit,Output,EventEmitter,OnDestroy } from '@angular/core';

import { Validators, FormBuilder, FormGroup, FormControl,ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
// import {EmpresaModalComponent} from '../../empresa-modal/empresa-modal.component';
import {FiredatabaseService}            from '@proyecto/servicios/firebase/firedatabase.service';
import {SqlserviceService}              from '../../services/sql/sqlservice.service';
import { MensajesService }              from '../../services/mensajes/mensajes.service';
import { Router,NavigationEnd }         from "@angular/router";
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalMensajeComponent }        from '../../services/modal-mensaje/modal-mensaje.component';
import {EmpresaModalComponent}          from '../../admin/users/empresa-modal/empresa-modal.component'
import {Location}                       from '@angular/common';
import {RemitosServiceService}          from'../../util/remitos/remitos-service.service'
import { AlertasService}                from '@proyecto/servicios/firebase/alertas.service';
export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
     console.log('nameRe',nameRe);
  return (control: AbstractControl): {[key: string]: any} | null => {
      console.log('control',control);

    const forbidden = nameRe.test(control.value);
     console.log('forbidden',forbidden);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };
}

export function validateMayorQue(number: number): ValidatorFn {
     console.log('number',number);
  return (control: AbstractControl): {[key: string]: any} | null => {
      console.log('control',control);
      let esCorrecto=false;
      if(control.value>number){
            esCorrecto=true;
      }
    // const forbidden = number.test(control.value);
     console.log('esCorrecto',esCorrecto);
    return !esCorrecto ? {'validateMayorQue': {value: control.value}} : null;
  };
}
import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'

@Component({
  selector: 'app-alertas-reposicion-crear',
  templateUrl: './alertas-reposicion-crear.component.html',
  styleUrls: ['./alertas-reposicion-crear.component.css']
})
export class AlertasReposicionCrearComponent implements OnInit, OnDestroy {
producto:Articulo=null;
listaAlertasExistentes:AlertaListado[];
limpiandoFormulario:boolean=false;
razonSocial=null;
codCliente=null;
clienteInput=null;

@Output() setEditar= new EventEmitter<boolean>();

form = this.fb.group({

  nombreAlerta: ['', [Validators.required, forbiddenNameValidator(/bob/i)]],
  articulo: ['', ],
  stockInicial: ['', [Validators.required,validateMayorQue(0)]],
  cantidadDeAnimales: ['', Validators.required],
  consumoDiario: ['', Validators.required],
  capacidadDeAlmacenamiento: ['', Validators.required],
  diasDeMargen: [2, Validators.required],

    },{ validator: formValidator }); //recordar validator es en singular!!!!




listaDeArticulos:Articulo[]=[]; // son los productos que puede pedir este cliente

alertaParaEditar:any=null;
idAlerta:number=null;

perfilUsuario:any=null;

diaAviso:{};
dias:number=0;
navigationSubscription;
mostrarBotonBuscaClientes=false;

mdiSpin:boolean = false;

  constructor(  private db                : FiredatabaseService,
                private fb                : FormBuilder,
                public sql                : SqlserviceService,
                private router            : Router,
                private _modal            : NgbModal,
                private _location         : Location,
                private alertasService    : AlertasService,
                private mensageService    : MensajesService,
                private remitosServcice:RemitosServiceService)
                { }

  ngOnInit() {
      this.alertaParaEditar=null;
      this.getPerfil();
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        console.log(e);
        if (e instanceof NavigationEnd) {
          this.ngOnDestroy();
          this.ngOnInit();
        }
      });

     this.form.valueChanges.subscribe(val => {
        if(this.limpiandoFormulario){
           return;
         }
        console.log(val);
         // console.log(val.articulo.COD_ARTICU);
         console.log('this.listaAlertasExistentes',this.listaAlertasExistentes);
         console.log('this.alertaParaEditar',this.alertaParaEditar);
         if(this.listaAlertasExistentes){
            for (var i = 0; i < this.listaAlertasExistentes.length; i++) {
              if(this.listaAlertasExistentes[i].articulo.COD_ARTICU==val.articulo.COD_ARTICU && !this.alertaParaEditar){
                console.log('son iguales',val.articulo);
                if(val.articulo){
                  this.mostrarMensajeModal('Alertas','Ya existe una alerta para este producto','');
                  this.form.get('articulo').setValue('');
                } 
              } 
            }
          } 

          if(this.alertaParaEditar){
            if(this.alertaParaEditar.articulo.COD_ARTICU!=val.articulo.COD_ARTICU ){
             
              this.mostrarMensajeModal('Alertas','No se puede modifcar el producto de un Alerta. Puede Borrarla','');
            }
          }
        });
    }

  


  getPerfil():void{
    this.alertaParaEditar=null;
    this.idAlerta=null
    console.log("Alerta crear get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
    console.log("Alerta crear perfil",perfil);
    this.perfilUsuario=perfil;
    
    console.log("codEmpresa",perfil.data.EmpresaSelected );

    if(this.perfilUsuario.data.perfil=='administrador' || this.perfilUsuario.data.perfil=='gestorPedidos'){
      this.mostrarBotonBuscaClientes=true;
    } else{
      this.mostrarBotonBuscaClientes=false;
    }
 
    if(perfil.data.EmpresaSelected){
           
        this.razonSocial=perfil.data.EmpresaSelected.RAZON_SOCI;
        this.codCliente=perfil.data.EmpresaSelected.COD_CLIENT;
        this.getAlertasVigentes(this.codCliente);
        this.getProductos(this.codCliente);
        
    } 
  
      this.getAlertaParaEditar();
   }) 
}





  getProductos(codCliente){

    this.db.getProductos(codCliente).subscribe(listaDeArticulos=>{
      this.listaDeArticulos=listaDeArticulos;
      console.log("Alerta crear listaDeArticulos",listaDeArticulos);
        
           if (this.listaDeArticulos.length ==0){
               this.mostrarMensajeModal('Alerta de Reposición',"El cliente no tiene productos asignados",'');
           }

           // this.getAlertaParaEditar();

       });

};


getAlertasVigentes(codCliente){

    this.alertasService.getAlertasCliente(codCliente).subscribe(data=>{
      this.listaAlertasExistentes=data;
    });    

}

  

getAlertaParaEditar():void{
  this.mensageService.getAlertaSelectedObs().subscribe(alertaParaEditar=>{
      console.log("alerta crear getAlertaParaEditar",alertaParaEditar);

      if(alertaParaEditar!=null){

        this.alertaParaEditar=alertaParaEditar.alertaParaEditar;
        this.idAlerta=alertaParaEditar.id;
           console.log("alerta crear this.alertaParaEditar",this.alertaParaEditar);
           console.log("alerta crearthis.idAlerta", this.idAlerta);

        this.getProductos(this.alertaParaEditar.codCliente)
        // this.form.setValue({alertaParaEditar});
        this.form.patchValue({
                nombreAlerta: this.alertaParaEditar.nombreAlerta,
                articulo: this.alertaParaEditar.articulo,
                stockInicial: this.alertaParaEditar.stockInicial,
                cantidadDeAnimales: this.alertaParaEditar.cantidadDeAnimales,
                consumoDiario: this.alertaParaEditar.consumoDiario,
                capacidadDeAlmacenamiento: this.alertaParaEditar.capacidadDeAlmacenamiento,
                diasDeMargen: this.alertaParaEditar.diasDeMargen,

        })
        
              this.codCliente=this.alertaParaEditar.codCliente;
              this.razonSocial=this.alertaParaEditar.razonSocial;

    }else {
         console.log("alerta crear this.alertaParaEditar nulo",this.alertaParaEditar);
          this.alertaParaEditar=null;
          this.idAlerta=null;
    };
    });
};


buscarEmpresa(){
  console.log('buscarEmpresa');

  const modalRef =    this._modal.open(EmpresaModalComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'jj';
    modalRef.componentInstance.empresa = this.clienteInput;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.empresa: "+result.empresa);
            console.log("result.empresa: "+result.empresa.COD_CLIENT);
            console.log("result.cause: "+result.cause);
            if(result.empresa){

               let e={"COD_CLIENT": result.empresa.COD_CLIENT,
                   "RAZON_SOCI": result.empresa.RAZON_SOCI,
                   "NOM_COM": result.empresa.NOM_COM,
                   "CUIT": result.empresa.CUIT,
                   "LOCALIDAD": result.empresa.LOCALIDAD};

              this.getProductos(e.COD_CLIENT); 
              this.codCliente=e.COD_CLIENT;
              this.razonSocial=e.RAZON_SOCI;

              this.actualizarListadoDeArticulos();
               // if(this.listaEmpresas){
               //    this.listaEmpresas.push(e);
               //  }else{
               //     this.listaEmpresas=[e];
               //  }
            }
            // console.log("result.cause: "+result.cause);
            // console.log("result.date: "+result.date.year);
            // console.log("result.date: "+result.date.month);
            // console.log("result.date: "+result.date.day);
            // Cross click
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );
  
      // } // if lista empresas y perfil cliente.
   }



borrarFormulario() {
  console.log("borrarFormulario");

   this.limpiandoFormulario=true;
 
this.form.get('articulo').enable();
  let temp={
          nombreAlerta: '',
          articulo: '',
          stockInicial: '',
          cantidadDeAnimales: '',
          consumoDiario: '',
          capacidadDeAlmacenamiento: '',
          diasDeMargen: ''    };

  this.form.patchValue( temp);


  this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });;
  this.form.markAsUntouched;
  this.form.markAsPristine;
  this.alertaParaEditar=null;
  this.idAlerta=null;
  this.mensageService.setAlertaSelectedObs(null);
 
  this.mostrarMensajeModal('Alerta de Reposición',"Se limpió el formulario",'');

  };

grabarAlerta(){
  console.log("Grabar Alerta");
  console.log("Grabar Alerta", this.perfilUsuario );

  console.log("Grabar Alerta values", this.form.value);
  console.log("Grabar Alerta form", this.form);
  if (!this.form.valid){
    this.mostrarMensajeModal('Alerta de Reposición',"formulario Inválido",'');
    return;
  }

  const alerta=this.form.value;
  console.log("Grabar Alerta articulo", this.form.value.articulo);
  console.log("Grabar Alerta articulo", alerta.articulo.COD_ARTICU);
  console.log("Grabar Alerta articulo", alerta.articulo.DESCRIPCIO);
  console.log("Grabar Alerta articulo", alerta.articulo.DESC_ADIC);
  console.log("Grabar Alerta articulo", alerta.articulo.ID_MEDIDA_STOCK);

  const articulo={
            COD_ARTICU:  alerta.articulo.COD_ARTICU,
            DESCRIPCIO:alerta.articulo.DESCRIPCIO,
            DESC_ADIC:alerta.articulo.DESC_ADIC,
            ID_MEDIDA_STOCK:alerta.articulo.ID_MEDIDA_STOCK
        };

  const  newAlerta= {
            articulo:articulo,
            cantidadDeAnimales:alerta.cantidadDeAnimales,
            capacidadDeAlmacenamiento: alerta.capacidadDeAlmacenamiento,
            consumoDiario: alerta.consumoDiario,
            diasDeMargen: alerta.diasDeMargen,
            nombreAlerta: alerta.nombreAlerta,
            stockInicial: alerta.stockInicial
            }



  let dias=   (newAlerta.stockInicial/(newAlerta.cantidadDeAnimales*newAlerta.consumoDiario)) -  newAlerta.diasDeMargen ;
  console.log('newAlerta.stockInicial',newAlerta.stockInicial)  ;
  console.log('newAlerta.cantidadDeAnimales*newAlerta.consumoDiario',newAlerta.cantidadDeAnimales*newAlerta.consumoDiario)  ;
  console.log('newAlerta.cantidadDeAnimales',newAlerta.cantidadDeAnimales)  ;
  console.log('newAlerta.consumoDiario',newAlerta.consumoDiario)  ;
  console.log('newAlerta.diasDeMargen',newAlerta.diasDeMargen)  ;
  console.log('dias',dias)  ;
  
  var hoy = new Date();
  newAlerta['diaActuliazacionCarga']={dia:hoy.getDate(),mes:hoy.getMonth()+1,ano:hoy.getFullYear()};
  newAlerta['stockDespuesUltimaCarga']=newAlerta.stockInicial;
  newAlerta['metodoAcutlaizastockDespuesUltimaCarga']='manual';


 
  var diaAviso = new Date();
      diaAviso.setDate( diaAviso.getDate() + dias );
      console.log('date ',diaAviso.getFullYear()+'-'+diaAviso.getMonth()+'-'+diaAviso.getDate());


     newAlerta['diaAviso']={dia:diaAviso.getDate(),mes:diaAviso.getMonth()+1,ano:diaAviso.getFullYear()};
    var diaCalculo = new Date();
     newAlerta['diaCalculo']={dia:diaCalculo.getDate(),mes:diaCalculo.getMonth()+1,ano:diaCalculo.getFullYear()};

if (this.alertaParaEditar){
  console.log("Modificar Alerta");
    
    // Se dejan las etregas realizadas

    newAlerta['actualizacionStock']=this.alertaParaEditar.actualizacionStock?this.alertaParaEditar.actualizacionStock:[];
  this.alertasService.modificarAlerta(this.perfilUsuario,this.codCliente,newAlerta, this.idAlerta).subscribe(
    data=>{
    console.log(data);
      this.alertaParaEditar=null;
      this.idAlerta=null;
      this.mostrarMensajeModal('Alerta de Reposición',"Se Actualizaron los datos",'');
      this.mensageService.setAlertaSelectedObs(null);
      // this._location.back();
      this.setEditar.emit(false);
      // this.router.navigate(['/alertaReposicionListado']);
    
    }
    ,error=>{console.log(error);
    this.mostrarMensajeModal('Alerta de Reposición',"Consulte al Administrador:",error);
    });

}else{

  let empresaSelected={COD_CLIENT:this.codCliente,RAZON_SOCI:this.razonSocial};
  // this.db.crearAlerta(this.perfilUsuario,this.perfilUsuario.data.EmpresaSelected,newAlerta).subscribe(
  this.alertasService.crearAlerta(this.perfilUsuario,empresaSelected,newAlerta).subscribe(
    data=>{
        console.log(data);
     this.mostrarMensajeModal('Alerta de Reposición',"Se creó una alerta nueva",'');
     this.setEditar.emit(false);
    // this.router.navigate(['/alertaReposicionControl']);
    }
  ,error=>{console.log(error);
    this.mostrarMensajeModal('Alerta de Reposición',"Consulte al Administrador: ",error);}

  );

}
}

cancelar(){
  this.mensageService.setAlertaSelectedObs(null);
  this.setEditar.emit(false);
}

ngOnDestroy(){
  console.log("on destroy");
  // if(!this.alertaParaEditar){
    this.mensageService.setAlertaSelectedObs(null);
  // }

  
  if (this.navigationSubscription) {  
           this.navigationSubscription.unsubscribe();
          }
  
        }


mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}

  compareArticulos(c1: Articulo, c2:Articulo): boolean {     
      return c1 && c2 ? c1.COD_ARTICU === c2.COD_ARTICU : c1 === c2; 
  }


  actualizarListadoDeArticulos(){
    this.buscarRemitosCliente(this.codCliente,this.perfilUsuario.data.settings.ventanaVisualizacionPedidos);
    
  }


  buscarRemitosCliente(codEmpresa,cantidadDeDias){
    console.log('buscarRemitosCliente');
    
    console.log('buscarRemitosCliente cantidadDeDias',cantidadDeDias);
  
    // this.showSpinner    = true;
    // this.mdiSpin        = false;
  
    this.sql.getRemitoDetallado(codEmpresa,60).subscribe(data=>
         {
        console.log(data);
        // this.remitos=this.acomodaRemitos(data);
  
        this.remitosServcice.acomodaRemitos(data).then(  
              remitosAcomodado=> {

                        
                this.remitosServcice.grabarArticulosUsadosEnPedidosTango(remitosAcomodado,codEmpresa);
  
              }).catch(
              error=>{}
              // this.mensajeError=error
              ) 
  
        
      
          },
          error =>{console.log(error);
              this.mdiSpin        = false;
              // this.mensajeError=" buscarRemitos Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";
  
         }
   );
  }
}

export const formValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
console.log('formValidator control',control);
  const nombreAlerta = control.get('nombreAlerta');
  const articulo = control.get('articulo');
  const stockInicial = control.get('stockInicial');
  const cantidadDeAnimales = control.get('cantidadDeAnimales');
  const consumoDiario = control.get('consumoDiario');
  const capacidadDeAlmacenamiento = control.get('capacidadDeAlmacenamiento');
  const diasDeMargen = control.get('diasDeMargen');

 

  let pasaLaValidacion=true;
  let mensaje="";
  console.log('formValidator stockInicial',stockInicial);
  console.log('formValidator capacidadDeAlmacenamiento',capacidadDeAlmacenamiento);
    if(stockInicial>capacidadDeAlmacenamiento){
      pasaLaValidacion=false;
      mensaje="stockInicial es mayor que la capacidad del Almacenamiento";
    } else {
    }

  console.log('formValidator pasaLaValidacion',pasaLaValidacion);
  return pasaLaValidacion ?  null: { 'formError': mensaje } ;

};
