import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

//Modulos internos
import { ClientesListComponent } from '../app/clientes/clientes-list/clientes-list.component';
import { ClientesCrearComponent } from '../app/clientes/clientes-crear/clientes-crear.component';
import { LogGoogleComponent } from '../app/log/log-google/log-google.component';
import { LogMailComponent } from '../app/log/log-mail/log-mail.component';
import { SetingsComponent } from '../app/admin/users/setings/setings.component';
import { RegistrarseComponent } from '../app/log/registrarse/registrarse.component';
import { UsuarioListComponent } from '../app/admin/users/usuario-list/usuario-list.component';
import { UsuarioNoautorizadoComponent } from './admin/users/usuario-noautorizado/usuario-noautorizado.component';
import { SolicituEmpresaComponent } from './admin/users/solicitu-empresa/solicitu-empresa.component';
import { SolicitudEmpresaListadoComponent } from './admin/users/solicitud-empresa-listado/solicitud-empresa-listado.component';

import { PedidosComponent } from '../app/pedidos/pedidos-listado/pedidos.component';

import { RemitosDetalleComponent } from '../app/remitos/remitos-detalle/remitos-detalle.component';
import { RemitoDetallePorIdComponent } from './remitos/remito-detalle-por-id/remito-detalle-por-id.component';

import { PedidosGestorListadoComponent } from '../app/pedidos/pedidos-gestor-listado/pedidos-gestor-listado.component';
import { PedidoCreadoDetalleComponent } from '../app/pedidos/pedido-creado-detalle/pedido-creado-detalle.component';
import { AlertasReposicionCrearComponent } from './alertasReposicion/alertas-reposicion-crear/alertas-reposicion-crear.component';
import { AlertasReposicionControlComponent } from './alertasReposicion/alertas-reposicion-control/alertas-reposicion-control.component';


import { AvisosListComponent } from '../app/avisos/avisos-list/avisos-list.component';
import { AvisosNuevoComponent } from '../app/avisos/avisos-nuevo/avisos-nuevo.component';
import { RemitoListadoTranportistaComponent } from './remitos/remito-listado-tranportista/remito-listado-tranportista.component';
import { MapaMonitorComponent }                      from './mapa/monitor/mapa-monitor.component';
//Plantillas
import { LoginComponent } from '../app/plantillas/login/login.component';
import { EstilosComponent } from '../app/plantillas/estilos/estilos.component';
 

// Rutas

const routes: Routes = [

  { path: 'clientesList', component: ClientesListComponent }
  ,{ path: 'clienteCrear/:id', component: ClientesCrearComponent }
  ,{ path: 'logGoogle', component: LogGoogleComponent }
  ,{ path: 'logMail', component: LogMailComponent }
  ,{ path: 'registrarse', component: RegistrarseComponent }
  ,{ path: 'settings', component: SetingsComponent }
  ,{ path: 'usuariosList', component: UsuarioListComponent }
  ,{ path: 'solicitudEmpresa', component: SolicituEmpresaComponent }
  ,{ path: 'solicitudEmpresaListado', component: SolicitudEmpresaListadoComponent }

  ,{ path: 'pedidos', component: PedidosComponent }
  ,{ path: 'pedidoCrearDetalles', component: PedidoCreadoDetalleComponent }
  ,{ path: 'pedidoGestorListado', component: PedidosGestorListadoComponent }


  ,{ path: 'avisosList', component: AvisosListComponent }



  ,{ path: 'remitosTransportista', component: RemitoListadoTranportistaComponent }
  ,{ path: 'remitosDetalles', component: RemitosDetalleComponent }
  ,{ path: 'remitosDetallesId/:remito', component: RemitoDetallePorIdComponent }


  ,{ path: 'alertaReposicionCrear', component: AlertasReposicionCrearComponent }

  ,{ path: 'alertaReposicionControl', component: AlertasReposicionControlComponent }
  ,{ path: 'mapaMonitor', component: MapaMonitorComponent }

  //Plantillas
  ,{ path: 'login', component: LoginComponent }
  ,{ path: 'estilos', component: EstilosComponent }



  ,{ path: 'ususario_no_autorizado', component: UsuarioNoautorizadoComponent }

  ,{ path: '', redirectTo: '/logMail', pathMatch: 'full' } //esto se usa para que funcione cuando no exista la ruta solicitada

  // , { path: '**', component: UsuarioNoautorizadoComponent }

  // ,{ path: 'firebase-messaging-sw.js', component: RegistrarseComponent }

];




@NgModule({
 // imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
 imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore', relativeLinkResolution: 'legacy' })],
 exports: [RouterModule],
 })

export class AppRoutingModule { }
