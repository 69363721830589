import { Component, OnInit, OnDestroy, ChangeDetectorRef ,NgZone,} from '@angular/core';
import {DatePipe,} from '@angular/common';

import { Router,NavigationEnd } from "@angular/router";
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';;
import { User } from '../user';
import { MensajesService }  from '../../../services/mensajes/mensajes.service';




import { AuthService } from '../../../services/firebase/auth.service';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import * as firebase from 'firebase';
import { mergeMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalMensajeComponent }  from '../../../services/modal-mensaje/modal-mensaje.component';
@Component({
  selector: 'app-solicitud-empresa-listado',
  templateUrl: './solicitud-empresa-listado.component.html',
  styleUrls: ['./solicitud-empresa-listado.component.css']
})
export class SolicitudEmpresaListadoComponent implements OnInit, OnDestroy {
		showSpinner:boolean=true;
		mensajeError:string='';
		solicitudes:any[];
    perfilUsuario:any=null;

    buscarProcesados:boolean=false;
    buscarPendientes:boolean=true;
    solicitud:any=null;
    i:number;
    navigationSubscription;
    mostrarFormulario=false;
    userSelected:User=null;
  constructor(
              
              private db: FiredatabaseService,
              public authService:AuthService,
              private mensageService:MensajesService,
              private ngZone: NgZone,
              private _modal: NgbModal,
              private cd: ChangeDetectorRef,
              private router:Router) { }

  ngOnInit() {
  	console.log("SolicituEmpresaComponent  start",);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();
       // this.router.navigated = false;
       // this.router.navigate([this.router.url]);
     }
    });
  	this.db.getSolicitudesPorEstado('Pendiente').subscribe(
		data=>{console.log('getSolicitudesPendientes data', data);
			this.solicitudes=this.acomodarlistdo(data);
			
      this.getPerfil();
		},
		error=>{console.log('getSolicitudesPendientes error', error);
			this.mensajeError=error;
		});
    
  }

getPerfil():void{
    console.log("pedido lista get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
      console.log("pedido lista perfil",perfil);
      this.perfilUsuario=perfil;
      this.showSpinner=false;
      if (!this.cd['destroyed']) {
          this.cd.detectChanges();
      }
    
   }) ;
}


  editarSolicitud(solicitud,i){
    console.log("solicitud",solicitud);
    console.log("i",i);
  
    this.userSelected={email: solicitud.email, 
          estado: "activo",
          perfil: "cliente",
          key:null, 
          empresas:null, 
          operador:null, 
          settings:null, 
          timeStamp:null
        }
    this.mostrarFormulario=true;
    
 
  }

  procesarSolicitud(solicitud,i){

  this.solicitud=solicitud;
  this.i=i;  
  

  console.log("procesarSolicitud",solicitud);

  this.editarSolicitud(this.solicitud,this.i);
  
  

  }

buscarProcesadosCambio(buscarProcesados){
  this.buscarProcesados=buscarProcesados;
   if(!this.buscarPendientes){
     this.buscarPendientes=true;
   }
   console.log(buscarProcesados);
   this.actulizarListado();
}

buscarPendienteCambio(buscarPendientes){
  this.buscarPendientes=buscarPendientes;
   if(!this.buscarProcesados){
     this.buscarProcesados=true;
   } 
   console.log(buscarPendientes);
   this.actulizarListado();
}

actulizarListado(){
  this.solicitudes=null;
  this.showSpinner=true;
  if(this.buscarProcesados&&this.buscarPendientes){
      this.getSolicitudes( );
  } else  if(this.buscarProcesados){
    this.getSolicitudesPorEstado( 'Procesado' );
     console.log("buscar Procesado");
  }else  if(this.buscarPendientes){
    this.getSolicitudesPorEstado( 'Pendiente' );
    console.log("buscar creado");
  }

}


getSolicitudes(){
  this.db.getSolicitudes().subscribe(
    data=>{console.log('getSolicitudesPendientes data', data);
      this.solicitudes=this.acomodarlistdo(data);
      
      this.getPerfil();
    },
    error=>{console.log('getSolicitudesPendientes error', error);
      this.mensajeError=error;
    });
}

acomodarlistdo(data){
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    data[index]['fechaSolicitud']=this.timestampToDate(element.timeStamp);
    
  }
  return data;
 
}

getSolicitudesPorEstado(estado:string){
  this.db.getSolicitudesPorEstado(estado).subscribe(
    data=>{console.log('getSolicitudesPorEstado data', data);
      this.solicitudes=this.acomodarlistdo(data);
      
      this.getPerfil();
    },
    error=>{console.log('getSolicitudesPorEstado error', error);
      this.mensajeError=error;
    });
}

mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}

  ngOnDestroy() {
        if (this.navigationSubscription) {  
            this.navigationSubscription.unsubscribe();
            }
    
          }

    timestampToDate(timestamp: any): any {
      //console.log("timestampToDate",timestamp);

      let rta;

      if(timestamp==null || timestamp===undefined) {
          rta = null;
          // no convierto dejo tal cual (null)

      } else {
          let isEmptyJson = (Object.entries(timestamp).length === 0 && timestamp.constructor === Object);
          if(isEmptyJson) {

            console.error("timestampToDate recibió {}",timestamp);
            rta = null;

          } else {

            let typeMillis = typeof timestamp.toMillis;
            if (typeMillis == "function") {
              let milisegundos = timestamp.toMillis();
              rta = new Date(milisegundos);
            } else {
              // console.log("timestampToDate toMills!=function", typeMillis);
              rta = null;
            }
          }
      }

      let formatoFechaHora=Object.prototype.toString.call(timestamp);
      // console.log("timestampToDate type, rta",formatoFechaHora, rta);

      return rta;

    }

  accion(data:any){
    console.log(data);
    if(data=="limpiar"){
      this.mostrarFormulario=false;
    } else if(data=="graboOK"){
      console.log('accion-graboOK perfilUsuario ',this.perfilUsuario);
      console.log('accion-graboOK solicitud ',this.solicitud);
      this.mostrarFormulario=false;
      
      let s=this.db.procesarSolicitud1(this.perfilUsuario,'Procesado', this.solicitud.uid).subscribe(
      next=>{
        // this.mostrarMensajeModal('Solicitud de Usuarios',"Genere un nuevo usuario con los datos que se han copiado",'');
        this.solicitud=null;
        s.unsubscribe();
        // this.i=i;  
      },
      error=>{
        console.log('error',error);
        // var r = confirm("error al crear el pedido: "+error);
      });

    }
    
  }
  

}
