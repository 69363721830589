import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuario-noautorizado',
  templateUrl: './usuario-noautorizado.component.html',
  styleUrls: ['./usuario-noautorizado.component.css']
})
export class UsuarioNoautorizadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
