<div class="modal-content ">

    <div class="modal-header">
        <h2 class="modal-title">Buscando Cliente: {{empresa}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
           <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center"><div class="loading" *ngIf="showLoading"></div>
            <p>
                <strong *ngIf="mensaje.length>0 "> {{mensaje}}.</strong>

            </p>


        <!-- <div class="table-body "  *ngIf="listaEmpresas.length>0"> -->
        <div  *ngIf="listaEmpresas.length>0">
            <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y tablaCompleta"  >
                        <thead>
                            <tr>
                                <th>
                                    Codigo Cliente
                                </th>
                                <th>
                                    Razón Social
                                </th>
                                <th>
                                    Cuit
                                </th>
                                <th>
                                    Localidad
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            <tr  *ngFor="let emp of listaEmpresas; let i = index" [attr.data-index]="i">
                                <td>
                                     {{emp.COD_CLIENT}}
                                </td>
                                <td>
                                    {{emp.RAZON_SOCI}}
                                </td>
                                <td>
                                    {{emp.CUIT}}
                                </td>
                                <td  (click)="verEmpresa(emp)">
                                     {{emp.LOCALIDAD}}
                                </td>
                                 <td>
                                    <button class="btn btn-alert" (click)="modal.close({cause:'empresaSeleccionada', empresa: emp })">
                                        Seleccionar
                                    </button>
                                     
                                </td>
                            </tr>
                        </tbody>
            </table>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
        <!-- <button type="button" class="btn btn-danger" (click)="modal.close({cause:'Ok click jj', date:model})">Ok</button> -->
       <!--  <button type="button" class="btn btn-danger" (click)="modal.close({cause:'Ok click jj', date: model })">Ok</button> -->
    </div>
</div>
