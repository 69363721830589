import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SqlserviceService} from '../../../../services/sql/sqlservice.service';
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';
import { MensajesService }  from '../../../../services/mensajes/mensajes.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-modal-inicial',
  templateUrl: './modal-inicial.component.html',
  styleUrls: ['./modal-inicial.component.css']
})
export class ModalInicialComponent implements OnInit {
    formSettings = this.fb.group({
  ventanaVisualizacionPedidos: ['', Validators.required],
  aceptaDescargaSinRepresentante: [false, Validators.required],
  aceptaNotificaciones: [true, Validators.required],

});

 key:string=null;
userData:any=null;   
showLoading:any=false;   
  constructor(	
  				public modal: NgbActiveModal,
  				private fb: FormBuilder,
  				private db: FiredatabaseService, 
  				private mensageService:MensajesService,
           public sql:SqlserviceService,) { }

  ngOnInit() {
  	this.init();
  }

  	init(){
    	this.mensageService. getPerfil().subscribe(
         user=>{

             console.log("settings  getPerfilUsuarioSeleccionadoObs user resived",user);

             this.key=user.key;
             this.userData=user.data;

            this.actualizarFechaDeSolicitudDatos();

             this.formSettings.patchValue({
                 ventanaVisualizacionPedidos: this.userData.settings.ventanaVisualizacionPedidos,
                 aceptaDescargaSinRepresentante: this.userData.settings.aceptaDescargaSinRepresentante,
                 aceptaNotificaciones: this.userData.settings.aceptaNotificaciones,

             });
        });

     this.formSettings.get('aceptaNotificaciones').valueChanges.subscribe(
   			value=> {
      console.log('aceptaNotificaciones',value);
   				}	
		);
	}

actualizarFechaDeSolicitudDatos(){
 this.db.actualizarFechaDeSolicitudDatos(this.key);
 
}


grabarSettings(){

    console.log(this.formSettings.value);

    console.log('formSettings = ',this.formSettings.value);
    console.log('key = ',this.key);


    this.userData['settings']={
        aceptaDescargaSinRepresentante: this.formSettings.value.aceptaDescargaSinRepresentante,
        aceptaNotificaciones: this.formSettings.value.aceptaNotificaciones,
        ventanaVisualizacionPedidos: this.formSettings.value.ventanaVisualizacionPedidos
        };

     this.db.updateUserSettings(this.key,this.userData).subscribe(
      resp=>{
        this.sql.setAceptaEntregaSinCliente(this.userData.EmpresaSelected.COD_CLIENT,this.formSettings.value.aceptaDescargaSinRepresentante,this.key,this.userData.email).subscribe(
           data=>{console.log(data)},
           error=>{console.log(error)});
        
        this.modal.close({cause:'Ok click jj'})
      },err=>{

      }
    ); 
}

}
