<div class="component usuario">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Usuarios</h2>

        </div>

        <div class="row inner-estructura innerListaUsuarios ">

            <div class="col-md-12" *ngIf="mostrarFormulario">

                
                <app-nuevo-usuario  [userSelected]="userSelected" (accion)="accion($event)"></app-nuevo-usuario>

            </div>

            <div class="col-md-12 " *ngIf="!mostrarFormulario">

                <!-- <h3 class="text-center" *ngIf="listaUsuarios">cantidad: {{listaUsuarios.length}}</h3> -->

  
    
  

 
                <table *ngIf="listaUsuarios" class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt" 
                       style=" position:relative; margin:auto;   width:100%;">
                    <thead>
                        <tr>
                            <th (click)="setOrden('usuario.userEmpresaPerfil')">
                                
                                <span class="" *ngIf="ordenCampo!='usuario.userEmpresaPerfil'">Empresa</span>
                                <span class="text-primary" *ngIf="ordenCampo=='usuario.userEmpresaPerfil'">Empresa</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='usuario.userEmpresaPerfil' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='usuario.userEmpresaPerfil' && !ordenReverse" ></i>
                            </th>
                          
                            <th (click)="setOrden('usuario.email')">
                               
                                <span class="" *ngIf="ordenCampo!='usuario.email'"> E-mail</span>
                                <span class="text-primary" *ngIf="ordenCampo=='usuario.email'"> E-mail</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='usuario.email' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='usuario.email' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('usuario.perfil')">

                                <span class="" *ngIf="ordenCampo!='usuario.perfil'">Perfil</span>
                                <span class="text-primary" *ngIf="ordenCampo=='usuario.perfil'">Perfil</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='usuario.perfil' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='usuario.perfil' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('usuario.estado')">

                                <span class="" *ngIf="ordenCampo!='usuario.estado'">Estado</span>
                                <span class="text-primary" *ngIf="ordenCampo=='usuario.estado'">Estado</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='usuario.estado' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='usuario.estado' && !ordenReverse" ></i>

                               
                            </th>
                            <th ngbTooltip="Nuevo Usuario" (click)="nuevoUsuario()" class="text-center">
                                <i class="mdi mdi-36px mdi-account-plus text-primary"></i>
                            </th>

                        </tr>

                        <tr>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="empresaFiltro"  
                                        [(ngModel)]="empresaFiltro" 
                                        placeholder="Filtrar por Empresa"/>
                            </th>
                          
                            <th>
                                <input class="form-control"
                                       type="email" id="emailFiltro"  
                                       [(ngModel)]="emailFiltro" 
                                       placeholder="Filtrar por E-mail"/>
                            
                            </th>
                            <th>
                               
                                <select class="form-control selectpicker" id="perfilFiltro"
                                        [(ngModel)]="perfilFiltro">
                                    <option  [ngValue]="null">Filtrar Por Perfil</option>
                                    <option  *ngFor="let perfil of tiposPerfil"  [ngValue]="perfil.id">{{perfil.val}}</option>
                                </select>

                            </th>

                            <th>
                                <select class="form-control selectpicker" id="estadoFiltro"
                                        [(ngModel)]="estadoFiltro" >
                                    <option  [ngValue]="null">Filtrar Estado</option>
                                    <option  *ngFor="let tip_est of tiposEstado"  [ngValue]="tip_est.id">{{tip_est.val}}</option>
                                </select>
                               
                            </th>

                            <th  ngbTooltip="Limpiar filtros" (click)="limpiarfiltros()" >
                                <i class="mdi mdi-24px mdi-broom"></i>
                            </th>
                        </tr>
                    </thead>
                    <!-- 'table-success': usuario.estado == 'activo' -->
                    <tbody>    
 
                        <tr *ngFor="let usuario of listaUsuarios
                                | orderBy :ordenCampo :ordenReverse :'case-insensitive'  
                                | empresaUsuarioPipe  : empresaFiltro
                                | searchFiltro:'local':'email':emailFiltro 
                                | searchFiltro:'local':'perfil':perfilFiltro
                                | searchFiltro:'local':'estado':estadoFiltro
                                | paginate: { itemsPerPage: grilla.paginadoCantidad, 
                                    currentPage : pagePaginado,
                                    totalItems  : cantidadFiltro}; let cantidadFiltro=count " 
                            (click)="setUserSelected(usuario)" 
                            [ngClass]="{'table-danger': usuario.estado == 'suspendido' ,'table-warning': usuario.estado == 'pendiente'}">
                            <td>
                                <div *ngFor="let empresa of usuario.empresas">
                                    <span [ngClass]="{'empresaSeleccionada': empresa|empresaSeleccionUsuarioPipe:empresaFiltro}">{{empresa.RAZON_SOCI}}</span>
                                </div>
                                
                            </td>
                            <td>
                                {{usuario.email}}
                            </td>
                            <td>
                                {{usuario.perfil}}
                            </td>
                            <td>
                                {{usuario.estado}}
                            </td>
                            <td  ngbTooltip="Editar Usuario">
                                <i class="mdi mdi-24px mdi-account-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

        
                <!-- nuevo Paginado -->
                <div class="row" style="border-top:1px solid #ccc;">
                    <div class="col-3" style="padding:10px;">
                        <div class="bg-white text-center">
                            <select class="form-control selectpicker" 
                                    id="grilla.paginadoCantidad"                                         
                                    [(ngModel)]="grilla.paginadoCantidad"
                                    (change)="onChangeGrilla('paginadoCantidad',grilla.paginadoCantidad)"> 
                                <ng-container *ngFor="let opcion of grilla.paginadoDropDown">
                                    <option [ngValue]="opcion">Items x Página {{opcion}}</option>
                                </ng-container>
                            </select>
                        </div>    
                    </div>    
                    <div class="col-9" style="padding:10px;">
                        <div class="bg-white text-center">

                
                            <pagination-controls 
                                autoHide="true" 
                                previousLabel="{{'anterior' }}" 
                                nextLabel="{{'siguiente'}}" 
                                (pageChange)="pagePaginado = $event" 
                                maxSize="15"></pagination-controls>

                        </div>
                    </div>                        
                </div>
            </div>
    </div>
    </div>

    <app-footer></app-footer>

</div>
