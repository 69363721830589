import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams   } from '@angular/common/http';
import { environment } from '../../../environments/environment';
// import {RequestOptions, URLSearchParams  } from '@angular/common/http';


import { Observable, throwError } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class SqlserviceService {
  // URL_SERVER:string='https://us-central1-ignatest-c4444.cloudfunctions.net/';  //Desarrollo
   // URL_SERVER:string='https://us-central1-laflota-19ada.cloudfunctions.net/'; // Produccion
   URL_SERVER:string=environment.nutralMix.SQL_SERVER; // Produccion
  constructor(private _http: HttpClient) { }

getEmpresas(empresas:string[]): Observable<any>{
    console.log("getEmpresas");
    console.log("getEmpresas search",empresas);

// Clientes='06MA01'
    const url:string=this.URL_SERVER+'sql_getDatosEmpresa?Clientes=%27'+empresas+'%27';
console.log("getEmpresas url",url);

 return this._http.get(url);


 };

getEmpresasPorRazonSocial(razonSocial:string): Observable<any>{
    console.log("getEmpresasPorRazonSocial");
    console.log("getEmpresasPorRazonSocial razonSocial",razonSocial);

// Clientes='06MA01'
    const url:string=this.URL_SERVER+'sql_getDatosEmpresaPorRazonSocial?razonsocial='+razonSocial;
console.log("getEmpresasPorRazonSocial url",url);

 return this._http.get(url);


 };

getPedidos(codEmpresa:string[],cantidadDias:Number): Observable<any>{
    console.log("getEmpresas");
    console.log("getEmpresas search",codEmpresa);

// Clientes='06MA01'
    const url:string=this.URL_SERVER+'sql_getPedidos?Codigo_Cliente='+
    codEmpresa+'&cantidadDias='+cantidadDias;
// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidos?Codigo_Cliente=06MA01&cantidadDias=5000
// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidos?Codigo_Cliente=06MA01&cantidadDias=5000

console.log("getPedidos url",url);

 return this._http.get(url);


 };

getPedidoDetalles(idPedido:string): Observable<any>{
    console.log("getPedidoDetalles");
    console.log("getPedidoDetalles idPedido",idPedido);

// Clientes='06MA01'
    const url:string=this.URL_SERVER+'sql_getDetallePedido?Id_Pedido='+
    idPedido;
// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidos?Codigo_Cliente=06MA01&cantidadDias=5000
// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidos?Codigo_Cliente=06MA01&cantidadDias=5000

console.log("getPedidoDetalles url",url);

 return this._http.get(url);


 };

 getPedidoDetallado(codEmpresa:string,cantidadDias:Number): Observable<any>{
    console.log("getPedidoDetallado");
    console.log("getPedidoDetallado codEmpresa",codEmpresa);
    console.log("getPedidoDetallado cantidadDias",cantidadDias);

// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidoDetallado?Codigo_Cliente=01JA01&cantidadDias=100
    const url:string=this.URL_SERVER+'sql_getPedidoDetallado?Codigo_Cliente='+
    codEmpresa+'&cantidadDias='+cantidadDias;

console.log("getPedidoDetallado url",url);

 return this._http.get(url);


 };

getRemitoDetallado(codEmpresa:string,cantidadDias:Number): Observable<any>{
    console.log("getRemitoDetallado");
    console.log("getRemitoDetallado codEmpresa",codEmpresa);
    console.log("getRemitoDetallado cantidadDias",cantidadDias);

// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidoDetallado?Codigo_Cliente=01JA01&cantidadDias=100
    const url:string=this.URL_SERVER+'sql_getRemitoDetallado?Codigo_Cliente='+
    codEmpresa+'&cantidadDias='+cantidadDias;

console.log("getRemitoDetallado url",url);

 return this._http.get(url);


 };

 getRemitoDetalladoTransportista(cantidadDias:Number): Observable<any>{
    console.log("getRemitoDetalladoTransportista");
  
    console.log("getRemitoDetalladoTransportista cantidadDias",cantidadDias);

// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidoDetallado?Codigo_Cliente=01JA01&cantidadDias=100
    const url:string=this.URL_SERVER+'sql_getRemitoDetalladoTransportista?cantidadDias='+cantidadDias;

console.log("getRemitoDetallado url",url);

 return this._http.get(url);


 };

getRemitoDetalladoParaTransportista_IdInfotrak(cantidadDias:Number,IdInfotrak:Number): Observable<any>{
    console.log("APP_GetRemitoDetalladoParaTransportista_IdInfotrak");
  
    console.log("APP_GetRemitoDetalladoParaTransportista_IdInfotrak cantidadDias",cantidadDias);
    console.log("APP_GetRemitoDetalladoParaTransportista_IdInfotrak IdInfotrak",IdInfotrak);

// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidoDetallado?Codigo_Cliente=01JA01&cantidadDias=100
    const url:string=this.URL_SERVER+'sql_APP_getRemitoDetalladoParaTransportista_IdInfotrak?cantidadDias='+cantidadDias+'&IdInfotrak='+IdInfotrak;

console.log("getRemitoDetallado url",url);

 return this._http.get(url);


 };

getRemitoXNumero(remito:string): Observable<any>{
    console.log("getRemitoXNumero");
    console.log("getRemitoXNumero remito",remito);


// https://us-central1-nutraltest.cloudfunctions.net/sql_getPedidoDetallado?Codigo_Cliente=01JA01&cantidadDias=100
    const url:string=this.URL_SERVER+'sql_getRemitoXNumero?remito='+
    remito

console.log("getRemitoDetallado url",url);

 return this._http.get(url);


 };

getVehiculos(): Observable<any>{
  console.log("getVehiculos");
  const url:string=this.URL_SERVER+'buscaVehiculos';
  return this._http.get(url);
 };



 test(){
     console.log('test');

 }

    //https://us-central1-nutraltest.cloudfunctions.net/sql_setNotificacionTransportista?COD_CLIENT='11ff11'&N_REMITO='R123123123'&ESTADO_ENTREGA_TRANSPORTISTA='entregado'&USUARIO_FIRE='USUARIO_FIRE'&EMAIL_USUARIO='EMAIL_USUARIO'
    setNotificacionesTransportista(COD_CLIENT:string,N_REMITO:string,ESTADO_ENTREGA_TRANSPORTISTA:string,USUARIO_FIRE:string,EMAIL_USUARIO:string,COMENTARIO:string): Observable<any>{
        console.log("setNotificacionesTransportista");
        console.log("COD_CLIENT",COD_CLIENT);
        console.log("N_REMITO",N_REMITO);
        console.log("ESTADO_ENTREGA_TRANSPORTISTA",ESTADO_ENTREGA_TRANSPORTISTA);
        console.log("USUARIO_FIRE",USUARIO_FIRE);
        console.log("EMAIL_USUARIO",EMAIL_USUARIO);
        console.log("COMENTARIO",COMENTARIO);

        let fechaHoraEntrega=new Date();

        console.log("fechaHoraEntrega",fechaHoraEntrega.valueOf());

        // const COD_CLIENT=request.query.COD_CLIENT;
        // const N_REMITO=request.query.N_REMITO;
        // const ESTADO_ENTREGA_TRANSPORTISTA=request.query.ESTADO_ENTREGA_TRANSPORTISTA;
        // const USUARIO_FIRE=request.query.USUARIO_FIRE;
        // const EMAIL_USUARIO=request.query.EMAIL_USUARIO;

        const url:string=this.URL_SERVER+'sql_setNotificacionTransportista?COD_CLIENT='+COD_CLIENT+
        '&N_REMITO='+N_REMITO+
        '&ESTADO_ENTREGA_TRANSPORTISTA='+ESTADO_ENTREGA_TRANSPORTISTA+
        '&USUARIO_FIRE='+USUARIO_FIRE+
        '&COMENTARIO='+COMENTARIO+
        '&fechaHoraEntrega='+fechaHoraEntrega.valueOf()+
        '&EMAIL_USUARIO='+EMAIL_USUARIO;

        

        return this._http.get(url);


    };

setAceptaEntregaSinCliente(COD_CLIENT:string,ACEPTA_ENTREGA_SIN_CLIENTE:Boolean,USUARIO_FIRE:string,EMAIL_USUARIO:string): Observable<any>{
    console.log("setAceptaEntregaSinCliente");
    console.log("COD_CLIENT",COD_CLIENT);
    console.log("ACEPTA_ENTREGA_SIN_CLIENTE",ACEPTA_ENTREGA_SIN_CLIENTE);
    console.log("USUARIO_FIRE",USUARIO_FIRE);
    console.log("EMAIL_USUARIO",EMAIL_USUARIO);



    const url:string=this.URL_SERVER+'sql_setAceptaEntregaSinCliente?COD_CLIENT='+COD_CLIENT+
    '&ACEPTA_ENTREGA_SIN_CLIENTE='+ACEPTA_ENTREGA_SIN_CLIENTE+
    '&USUARIO_FIRE='+USUARIO_FIRE+
    '&EMAIL_USUARIO='+EMAIL_USUARIO;


    console.log("setAceptaEntregaSinCliente url",url);

    return this._http.get(url);


 };

sendMail(to, titulo,mensaje): Observable<any>{
    console.log("sendMessage",mensaje);

// let params: URLSearchParams = new URLSearchParams();
// params.set('var1', "val1");
// params.set('var2', "val2");

// let requestOptions = new RequestOptions();
// requestOptions.search = params;

  const params = new HttpParams();
//     this.header = new HttpHeaders();
//     this.params.set("assunto","subjetParams");
//     // this.header.append('Authorization', "clave");
//     this.header.append('Content-Type', "application/json");
//     this.header.append('Access-Control-Allow-Origin', "*");
//     // this.header.append('Access-Control-Allow-Origin', "http://localhost:4200");

//    var config={
//         params:this.params,
//         header:this.header,

    // }

const httpOptions = {
 headers: new HttpHeaders({
   'Content-Type':  'application/json',
   'Authorization': 'secret-key'
 })
};

var body1={
        title: 'foo',
        body: mensaje,
        userId: 1
      };

 // return this._http.post(this.URL_SERVER+'enviarEmail',body1,httpOptions);
 const url:string=this.URL_SERVER+'enviarEmail?to='+to+'&titulo='+titulo+'&mensaje='+mensaje;
   
   
  return this._http.get(url);



 };


}
