import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
// import {Location} from '@angular/common';
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';
import { MensajesService }  from '../../services/mensajes/mensajes.service';
import { Router,NavigationEnd } from "@angular/router";
import {  Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbdSortableHeader,  SortEvent, compare } from '../../directive/sortable.directive';
import { ModalSiNoComponent }  from '../../services/modal-si-no/modal-si-no.component';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {GrillaGenerica}                                           from '../../generico/grillaGenerica'
import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'
import { AlertasService}                from '@proyecto/servicios/firebase/alertas.service';
@Component({
  selector: 'app-alertas-reposicion-control',
  templateUrl: './alertas-reposicion-control.component.html',
  styleUrls: ['./alertas-reposicion-control.component.css']
})
export class AlertasReposicionControlComponent extends GrillaGenerica  implements OnInit {


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


    perfilUsuario   : any=null;
    listadoPrincipal: any[]=[];
    mensajeError    : string=null;
    showSpinner     : boolean=true;
    navigationSubscription;
    empresaSelected :any=null;
    editar:boolean=false;

  constructor( private db: FiredatabaseService,
      private mensageService:MensajesService,
      private alertasService:AlertasService,
      private router:Router,
      private cd: ChangeDetectorRef, 
      private _modal: NgbModal,

      // private _location: Location
      ) {
        super();
       }

  ngOnInit() {
       console.log("alerta Reposicion Control");
       console.log("alerta Reposicion Control",this.headers );
 

    this.perfilUsuario=null;
    this.getPerfil();
     this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();

       }
     });

  }
  getPerfil():void{
    console.log("alerta-control get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
      console.log("alerta-control perfil",perfil);
      this.perfilUsuario=perfil;
      this.seleccionListadoPorPerfil();
    }) ;
  }

  seleccionListadoPorPerfil(){
    if (this.perfilUsuario.data.perfil== "cliente" || this.perfilUsuario.data.perfil== "distribuidor"){
        
      if(!this.perfilUsuario.data.EmpresaSelected ){
        this.mensajeError="Seleccione un Cliente "
        this.alertService.confirm({ 
          title:   'Alertas Reposición', 
          message:  'Seleccione un Cliente', 
      
        }).then(data=>{console.log('ok confirm',data);  });
      
      }else{
        
        this.empresaSelected=this.perfilUsuario.data.EmpresaSelected;
        this.getAlertasReposicion(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);
    
      }
    } else{
      this.getAlertasReposicion(null);
    }
  }





getAlertasReposicion(COD_CLIENT:string){

  if(COD_CLIENT!=null){

    this.alertasService.getAlertasCliente(COD_CLIENT).subscribe(data=>{
      this.showSpinner=false;
      console.log("alerta-control  getAlertasCliente listadoPrincipal",data);
      this.listadoPrincipal=data;
      if(this.listadoPrincipal.length==0){
        this.mensajeError="No hay Alertas para mostrar";
      }
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
     
    });

  }else{


    this.alertasService.getTodasLasAlertas().subscribe(data=>{
        this.showSpinner=false;
        console.log("alerta-control  getAlertasCliente listadoPrincipal",data);
        this.listadoPrincipal=data;
        if(this.listadoPrincipal.length==0){
          this.mensajeError="No hay Alertas para mostrar";
        }
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
    
    });
  }
}

onChangeEmpresaSelected(){
  console.log('onChangeEmpresaSelected',JSON.stringify( this.empresaSelected));
  this.perfilUsuario['data']['EmpresaSelected']=this.empresaSelected;
  this.mensageService.setPerfilUsuarioObs(this.perfilUsuario);
  this.mensageService.setEmpresaSelectedObs(this.empresaSelected);
  this.db.updateUserEmpresaSelected(this.perfilUsuario.key,this.empresaSelected);
}
compareEmpresas(c1: any, c2:any): boolean {     
  return c1 && c2 ? c1.COD_CLIENT === c2.COD_CLIENT : c1 === c2; 
}

nuevoPedido(){
  this.mensageService.setPedidoWebSelectedObs(null);
  // this.router.navigate(['/alertaReposicionCrear']);
  this.editar=true
}

onSort({column, direction}: SortEvent) {

    console.log(column);
    console.log(direction);
  
    this.headers.forEach(header => {
 
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

  
    if (direction === '') {
  
    } else {

      if(column=='empresa'){  
        this.listadoPrincipal = [...this.listadoPrincipal].sort((a, b) => {
          const res = compare(a['empresa']['RAZON_SOCI'], b['empresa']['RAZON_SOCI']);
          return direction === 'asc' ? res : -res;
        });
      } else if(column=='producto'){  
        this.listadoPrincipal = [...this.listadoPrincipal].sort((a, b) => {
          console.log(a);
          console.log(b);
          const res = compare(a['alerta']['articulo']['DESCRIPCIO'], b['alerta']['articulo']['DESCRIPCIO']);
          return direction === 'asc' ? res : -res;
        });
      } else if(column=='diasDeAlimentoDisponible'){  
        this.listadoPrincipal = [...this.listadoPrincipal].sort((a, b) => {
          console.log(a);
          console.log(b);
          const res = compare(a['diasDeAlimentoDisponible'], b['diasDeAlimentoDisponible']);
          return direction === 'asc' ? res : -res;
        });
      }
  }
  }


  editarAlerta(alertaParaEditar){
    console.log("alerta-control alertaParaEditar",alertaParaEditar);
    let alerta=alertaParaEditar.alerta
    alerta['razonSocial']=alertaParaEditar.empresa.RAZON_SOCI;
    alerta['codCliente']=alertaParaEditar.empresa.COD_CLIENT;
    this.mensageService.setAlertaSelectedObs({alertaParaEditar:alerta, id:alertaParaEditar.alertaIndex});
    // this.router.navigate(['/alertaReposicionCrear']);
    this.editar=true;
  }



  borrarAlerta(alertaParaBorrar){

    console.log("borrarAlerta alertaParaBorrar",alertaParaBorrar);
 
    let modalRef =    this._modal.open(ModalSiNoComponent);
      modalRef.componentInstance.titulo = "Alerta - Borrar";
      modalRef.componentInstance.mensaje = "Confirma la eliminación del Alerta ";
      modalRef.componentInstance.dato = "Nombre: "+ alertaParaBorrar.alerta.nombreAlerta;
      modalRef.result.then(result=>{
              console.log("result: "+result);
              console.log("result.cause: "+result.cause);
              if(result.cause="SI"){
                this.borrarAlertaEnBase(alertaParaBorrar.empresa.COD_CLIENT,alertaParaBorrar.alerta, alertaParaBorrar.alertaIndex);
              }
              
            },reason=>{
              console.log("rison: "+reason);
              if (reason === ModalDismissReasons.ESC) {
              return 'by pressing ESC';
            } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
              return 'by clicking on a backdrop';
            } else {
              return  `with: ${reason}`;
            }
            } );
  }

  borrarAlertaEnBase(codCliente,alertaParaBorrar, id){

    this.alertasService.borrarAlerta(codCliente,alertaParaBorrar, id).subscribe(
      data=>{
        console.log(data);
        this.seleccionListadoPorPerfil();
            
      },error=>{
        console.log(error);

      });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }

  setEditar(editar:boolean){
    console.log('setEditar',editar);
    this.editar=editar;
  }

}

