import { Component, OnInit ,OnDestroy, Output,EventEmitter,Input, ChangeDetectorRef} from '@angular/core';
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalMensajeComponent }  from '../../services/modal-mensaje/modal-mensaje.component';
import { Validators, FormBuilder, FormGroup, FormControl,ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
// import { NgbDateCustomParserFormatter} from '../../util/dateformat/dateformat';
import { MensajesService }  from '../../services/mensajes/mensajes.service';
import { Router,NavigationEnd } from "@angular/router";
import { Subscription } from 'rxjs';
import {Location} from '@angular/common';
@Component({
  selector: 'app-pedido-creado-detalle',
  templateUrl: './pedido-creado-detalle.component.html',
  styleUrls: ['./pedido-creado-detalle.component.css']
})
export class PedidoCreadoDetalleComponent implements OnInit, OnDestroy {

 // Formulario
  pedidoForm = this.fb.group({
  codArticulo: [null, Validators.required],
  cantidad: ['',[Validators.required,validateMayorQue(0)]],
  // unidadDeMedida: [{value: '', disabled: true}, Validators.required],
  unidadDeMedida: ['',],
  fechadeEntrega: ['', Validators.required],
  comentario: ['', ],
 
    });

  getPerfilSubscription: Subscription;
  getProductosSuscription: Subscription;
  getPedidoParaEditarSubscription: Subscription;
  perfilUsuario:any=null;

  // son los productos que puede pedir este cliente. Se bajan de firebase
  listaDeProductos:any;
  pesoTotal:number=0;
  editCantidad:number[]=[];

 
  navigationSubscription;


  items:any[]=[];// Arrray que contiene los productos, cantidades y unidades solicitadas



  // Tiene los datos de un pedido que llega por mensaje
  pedidoParaEditar:any=null;


  // Mensaje
  mensajeError:string=null;
  errorFecha:string=null;
  minDate={};
  @Output() setEditar= new EventEmitter<boolean>();
  empresaSelected:any=null;

  constructor(      private db: FiredatabaseService,
      private mensageService:MensajesService,
      private router:Router,
      private _modal: NgbModal,
      private fb: FormBuilder,
        private _location: Location,

      ) { }

  ngOnInit() {
    console.log("pedido crear detalle");
    // Inicializo el perfil del usuario y pedido para editar
    this.perfilUsuario=null;
    this.pedidoParaEditar=null;
    this.getPerfil();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();
      
     }
     });

     var d = new Date();
      console.log('date ',d.getFullYear()+'-'+d.getMonth()+'-'+d.getDate());
      d.setDate( d.getDate() + 2 );// son 48 hs despues
     this.minDate= {
            "year": d.getFullYear(),
            "month": d.getMonth()+1, // get Month retonra de 0- 11
            "day":d.getDate()
          };
     this.pedidoForm.get('fechadeEntrega').setValue(this.minDate);

    console.log(this.pedidoForm.controls); 
    this.pedidoForm.valueChanges.subscribe(val => {
          this.mensajeError=null;
          this.errorFecha=null;
          console.log('valueChanges',val);
          console.log('valueChanges',val.fechadeEntrega);
         
          // var fechaEnt = new Date(val.fechadeEntrega.year+'-'+val.fechadeEntrega.month+'-'+val.fechadeEntrega.day).getTime();
          var fechaEnt = new Date(val.fechadeEntrega.month+'/'+val.fechadeEntrega.day+'/'+val.fechadeEntrega.year).getTime();
          var hoy = new Date().getTime();
          var diff = Math.trunc(( fechaEnt-hoy)/86400000);//1000*60*60*24
          console.log('fechaEnt',fechaEnt);
          console.log('hoy',hoy);
          console.log('diff',diff);
          if(isNaN(fechaEnt)){
            this.errorFecha='Fecha erronea';
          }
          if (diff<1){
             this.errorFecha='La primer fecha válida en 48 hs';
          }
          console.log('val.comentario',val.comentario);
          console.log('this.items.length',this.items.length);
          console.log('this.items.test',val.comentario=="" && this.items.length==0);
          if(val.comentario=="" && this.items.length==0){
          this.pedidoForm.controls['comentario'].setErrors({'incorrect': true});
          } else {
            this.pedidoForm.controls['comentario'].clearValidators;
          }


      });
   }



  getPerfil():void{
    // Busco el perfil del usuario
    console.log("pedido crear get Perfil");
    this.getPerfilSubscription=this.mensageService.getPerfil().subscribe(perfil=>{
      console.log("pedido crear perfil",perfil);
      this.perfilUsuario=perfil;
      // busco los productos ese perfil
      // this.getProductos(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);
     
      this.getPedidoParaEditar();
   }) ;
  }



  getPedidoParaEditar():void{
   this.getPedidoParaEditarSubscription= this.mensageService.getPedidoWebSelectedObs().subscribe(pedidoParaEditar=>{
      console.log("pedido crear pedidoParaEditar",pedidoParaEditar);
      this.pedidoParaEditar=pedidoParaEditar;
      // si existen datos los cargo en el 
      if(this.pedidoParaEditar!=null){
        if(pedidoParaEditar.cabecera!=null){
          if(pedidoParaEditar.cabecera.fechaEntrega!=null){
            this.pedidoForm.get('fechadeEntrega').setValue(pedidoParaEditar.cabecera.fechaEntrega);   
          }
          if(pedidoParaEditar.cabecera.fechaEntrega!=null){
            this.pedidoForm.get('comentario').setValue(pedidoParaEditar.cabecera.comentario);   
          }
        } 
        this.empresaSelected=this.pedidoParaEditar.cabecera.empresa;
        this.getProductos(this.pedidoParaEditar.cabecera.empresa.COD_CLIENT);
      } else{
        this.empresaSelected=this.perfilUsuario.data.EmpresaSelected;
        this.getProductos(this.empresaSelected.COD_CLIENT);
      }
       
    })
  
  }

  getProductos(codigoCliente){
    // busco los productos ese cliente y los almaceno en lista de productos.
   this.getProductosSuscription= this.db.getProductos(codigoCliente).subscribe(listaDeProductos=>{
        this.listaDeProductos=listaDeProductos;
        console.log("pedido crear detalle lista productos",this.listaDeProductos);
        this.agregarItems();
         
    });
}


  agregarItems(){

    if(this.pedidoParaEditar && this.pedidoParaEditar.detalle.length>0 &&  this.listaDeProductos.length>0){
      console.log('pedidoForm',this.pedidoForm);
      for(let i=0; i< this.pedidoParaEditar.detalle.length; i++ ) {
          
        this.agregarItem( this.pedidoParaEditar.detalle[i].articulo.COD_ARTICU,
                          this.pedidoParaEditar.detalle[i].cantidad,
                          this.pedidoParaEditar.detalle[i].unidad);
      };
    }    
  }



  // Borra el item de la disponibilidad de productos y lo agrega a la lista de items a comprar
    agregarItem(cod_articu,cantidad,unidadDeMedida){

      console.log("pedido crear detalle agregarItem cod_articu", cod_articu);
      console.log("pedido crear detalle agregarItem cantidad", cantidad);
      console.log("pedido crear detalle agregarItem unidadDeMedida", unidadDeMedida);
      console.log("pedido crear detalle this.pedidoForm.value.unidadDeMedida", this.pedidoForm.value.unidadDeMedida);
      console.log("pedido crear detalle this.pedidoForm.value.fechadeEntrega", this.pedidoForm.value.fechadeEntrega);
      console.log("pedido crear detalle this.pedidoForm.value.comentario", this.pedidoForm.value.comentario);
  
     
      let validaciones:boolean=true;
      let item:any;
      this.mensajeError="";
      let articulo:any=null;
     

      
      if(!cod_articu){
        
        this.mensajeError="Seleccione un artículo. ";
        validaciones=false;

      } else {

      }
      if(cantidad<=0){
             this.mensajeError=this.mensajeError+" La cantidad debe ser mayor que cero"
         validaciones=false;

      }

      // verificar si la sumo supera los kilos no supere los 28000 kg,
      this.pesoTotal=0;
      for (var i = 0; i < this.items.length; i++) {
        this.pesoTotal=this.pesoTotal+this.items[i].cantidad;
      }

       

       if(this.pesoTotal+cantidad>28000){
           this.mensajeError=this.mensajeError+" Superó los 28000 kg. La cantidad máxima que puede solicitar es: " +(28000-this.pesoTotal);
         validaciones=false;

       }else{

         this.pesoTotal=this.pesoTotal+cantidad;
       }
   

      if (validaciones){

        for(var i =  this.listaDeProductos.length - 1; i >= 0; i--) {
          if( this.listaDeProductos[i].COD_ARTICU == cod_articu) {
              this.pedidoForm.get('unidadDeMedida').setValue(this.listaDeProductos[i]. ID_MEDIDA_STOCK);
              articulo = this.listaDeProductos[i];
             
          }
        }


       //Copio los datos al array del ítems del pedido
        this.items.push({articulo:articulo,cantidad:cantidad,unidad:unidadDeMedida });
        this.editCantidad.push(0);

       // Limpio el formulario
        this.pedidoForm.get('codArticulo').setValue(null);
        this.pedidoForm.get('unidadDeMedida').setValue('');
        this.pedidoForm.get('cantidad').setValue('');
        this.pedidoForm.get('codArticulo').markAsPristine();
        this.pedidoForm.get('unidadDeMedida').markAsPristine();
        this.pedidoForm.get('cantidad').markAsPristine();
         this.pedidoForm.get('codArticulo').markAsUntouched();
        this.pedidoForm.get('unidadDeMedida').markAsUntouched();
        this.pedidoForm.get('cantidad').markAsUntouched();

       // Saco el articulo de la lista de productos.
        for(var i =  this.listaDeProductos.length - 1; i >= 0; i--) {
          if( this.listaDeProductos[i].COD_ARTICU === this.items[this.items.length - 1].articulo.COD_ARTICU) {
           console.log("saco",this.listaDeProductos[i].COD_ARTICU);
           this.listaDeProductos.splice(i, 1);
          }
        }

      }else {
         console.log("Validaciones Incorrectas");
         return;
      }
}


// // Borrar Item saca el item del listado  a comprar y lo agrega a la lista de productos disponibles
//  modificarItem(index){

//     console.log("pedido crear detalle index", index);
//     console.log("pedido crear detalle modificar Item Articulo", this.items[index].articulo); 

//     // Copio los datos en formulario
//     this.pedidoForm.get('unidadDeMedida').setValue(this.items[index].unidad);
//     this.pedidoForm.get('cantidad').setValue(this.items[index].cantidad);
//     this.pedidoForm.get('codArticulo').setValue(this.items[index].articulo.COD_ARTICU);
    
   
    
    
//     // Agrego el artículo al listado de Productos
//     this.listaDeProductos.push(this.items[index].articulo);
   

//     // BORRA EL ITEM DE LA LISTA
//     this.items.splice(index, 1);
//     // this.cd.detectChanges();
//   }

modificarItem(index){
  this.editCantidad[index]=this.items[index].cantidad;
  // this.cantidadEditadamostrarValidacion

}

confirmarModificacionItem(index){

    this.mensajeError='';
    // verificar si la sumo supera los kilos no supere los 28000 kg,
      let pesoTotal=0;
      for (var i = 0; i < this.items.length; i++) {
        if(i!=index){pesoTotal=pesoTotal+this.items[i].cantidad;}
      }

       

       if(pesoTotal+this.editCantidad[index]>28000){
           this.mensajeError=this.mensajeError+" Superó los 28000 kg. La cantidad máxima que puede solicitar es: " +(28000-pesoTotal);
      

       }else{

         this.pesoTotal=pesoTotal+this.editCantidad[index];
         this.items[index].cantidad= this.editCantidad[index];
         this.editCantidad[index]=0;
       }
   


}

 // Borrar Item saca el item del listado  a comprar y lo agrega a la lista de productos disponibles
 borrarItem(index){

    console.log("pedido crear detalle borrarItem", index); ;
     this.listaDeProductos.push(this.items[index].articulo);
     this.items.splice(index, 1);
     this.editCantidad.splice(index, 1);
 }



 setArticuloSeleccionado(cod_articu){
  // var r = confirm("Do you really want to change?");
    console.log("pedido crear cod_articu", cod_articu);
    this.mensajeError=null;
    // Busco la unidad y la copio en el formulario    
     for(var i =  this.listaDeProductos.length - 1; i >= 0; i--) {
          if( this.listaDeProductos[i].COD_ARTICU == cod_articu) {
              this.pedidoForm.get('unidadDeMedida').setValue(this.listaDeProductos[i]. ID_MEDIDA_STOCK);
              
             
          }
    }
 }

clickedCantidad(cantidad){
      console.log("clickedCantidad", cantidad);

}

  grabarPedido(){
    console.log("Grabar Pedidos");
    console.log("Grabar perfilUsuario", this.perfilUsuario );
    console.log("Grabar this.pedidoParaEditar", this.pedidoParaEditar );


    if(this.pedidoForm.value.comentario=="" || this.items.length==0){
      this.pedidoForm.controls['comentario'].setErrors({'incorrect': true});
      this.mostrarMensajeModal('Verifique',"Ingrese Articulos o un comentario para generar el pedido",'');
      return;
    } else {
      this.pedidoForm.controls['comentario'].clearValidators;
      if (this.pedidoParaEditar && this.pedidoParaEditar.NRO_PEDIDO!=null){
        console.log("Modificar Pedidos");
        this.db.modificarPedidoWeb(this.perfilUsuario,this.empresaSelected,this.items,this.pedidoForm.value.fechadeEntrega,this.pedidoForm.value.comentario,this.pedidoParaEditar.NRO_PEDIDO).subscribe(      next=>{
            console.log('next',next);
            this.mostrarMensajeModal('Modificación Pedido Web',"Se ha Modificado el Pedido WEB  Nro "+this.pedidoParaEditar.NRO_PEDIDO ,'');
            this.setEditar.emit(false);
            },
            error=>{
              console.log('error',error);
        });
      }else{
        console.log("Grabar Pedidos", this.items);
        this.db.crearPedidoWeb(this.perfilUsuario,this.perfilUsuario.data.EmpresaSelected,this.items,this.pedidoForm.value.fechadeEntrega,this.pedidoForm.value.comentario)
        .subscribe(      next=>{
            console.log('next',next);
          this.mostrarMensajeModal('Nuevo Pedido Web',"Se ha creado un Nuevo Pedido WEB con el Nro "+next.NroPedido ,'');
            // this._location.back();
            // this.router.navigate(['/pedidosCrearListados']); 
            this.setEditar.emit(false)
          },
          error=>{
            console.log('error',error);
            // var r = confirm("error al crear el pedido: "+error);
          });
      }    
    }
  }

  ngOnDestroy(){
    console.log("on destroy");
    this.mensageService.setPedidoWebSelectedObs(null);
    // this.getPedidoParaEditarSubscription.unsubscribe();
    if(this.getPerfilSubscription){
     this.getPerfilSubscription.unsubscribe();
     }
     if (this.getProductosSuscription) {
     this.getProductosSuscription.unsubscribe();
   }
      console.log(this.navigationSubscription);
    if (this.navigationSubscription) {  

       this.navigationSubscription.unsubscribe();
    
    }
 
  }

mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}

  cancelar(){
    console.log('cancelar');
    this.setEditar.emit(false);
  }

  onChangeEmpresaSelected(){
    console.log('onChangeEmpresaSelected',JSON.stringify( this.empresaSelected));
    this.limpiar();
    this.getProductos(this.empresaSelected.COD_CLIENT );
    this.perfilUsuario['data']['EmpresaSelected']=this.empresaSelected;
    this.mensageService.setPerfilUsuarioObs(this.perfilUsuario);
    this.mensageService.setEmpresaSelectedObs(this.empresaSelected);
    this.db.updateUserEmpresaSelected(this.perfilUsuario.key,this.empresaSelected);
    
  }

  limpiar(){
    
       // Limpio el formulario
       this.pedidoForm.get('codArticulo').setValue(null);
       this.pedidoForm.get('unidadDeMedida').setValue('');
       this.pedidoForm.get('cantidad').setValue('');
       this.pedidoForm.get('comentario').setValue('');
       this.pedidoForm.get('codArticulo').markAsPristine();
       this.pedidoForm.get('unidadDeMedida').markAsPristine();
       this.pedidoForm.get('cantidad').markAsPristine();
       this.pedidoForm.get('comentario').markAsPristine();
        this.pedidoForm.get('codArticulo').markAsUntouched();
       this.pedidoForm.get('unidadDeMedida').markAsUntouched();
       this.pedidoForm.get('cantidad').markAsUntouched();
       this.pedidoForm.get('comentario').markAsUntouched();
       this.pedidoForm.get('fechadeEntrega').setValue(this.minDate);

       this.pedidoParaEditar=null;
       this.pesoTotal=0;
       this.items=[];
  }
  
  compareEmpresas(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.COD_CLIENT === c2.COD_CLIENT : c1 === c2; 
  }
}





export function validateMayorQue(number: number): ValidatorFn {
     console.log('number',number);
  return (control: AbstractControl): {[key: string]: any} | null => {
      console.log('control',control);
      let esCorrecto=false;
      if(control.value>number){
            esCorrecto=true;
      }
    // const forbidden = number.test(control.value);
     console.log('esCorrecto',esCorrecto);
    return !esCorrecto ? {'validateMayorQue': {value: control.value}} : null;
  };


  
}