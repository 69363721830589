<div class="component ">

    <app-header></app-header>

    <div class="estructura" >

        <div class="top-estructura">
<!-- mostrar: {{mostrarFormulario}} -->
            <h2 *ngIf="solicitudes">Solicitudes ({{solicitudes.length  }})</h2>
            <div class="text-center">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"  [(ngModel)]=buscarPendientes (change)="buscarPendienteCambio(buscarPendientes)">
                      <label class="form-check-label" for="inlineCheckbox1">Pendientes</label>
                </div>
                 <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2"  [(ngModel)]="buscarProcesados" (change)="buscarProcesadosCambio(buscarProcesados)">
                     <label class="form-check-label" for="inlineCheckbox2">Procesados</label>
                </div>
                
            </div>

        </div>

        <div class="row inner-estructura" > 

            <div class="col-sm-12" >

                <!--<app-spinner *ngIf="showSpinner" [message]="mensajeSpinner"></app-spinner>-->
                <div class="text-center"><div class="loading" *ngIf="showSpinner"></div>
            </div>

                <div *ngIf="mensajeError" >{{mensajeError}}</div>
       

                <div class="accordion" id="accordionExample" *ngIf="!mostrarFormulario" >
                    <div *ngIf="solicitudes.length==0">Sin solicitudes para mostrar</div>
                    <div *ngFor="let solicitud of solicitudes ; let i = index">

                        <div class="top-inner-estructura clearfix" id="headingOne">
                            <h3 data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne" >
                             

                    <!-- Email -->
                                 <span > <b>Email: </b>  <span class=" textosNormalesEncabezado "> {{solicitud.email}}</span></span>
                    <!-- Fecha de la Solicitud -->
                               <span class="marginEnTitulo"> <b>Fecha de la Solicitud: </b> 
                                <span class=" textosNormalesEncabezado" >{{ solicitud?.timeStamp?.toDate() }}</span>
                                <!-- {{ solicitud.timeStamp.toDate() | date:'dd-MM-yyyy' }} -->
                                <!-- solicitud?.timeStamp.seconds * 1000 | date  -->
                                </span>

                                <p>
                                    <!-- <a href="javascript:;" (click)="editarSolicitud(solicitud,i)">Editar</a> -->
                                    <a href="javascript:;" (click)="procesarSolicitud(solicitud,i)"  *ngIf="solicitud.estado=='Pendiente'">Procesar</a>
                                </p>
                            </h3>
                            <span class="etiqueta" *ngIf="solicitud.estado">{{solicitud.estado}}</span>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="table-body">
                                <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y ">
                                    <thead>
                                        <tr >
                                            <th>
                                                Razón Social
                                            </th>
                                            <th>
                                                Cuit
                                            </th>
                                            <th>
                                                Comentario
                                           </th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>

                                            <td >
                                                {{solicitud.razonSocial}}
                                            </td >
                                            <td   >
                                                {{solicitud.cuit | mask:'00-00000000-0'}}
                                            </td >
                                            <td >
                                                {{solicitud.comentario}}
                                            </td >
                                            
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="mostrarFormulario">

                
                    <app-nuevo-usuario  [userSelected]="userSelected" (accion)="accion($event)"></app-nuevo-usuario>
            
                </div>

            </div>
        </div>
    </div>

  

    <app-footer></app-footer>

</div>
