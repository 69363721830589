<div class="component clientes">

    <app-header></app-header>

    <p>
      clientes-crear works!
    </p>

    <app-footer></app-footer>

</div>
