<div class="component alertas">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Alerta de Reposición:</h2>
             <span class="etiqueta black" *ngIf="razonSocial">{{razonSocial  }}</span>
            
        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">
               <!--  <p class="alerta alerta-2">
                    Form Status: {{ form.status }}
                    Form Status: {{ form.value }}
                </p> -->
                <br>

                <!-- alertaParaEditar -->
                 <!-- ● Buscar Cliente --> 
                    <div class="form-group col-md-4" *ngIf="mostrarBotonBuscaClientes && !alertaParaEditar" >
                        <label for="buscarCliente" >Buscar un cliente</label>
                         <input [(ngModel)]="clienteInput"  class="form-control" placeholder="ingrese el cliente"/>
                           <button class="btn btn-primary" (click)="buscarEmpresa()">Buscar</button>
                         

                    </div> 
                    <!-- <div class="col-md-4">
                        <button class="btn btn-secondary float-right" (click)="actualizarListadoDeArticulos()">Actualizar Artículos</button>
                    </div> -->
                   


                <form class="row" [formGroup]="form">

                    <!-- ● Nombre de la Alerta -->
                    <div class="form-group col-md-4">
                        <label for="nombreAlerta">Nombre del alerta</label>
                        <input type="text" class="form-control" id="nombreAlerta" formControlName="nombreAlerta" required forbiddenNameValidator />
                        <div *ngIf=" (form.controls.nombreAlerta.invalid && (form.controls.nombreAlerta.dirty|| form.controls.nombreAlerta.touched  ) )" class="alerta alerta-3">
                            El nombre de Alerta es no es válido.
                        </div>
                    </div>


                    <!-- ● Artículo -->
                    <div class="form-group col-md-4 ">
                        <label for="estado">Artículo
                            <span *ngIf="mdiSpin" class="float-right" ><i class="mdi mdi-refresh mdi-spin text-primary mdi-24px"></i>Buscando Articulos </span>
                        </label>
                        <select   class="form-control selectpicker "  
                                id="articulo"   
                                formControlName="articulo" 
                                [compareWith]="compareArticulos"  >
                            <option   [ngValue]="''" >Artículo</option>
                            <option  *ngFor="let articulo of listaDeArticulos" [ngValue]="articulo" >{{articulo.DESCRIPCIO}} - {{articulo.COD_ARTICU}}</option>
                        </select>
                        <div *ngIf=" (form.controls.articulo.invalid && (form.controls.articulo.dirty|| form.controls.articulo.touched  ) )" class="alerta alerta-3">
                            El articulo es no es válido.
                        </div>
                    </div>


                    <!-- ● Stock Inicial -->
                    <div class="form-group col-md-4">
                        <label for="stockInicial">Stock Inicial</label>
                        <input type="number" class="form-control" id="stockInicial" formControlName="stockInicial" required />
                        <div *ngIf=" (form.controls.stockInicial.invalid && (form.controls.stockInicial.dirty|| form.controls.stockInicial.touched  ) )" class="alerta alerta-3">
                            El stockInicial es no es válido.
                        </div>
                    </div>


                    <!-- ● Cantidad de animales -->
                    <div class="form-group col-md-4">
                        <label for="cantidadDeAnimales">Cantidad De Animales</label>
                        <input type="number" class="form-control" id="cantidadDeAnimales" formControlName="cantidadDeAnimales" required />
                        <div *ngIf=" (form.controls.cantidadDeAnimales.invalid && (form.controls.cantidadDeAnimales.dirty|| form.controls.cantidadDeAnimales.touched  ) )" class="alerta alerta-3">
                            El stockInicial es no es válido.
                        </div>
                    </div>


                    <!-- ● Consumo diario -->
                    <div class="form-group col-md-4">
                        <label for="consumoDiario">Consumo Diario</label>
                        <input type="number" class="form-control" id="consumoDiario" formControlName="consumoDiario" required />
                        <div *ngIf=" (form.controls.consumoDiario.invalid && (form.controls.consumoDiario.dirty|| form.controls.consumoDiario.touched  ) )" class="alerta alerta-3">
                            El consumo diario es no es válido.
                        </div>
                    </div>


                    <!-- ● capacidad De Almacenamiento -->
                    <div class="form-group col-md-4">
                        <label for="capacidadDeAlmacenamiento">Capacidad de Almacenamiento</label>
                        <input type="number" class="form-control" id="capacidadDeAlmacenamiento" formControlName="capacidadDeAlmacenamiento" required />
                        <div *ngIf=" (form.controls.capacidadDeAlmacenamiento.invalid && (form.controls.capacidadDeAlmacenamiento.dirty|| form.controls.capacidadDeAlmacenamiento.touched  ) )" class="alerta alerta-3">
                            El capacidad De Almacenamiento es no es válido.
                        </div>
                    </div>

                    <div *ngIf="form.errors" class="alerta alerta-3">
                        {{form.errors.formError}}
                    </div>

                    <!-- ● Días de Margen (antelación para hacer pedidos) -->
                    <div class="form-group col-md-4">
                        <label for="diasDeMargen">Días de Margen</label>
                        <input type="number" class="form-control" id="diasDeMargen" formControlName="diasDeMargen" required />
                        <div *ngIf=" (form.controls.diasDeMargen.invalid && (form.controls.diasDeMargen.dirty|| form.controls.diasDeMargen.touched  ) )" class="alerta alerta-3">
                            El dias De Margen es no es válido.
                        </div>
                    </div>

               


                    <!-- <button  class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit </button> -->
                    <div class="form-group col-md-12 text-center">
                        <button class="btn btn-primary"  (click)="grabarAlerta()"> Grabar Alerta</button>
                        <!-- <button class="btn btn-secondary" (click)="borrarFormulario()">Limpiar </button>                         -->
                        <button class="btn btn-secondary" (click)="cancelar()">Cancelar </button>                        
                    </div>

                </form>


            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
