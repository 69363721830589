
// https://angular-maps.com/api-docs/agm-core/components/agminfowindow
import { Component, OnInit,OnDestroy, Input } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
import { Subscription } from 'rxjs';

import {SqlserviceService} from '../../services/sql/sqlservice.service';


import { LISTA_TRANSPORTES_INFOTRAK } from '../../util/tablas/lista_transportes_infotrak';
import { TransporteInfotrak } from '../../util/objetos/transportes_infotrak';

// import * as MarkerClusterer from "@google/markerclusterer"



@Component({
  selector: 'app-mapa-monitor',
  templateUrl: './mapa-monitor.component.html',
  styleUrls: ['./mapa-monitor.component.css']
})
export class MapaMonitorComponent implements OnInit, OnDestroy {

  vehiculos:any=null;
  zoom: number = 7;   /* 15 */
  lat: number = -35.464475; 
  lng: number = -60.8467927;
  label:string="";
  etiquetaMapa : string[]=[];
  timerSuscription:Subscription=null;

    icono:any[]= [{
        // url: '../../assets/images/Truck-icon.ico',
        url: '../../assets/images/general/ic_mappin.png',
        scaledSize: {
            width: 28,
            height: 36,
        }
        // ,
        //  'background-color': 'blue',

        // transform: 'rotate(45deg)'

      }];
      icono_def:any= {
       
        url: '../../assets/images/general/ic_mappin.png',
        scaledSize: {
            width: 28,
            height: 36,
        }
      };
      icono_N:any= {
       
        url: '../../assets/images/iconos/camion/N.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_S:any= {
       
        url: '../../assets/images/iconos/camion/S.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
       icono_E:any= {
       
        url: '../../assets/images/iconos/camion/E.png',
        scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_O:any= {
       
        url: '../../assets/images/iconos/camion/O.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };



      icono_NO:any= {
       
        url: '../../assets/images/iconos/camion/NO.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
       icono_NE:any= {
       
        url: '../../assets/images/iconos/camion/NE.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };
      icono_SE:any= {
       
        url: '../../assets/images/iconos/camion/SE.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };

      icono_SO:any= {
       
        url: '../../assets/images/iconos/camion/SO.png',
         scaledSize: {
            width: 64,
            height: 64,
        }
      };

   mensajeSpinner = "mensaje spinner";
   showSpinner:boolean = true;
   mensaje:String = '';

  ListaTransporteInfotrak:TransporteInfotrak[]=LISTA_TRANSPORTES_INFOTRAK;
    //  icono= {
    //     path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145  5,90 95,90 z',
    //     scale: 1,
    //     fillColor: "red",
    //     fillOpacity: 0.8,
    //     strokeWeight: 2,
    //     rotation: 150 //this is how to rotate the pointer
    // };

    // icono triangulo
    //  icono=  {
    //   // path: 'M 0 0 L -35 -100 L 35 -100 z',
    //   // path: '../../assets/images/Truck-icon.svg',  (esto no funciona)
    //   path :'M156 1368 c-4 -13 -25 -222 -22 -225 0 0 145 -28 321 -60 l320 -60 6 56 c4 31 7 91 8 133 1 76 1 76 -26 82 -16 3 -145 22 -288 41 -143 20 -272 38 -288 41 -18 4 -28 1 -31 -8z',
    //   fillColor: '#3884ff',
    //   fillOpacity: 0.7,
    //   scale: 1,
    //   strokeColor: '#356cde',
    //   rotation: 90, //rota
    //   strokeWeight: 1
    // };

  constructor(public sql:SqlserviceService) { }

  ngOnInit() {

    const source = timer(500,180000); // se dispara cada 10000 ms (10seg) el primero es a los 50 ms
    //output: 0,1,2,3,4,5......               // 1000->1 seg, 60000-> 1min , 180000-> 3min
    for (var i = 0; i < this.ListaTransporteInfotrak.length; i++) {
        
      this.etiquetaMapa[i]=this.ListaTransporteInfotrak[i].chofer + ' - ' +this.ListaTransporteInfotrak[i].telefono;

    }
    this.timerSuscription = source.subscribe(val =>{
        console.log(val);
        this.getVehiculos();
    });
   

   
  }

  getVehiculos(){
    console.log("getVehiculos");
    const s= this.sql.getVehiculos().subscribe(data=> {
        console.log(data);
        console.log("getVehiculos",data);
        this.vehiculos=data;
        this.getUbicacionTransporte();
      //   const clusterOptions = {
      //     imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      //     gridSize: 30,
      //     zoomOnClick: false,
      //     maxZoom: 10,
      // }
    
      //   new MarkerClusterer(map, this.vehiculos, clusterOptions)
        this.showSpinner=false;
        s.unsubscribe();
          },
          error =>{console.log("getVehiculos",error);
              this.showSpinner=false;
              this.mensaje="Se produjo un error: /n getVehiculos: " +error.name+"/n Mensaje: "+error.message+"/n";
        s.unsubscribe();
    });
  }

  getUbicacionTransporte(){


    for (let index = 0; index <  this.vehiculos.length; index++) {
      const vehiculo =  this.vehiculos[index];
      switch (vehiculo.sentido) {
          
        case "Norte":
          this.icono[index]=this.icono_N;
          break;
        case "Sur":
          this.icono[index]=this.icono_S;
          break;
        case "Este":
          this.icono[index]=this.icono_E;
          break;
        case "Oeste":
          this.icono[index]=this.icono_O;
          break;  
        case "NorEste":
          this.icono[index]=this.icono_NE;
          break;    
        case "NorOeste":
          this.icono[index]=this.icono_NO;
          break;
        case "SurEste":
          this.icono[index]=this.icono_SE;
          break;
        case "SurOeste":
          this.icono[index]=this.icono_SO;
          break;

        default:

          this.icono[index]=this.icono_def;
          break;
        }
      
    }
  
  }

  ngOnDestroy(){
    console.log("OnDestroy");
    this.timerSuscription.unsubscribe();
  }
  
}
