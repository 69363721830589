import { log, logIf, logTable, values } from '@maq-console';

export class Grilla {

  paginadoTipo            : string   = 'local';   // local / servidor
  paginadoCantidad        : number   = 20;
  paginadoAutoHide        : boolean  = true;
  paginadoDropDown        : number[] = [1,2,5,10,20,30,50,100];

  resultKey1              : string   = null;
  resultKeyN              : string   = null;
  resultCantidad          : number   = null;
  coleccionCantidadDocs   : number   = null;

  orderField              : string   = 'nombre';
  orderReverse            : boolean  = false;
  orderServer             : string[] = [];  // Columnas por las cuales está disponible el ordenamiento

  filtroNombre            : string   = 'nombre';  // Filtros por los que se realizan las búsquedas por Texto, por lo general es el campo nombre
  filtros                 : any[]    = [];  // Array de ngModel del HTML. Ejemplo: [(ngModel)]="grilla.filtros['nombre']"
  filtrosServer           : string[] = [];  // Filtros por los que está disponible el ordenamiento
  filtrosGrupos           : any[]    = [];  // Grupos de Filtros que deben utilizarse en conjunto
  filtrosStyle            : any[]    = [];  // Estilo con que se muestra el Filtro
  filtrosInicial          : any[]    = [];  // Valor con el que se inicializan los filtros indicandos en el array, ej: ['filtroName1|valorInicial','filtroName2|valorInicial']
  whereOriginal           : any[]    = [];  // Cada elemento tiene la estructura { key: xxx, operador: xxx, value: xxx } - Filtros iniciales al cargarse ListadoPrincipal
  whereArray              : any[]    = [];  // Cada elemento tiene la estructura { key: xxx, operador: xxx, value: xxx } - Filtros iniciales + seteados en los filtros del cabezal de la grilla

  paginaRequerida         : string   = 'todas';  // 'primera'|'siguiente'|'anterior'|'anteriorOverflow' (paginadoTipo = 'servidor'), 'todas' (local)
  paginaRequeridaAnterior : string   = null;     // Se compara con [paginaRequerida] para calcular [paginaActualNro]
  paginaActualNro         : number   = null;     // nro de pagina actual
  paginaActualNroAnterior : number   = null;     // nro de pagina actual
  paginaOverflow          : boolean  = false;    // Se pone en true, cuando te pasas de la última página con resultados
  
  cotizacionesActivas     : boolean  = false;    // Configura msg.cacheColecciones con las cotizaciones correspondientes, y las calcula en this.cotizaciones
  campoKeywords           : boolean  = true;     // false (resuelve con array keywords), true busca en el campo nombre. Indica si el filtro Nombre es sensitive (diferencia mayúsculas) o no. A menos que la tabla tenga definido el array keywords viene con true
  camposDecimal           : string[] = [];       // Indica que campos del formulario son de tipo decimal o currency

  verColumnaKey           : boolean = false;
  verFiltrosAdicionales   : boolean = false;
  verFilasAdicionales     : boolean = false;
  verFilaAdicionalTr      : any[]   = [];

  keyHoverActivo          : string  = null;

  tipoPerfilUsuario       : string  = 'Desarrollador';

  constructor(init?:Partial<Grilla>) {

    Object.assign(this, init);

    log(...values('funcionComponente','constructor grilla'));

  }
  
  
  setValue(nombreColeccion:string, init?:Partial<Grilla>) {    
      // console.log("grilla.setValue",nombreColeccion);
      Object.assign(this, init);
  }

  colspanPaginado(cantCampos: number, cual:string):number {

    let izquierda = Math.trunc(cantCampos / 2);
    let derecha   = Math.trunc(cantCampos / 2);

    let mitad = cantCampos / 2;
    if(mitad != Math.trunc(cantCampos / 2)) {

      izquierda = Math.trunc(cantCampos / 2);
      derecha   = Math.trunc(cantCampos / 2)+1;
    }
    //console.log("colspanPaginado",cantCampos, izquierda, derecha);

    if(cual=='izquierda') {
      return izquierda;
    } else {
      return derecha;
    }

  }

  setColeccionCantidadDocs(cantidadDocumentosFisicos: number,
                           cantidadDocumentosLogicos:number, tipoPerfilUsuario:string) {
      log(...values("valores","setColeccionCantidadDocs:",arguments));
      if(this.paginadoTipo == 'servidor') {
          if(["Desarrollador"].indexOf(this.tipoPerfilUsuario)!=-1) {
              this.coleccionCantidadDocs = cantidadDocumentosFisicos;
          } else {
              this.coleccionCantidadDocs = cantidadDocumentosLogicos;
          }
      }
  }

  mostrarFiltro(cual:string) {
    if(this.paginadoTipo == 'local') {
      return true;
    } else if(this.filtrosServer.indexOf(cual)!=-1) {
      return true;
    } else {
      return false;
    }
  }

  setOrder(value: string) {
    if (this.orderField === value) {
      this.orderReverse = !this.orderReverse;
    }
    this.orderField = value;
  }

  habilitarDeshabilitarFilasAdicionales() {
    this.verFilasAdicionales=!this.verFilasAdicionales;
    if(this.verFilasAdicionales==false) {
        this.verFilaAdicionalTr=[];
    }
  }

  habilitarDeshabilitarFilaAdicional(index) {
    let posIndex=this.verFilaAdicionalTr.indexOf(index);
    if(posIndex==-1) {
      this.verFilaAdicionalTr.push(index);
    } else {
      this.verFilaAdicionalTr.splice(posIndex,1); // elimina el elemento
    }
  }

  colorFilaSegunEstado(settings:any):any {
      //console.log("colorFilaSegunEstado", settings);
      if(settings==null || settings===undefined) {
        return {};
      } else if(settings.isBorrado) {
        return { 'color':'red' };
      } else if(settings.isActivo==false) {
        return { 'color':'#ccc' };
      } else {
        return {};
      }
    }

    // imporar como funcion de fn.funciones (no como servicio)
    ordenarXAtributo(key, order = 'asc', translateValue) {
      /*
       * Sirve para Ordena un Array de Objetos
       *
       * Ejemplo
       * this.listadoDistribuidores.sort(this.fn.ordenarXAtributo(orderBy[0].key, orderBy[0].ascDesc,false));
       *
       * const LISTADO_DE_PAISES = [
       *   {id:'Argentina', val:'Argentina',  'husosHorarios': ['UTC-3'] },
       *   {id:'Chile',     val:'Chile',      'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Brasil',    val:'Brasil',     'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Colombia',  val:'Colombia',   'husosHorarios': ['UTC-5'] },
       * ];
       *
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('id'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val', 'desc'));
       */

      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        if(translateValue) {
          // console.log("translate a[key]",a[key]);
          // console.log("translate b[key]",b[key]);
          var varA = (typeof a[key] === 'string') ? this.translate.instant(a[key]) : a[key];
          var varB = (typeof b[key] === 'string') ? this.translate.instant(b[key]) : b[key];
        } else {
          var varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
          var varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
        }

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    }


}
