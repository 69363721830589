export class User {
  key: string;
  email: string;
  empresas: string[];
  estado: string;
  operador: string;
  perfil: string;
  settings: any;
  timeStamp: string;
}

