
<nav class="navbar navbar-light navbar-expand-md fixed-top">

    <a class="navbar-brand" href="">Nutral Mix &reg;</a>


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse">

        <ul class="navbar-nav mr-auto">
            
            <li class="nav-item " *ngIf='authService.esCliente || authService.esDistribuidor || authService.esAdministrador'>
                <a class="nav-link" routerLink="/clientesList">Clientes</a>
            </li>

            <li class="nav-item dropdown" *ngIf='authService.esCliente || authService.esDistribuidor || authService.esAdministrador '>
                <a class="nav-link dropdown-toggle" href="" id="PedidosDropdown" data-toggle="dropdown">Pedidos</a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="PedidosDropdown">
                    <a class="dropdown-item"  routerLink="/pedidos">Pedidos Procesados</a>
                    <a class="dropdown-item"  routerLink="/pedidoGestorListado">Pedidos WEB </a>
                </div>
            </li>

            <li class="nav-item dropdown" *ngIf='authService.esGestorPedidos'>
                <a class="nav-link dropdown-toggle" href="" id="PedidosDropdown" data-toggle="dropdown">Pedidos</a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="PedidosDropdown">
                    <a class="dropdown-item"  routerLink="/pedidoGestorListado"> Pedidos WEB </a>
                </div>
            </li>

<!-- Remitos  -->
            <li class="nav-item " *ngIf='authService.esCliente || authService.esDistribuidor || authService.esAdministrador '>
                <a class="nav-link" routerLink="/remitosTransportista">Remitos </a>
            </li>

<!-- Alertas de Reposición  -->

            <li class="nav-item "  *ngIf='authService.esCliente || authService.esDistribuidor || authService.esAdministrador '>
                <a class="nav-link" routerLink="/alertaReposicionControl" id="AlertasDropdown" >Alertas Reposición </a>

          </li>

          <li class="nav-item dropdown"  *ngIf='authService.esGestorPedidos'>
                <a class="nav-link dropdown-toggle" href="" id="AlertasDropdown" data-toggle="dropdown">Alertas Reposición </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="AlertasDropdown">
                    <a class="dropdown-item" routerLink="/alertaReposicionControl">Control de Alertas</a>
             </div>
          </li>




          <li class="nav-item dropdown" *ngIf='authService.esTransportista '>
               <a class="nav-link dropdown-toggle" href="" id="AlertasDropdown" data-toggle="dropdown">Remitos </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="AlertasDropdown">
                    <a class="dropdown-item" routerLink="/remitosTransportista">Remitos Transportista</a>
             </div>  

             
          </li>




            <!-- MOBILE MENU -->
            <li class="nav-item d-block d-md-none" *ngIf='empresa'>
                <a class="nav-link" routerLink="/settings">Configuración</a>
            </li>
            <li class="nav-item d-block d-md-none" *ngIf='authService.authenticated'>
                <a class="nav-link" (click)="logoutGoogle()">Salir</a>
            </li>
            <li class="nav-item d-block d-md-none" *ngIf='!authService.authenticated'>
                <a class="nav-link" routerLink="/logMail">Ingresar</a>
            </li>

           <li class="nav-item " *ngIf='authService.esAdministrador'>
                <a class="nav-link" routerLink="/usuariosList">Usuarios</a>
            </li>
            <li class="nav-item " *ngIf='authService.esAdministrador'>
                <a class="nav-link" routerLink="/solicitudEmpresaListado">Solicitudes</a>
            </li>
            <li class="nav-item " *ngIf='authService.esAdministrador'>
                <a class="nav-link" routerLink="/mapaMonitor">Monitor</a>
            </li>

            <li class="nav-item d-none d-md-block float-right" >
                
                <a class="nav-link icono flecha" ngbTooltip="Atrás"  (click)="backClicked()"></a>
            </li>
            <li class="nav-item" *ngIf='!authService.authenticated'>
                <a class="nav-link" routerLink="/logMail">Ingresar</a>
            </li>
            
            <li class="nav-item d-none d-md-block" *ngIf='authService.authenticated'>
                <div class="nav-link dropdown">
                    <a class="dropdown-toggle mi-perfil" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown"><img  *ngIf='authService.currentUserPhotoURL' src={{authService.currentUserPhotoURL}}>{{authService.currentUserDisplayName}}</a>


                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" *ngIf='empresa  || authService.esGestorPedidos || authService.esAdministrador' routerLink="/settings">Configuración</a>
                        
                        <a class="dropdown-item" *ngIf='authService.authenticated' (click)="logoutGoogle()">Salir</a>

                    </div>
                </div>
            </li>

        </ul>






            
        <!-- <div class="form-inline">
            <ul class="navbar-nav float-right">

                <li class="nav-item d-none d-md-block" >
                
                    <a class="nav-link icono flecha" ngbTooltip="Atrás"  (click)="backClicked()"></a>
                </li>
                <li class="nav-item" *ngIf='!authService.authenticated'>
                    <a class="nav-link" routerLink="/logMail">Ingresar</a>
                </li>
                
                <li class="nav-item d-none d-md-block" *ngIf='authService.authenticated'>
                    <div class="nav-link dropdown">
                        <a class="dropdown-toggle mi-perfil" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown"><img  *ngIf='authService.currentUserPhotoURL' src={{authService.currentUserPhotoURL}}>{{authService.currentUserDisplayName}}</a>


                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" *ngIf='empresa  || authService.esGestorPedidos || authService.esAdministrador' routerLink="/settings">Configuración</a>
                            
                            <a class="dropdown-item" *ngIf='authService.authenticated' (click)="logoutGoogle()">Salir</a>

                        </div>
                    </div>
                </li>
            </ul>
        </div>     -->
    </div>

</nav>
