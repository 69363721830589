import {Injector  } from '@angular/core';
import { Grilla }                       from '@maq-models/grilla/grilla.model';
// Servicios



import { ConfirmService }              from '@maq-servicios/confirm/confirm-service';
import { AlertService }                from '@maq-servicios/alert/alert-service';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
export class GrillaGenerica  {
   
  public alertService     : AlertService;

  public toastrService    : ToastrService;
  public confirmService   : ConfirmService;
  public mostrarDetalle   : boolean[]=[];


    public grilla               : Grilla;
    public pipePaginado         : any;
    public pagePaginado         : any;
    public cantidadFiltro       : any;

    public ordenCampo           : string ='';    // Se usa en el listado para ordenar por este campo
    public ordenReverse         : boolean =true;  // Determina el orden del filtro anterios

    public mostrarFormulario    : boolean =false;
    public filtro               :any[]=[]


  constructor(     ) {

    const injector = AppInjector.getInjector();
    if(injector==undefined){
      // return;
    } else{
    this.confirmService   = injector.get(ConfirmService);
    this.alertService     = injector.get(AlertService);
   
   }
                    let grilla_ = {
                      paginadoTipo          : 'local',    // local / servidor
                      // orderField            : 'fechaHoraInicioPlaneada',
                      // orderReverse          : true,
                      // orderServer           : ['key', 'fechaHoraInicioPlaneada'],
                      // whereArray            : argumentos['grillaWhereArray'],
                      // campoKeywords         : true,
                      // filtroNombre          : 'nombre',
                      // filtrosServer         : ['key', 'sesionKN.key', 'codigo','nombre','vehiculoPrincipalKN.key','integranteChoferKANE.key',
                      //                          'estadoRutaKN.key','fechaHoraInicioPlaneada','organizacionKNAI.key'],
                      // camposDecimal         : ['distanciaPlanificada','costoPlanificado'],
                      paginadoCantidad      : 20,
                      paginadoAutoHide      : false,
                      // verColumnaKey         : false,
                   }
                   this.grilla = new Grilla({});
                   this.setGrilla(grilla_);
                   }


  setGrilla(valores:any) {
  //   console.log("setGrilla",valores);

    let objValores = {};
    if(valores.paginadoTipo!==undefined)              objValores['paginadoTipo']              = valores.paginadoTipo;
    if(valores.orderField!==undefined)                objValores['orderField']                = valores.orderField;
    if(valores.orderReverse!==undefined)              objValores['orderReverse']              = valores.orderReverse;
    if(valores.orderServer!==undefined)               objValores['orderServer']               = valores.orderServer;
    if(valores.filtroNombre!==undefined)              objValores['filtroNombre']              = valores.filtroNombre;
    if(valores.filtrosServer!==undefined)             objValores['filtrosServer']             = valores.filtrosServer;
    if(valores.filtrosGrupos!==undefined)             objValores['filtrosGrupos']             = valores.filtrosGrupos;
    if(valores.filtrosInicial!==undefined)            objValores['filtrosInicial']            = valores.filtrosInicial;
    if(valores.campoKeywords!==undefined)             objValores['campoKeywords']             = valores.campoKeywords;      
    if(valores.paginadoCantidad!==undefined)          objValores['paginadoCantidad']          = valores.paginadoCantidad;
    if(valores.paginadoAutoHide!==undefined)          objValores['paginadoAutoHide']          = valores.paginadoAutoHide;
    if(valores.verColumnaKey!==undefined)             objValores['verColumnaKey']             = valores.verColumnaKey;
    if(valores.whereArray !==undefined)               objValores['whereOriginal']             = valores.whereArray;
    if(valores.whereArray !==undefined)               objValores['whereArray']                = valores.whereArray;
    if(valores.camposDecimal!==undefined)             objValores['camposDecimal']             = valores.camposDecimal;
    if(valores.cotizacionesActivas!==undefined)       objValores['cotizacionesActivas']       = valores.cotizacionesActivas;

    if(valores.paginadoTipo=='local') {
        objValores['paginaRequerida'] = 'todas';
        this.pipePaginado = {  
          paginate: { 
            itemsPerPage: 20, 
            currentPage: this.pagePaginado 
          }
        }
    } else {
        objValores['paginaRequerida'] = 'primera';
    }    
    
    //this.grilla = new Grilla(objValores);
    this.grilla.setValue('this.configComponente.nombreColeccion', objValores);

    // Inicializo Filtros
    for(let i=0; i<this.grilla.filtrosServer.length; i++) {
      let nombreFiltro=this.grilla.filtrosServer[i];
      this.grilla.filtros[nombreFiltro] = null;
    } 

}        
  onChangeGrilla(cual, value:any) {

  // Hizo click en alguna de las columnas para cambiar el orden
  if(cual=='order') {
      if(this.grilla.paginadoTipo=='servidor') { 
          if(this.grilla.orderServer.indexOf(value)!=-1) {  
              // Sólo ejecuto el ordenamiento en modo servidor
              // si la columana está incluída entre las disponibles para ordenar
              this.grilla.setOrder(value);
              this.grilla.paginaRequerida = 'primera';
        
          }    
      } else {
          this.grilla.setOrder(value);
      }           
  }

  // Hizo click en el dropDown de cantidad de elementos a mostrar en el paginado
  if(cual=='paginadoCantidad') {
      if(this.grilla.paginadoTipo=='servidor') { 
          this.grilla.paginaRequerida = 'primera';
        
      }  
  }  
  }  

  accion(data:any){
    console.log('accion',data);
    this.mostrarFormulario=false;
  }

  visualizarDetalle(i){
    if(this.mostrarDetalle[i]){
      this.mostrarDetalle[i]=false;  
    } else{
      this.mostrarDetalle[i]=true;
    }
  } 

  limpiarfiltros(){
    // this.filtro=[];
    console.log('limpiarFiltro',this.filtro);

    for ( const key in this.filtro) {
      console.log('limpiarFiltro key',key);
      const element = this.filtro[key];
      console.log('limpiarFiltro element',element);
        
      if (typeof element === 'string' || element instanceof String){
        this.filtro[key]='';
      }
      else if(typeof element === 'boolean' || element instanceof Boolean){}
        this.filtro[key]=null;
    }
    console.log('limpiarFiltro',this.filtro)
    this.onPostLimpiarFiltros();
  }

  onPostLimpiarFiltros(){

  }
 
  
  // Selecciona el campo y el orden con el que se ordena la tabla .
  setOrden(campo:string){
    this.ordenCampo=campo;
    this.ordenReverse=!this.ordenReverse;
  
  }
}




export class AppInjector {

  private static injector: Injector;

  static setInjector(injector: Injector) {
          AppInjector.injector = injector;
  }

  static getInjector(): Injector {
          return AppInjector.injector;
  }

}       
