import { Pipe, PipeTransform } from '@angular/core';

import { log, logIf, logTable, values } from '@maq-console';

@Pipe({
  name: 'searchFiltro',
  pure: false
})
export class SearchFiltroPipe implements PipeTransform {
  
  transform(value:any, paginadoTipo:string, fieldName:string, textSearch:any, type:string): Array<any> {
      //log(...values("valoresDebug","SearchFiltroPipe", value, paginadoTipo, fieldName, textSearch, type));

      if(paginadoTipo=='servidor') {
          return value;
      }

      let typeSearch:string;
      if(type==undefined) {
        typeSearch = "string";
      } else {
        typeSearch = type;
      }
      //console.log("searchFiltro typeSearch",typeSearch);
      let valorGrabado:any;
      let rta:boolean;
      let searchText = new RegExp(textSearch, 'ig');
      if (value) {
          return value.filter(valor => {
              if(fieldName.indexOf('.')==-1) {
                valorGrabado=valor[fieldName];
              } else {
                let partes = fieldName.split('.');
                if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                   (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ]) &&
                   (valor[ partes[0] ][ partes[1] ][ partes[2] ]!==undefined && valor[ partes[0] ][ partes[1] ][ partes[2] ]) ) {
                     
                   valorGrabado=valor[ partes[0] ][ partes[1] ][ partes[2] ];
                  
                } else if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                  (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ])  ) {
                    
                   valorGrabado=valor[ partes[0] ][ partes[1] ];
                } else {
                  valorGrabado=null;
                }
                //console.log("searchFiltro valor",valorGrabado, valor);
              }

              if(typeSearch=="array") {  // Filtro Array
                if(textSearch==null || textSearch==undefined) {
                  return true;
                } else {
                  //console.log("searchFiltro select1 array textSearch,valorGrabado",textSearch,valorGrabado);
                  rta=false;
                  if(valorGrabado==null)  rta=false;
                  if(valorGrabado!=null && valorGrabado.indexOf(textSearch)!=-1) {
                    rta=true;
                  } else {
                    rta=false;
                  }
                  return rta;
                }

              } else if(typeSearch=="array_obj") {  // Filtro Array de Objetos
                if(textSearch==null || textSearch==undefined || textSearch.key==null) {
                  return true;
                } else {
                  //console.log("pipe array textSearch,valorGrabado",textSearch,valorGrabado);
                  rta=false;
                  if(valorGrabado!=null && valorGrabado!=undefined) {
                    for(let i=0; i<valorGrabado.length; i++) {
                      //console.log("pipe array valorGrabado[i]==textSearch",valorGrabado[i],textSearch);
                      //console.log("pipe array valorGrabado[i].nombre==textSearch.nombre",valorGrabado[i].key,textSearch.key);
                      if(valorGrabado[i].key2!=undefined && valorGrabado[i].key2==textSearch.key2) {
                        rta=true; break;
                      } else if(valorGrabado[i].nombre!=undefined && valorGrabado[i].nombre==textSearch.nombre) {
                        rta=true; break;
                      }
                    }
                  }
                  //console.log("pipe array rta",rta);
                  return rta;
                }

              } else if(typeSearch=="boolean") {  // Filtro Booleano
                //console.log("boolean nombre",valor['datosPersonales']['nombre']);
                //console.log("boolean searchFiltro", value, fieldName, textSearch, type);
                if(textSearch==null || textSearch==undefined) {
                  //console.log("boolean true",true);
                  return true;
                } else {
                  //console.log("boolean textSearch,valorGrabado",textSearch,valorGrabado);
                  rta=false;
                  if(textSearch==false && valorGrabado==null)  rta=true;
                  if(textSearch==false && valorGrabado==false) rta=true;
                  if(textSearch==true  && valorGrabado)        rta=true;
                  //console.log("boolean rta",rta);
                  return rta;
                }

              } else if(typeSearch=="time") {  // Filtro Time
                //console.log("time searchFiltro", value, fieldName, textSearch, type);
                if(textSearch==null || textSearch==undefined) {
                  //console.log("boolean true",true);
                  return true;
                } else {
                  //console.log("boolean textSearch,valorGrabado",textSearch,valorGrabado);
                  rta=false;
                  if (valorGrabado) {
                     let hora=valorGrabado.hour.toString()+':'+valorGrabado.minute.toString()+':'+valorGrabado.second.toString();
                     //console.log("time searchFiltro hora", hora);
                     return hora.search(searchText) !== -1;
                  } else {
                     return true;
                  }
                }

              } else {  // Filtro String
                if(textSearch==null) return true;
                if(typeof valorGrabado!=='string' && valorGrabado) valorGrabado=valorGrabado.toString();
                if (valorGrabado) {
                  //console.log("valorGrabado.search(searchText) !== -1",valorGrabado, textSearch);
                  return valorGrabado.search(searchText) !== -1;
                } else {
                  return (textSearch) ? false : true;
                }
              }
          });
      }
  }
}
