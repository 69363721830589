import { Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';


@Pipe({
    name: 'empresaUsuarioPipe', 
    pure: false
})
export class EmpresaUsuarioPipe implements PipeTransform {

  transform(value, empresaFilter:string): Array<any> {
    //   console.log('EmpresaUsuarioPipe',value,empresaFilter);
      let searchText = new RegExp(empresaFilter, 'ig');
    //   console.log('EmpresaUsuarioPipe',empresaFilter,searchText);
    if(!empresaFilter){
        return value;
    }
    if (value) {
      return value.filter(usuario => {
        if (usuario.empresas ) {
                let encontroEmpresa= usuario.empresas.find(empresa=>empresa.RAZON_SOCI.search(searchText) !== -1);
                // console.log('EmpresaUsuarioPipe encontroEmpresa',encontroEmpresa);
                return  encontroEmpresa!=undefined;
        }
        else{
          return false;
        }
      });
    }
  }
}

@Pipe({
    name: 'empresaSeleccionUsuarioPipe', 
    pure: false
})
export class EmpresaSeleccionUsuarioPipe implements PipeTransform {

  transform(value:any, empresaFilter:string): boolean {
        // console.log('empresaSeleccionUsuarioPipe',value,empresaFilter);
    let searchText = new RegExp(empresaFilter, 'ig');
    let result:boolean=false;
    if (value && empresaFilter && value.RAZON_SOCI) {
  
        if (value.RAZON_SOCI.search(searchText) !== -1){
              result= true;
        }
        else{
            result=  false;
        }
      
    }
    return result;
  }
}


@Pipe({
    name: 'lengthPipe', 
    pure: false
})
export class LengthPipe implements PipeTransform {

  transform(value:any[]): number {
        // console.log('LengthPipe',value,empresaFilter);
   
    return value.length;    
   
  }
}