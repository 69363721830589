<div class="modal-content ">

    <div class="modal-header">
        <h2 class="modal-title">Seleccione las siguientes Opciones </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
           <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center"><div class="loading" *ngIf="showLoading"></div></div>
            <p>
                <!-- <strong *ngIf="mensaje.length>0 "> {{mensaje}}.</strong> -->

            </p>


        <form [formGroup]="formSettings">
 <!--                        <div class="form-group w-25">
                            <label for="ventanaVisualizacionPedidos">Ventana de visualización de pedidos (días):</label>
                            <input type="number" class="form-control" id="ventanaVisualizacionPedidos" placeholder="Ingrese la cantidad de días que quiere visualizar" value="30" formControlName="ventanaVisualizacionPedidos">
                        </div> -->
<!--                         <div *ngIf=" (formSettings.controls.ventanaVisualizacionPedidos.invalid && (formSettings.controls.ventanaVisualizacionPedidos.dirty|| formSettings.controls.ventanaVisualizacionPedidos.touched  ) )" class="alert alert-danger">
                            Ingrese un Numero valido
                        </div> -->

                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="aceptaDescargaSinRepresentante" formControlName="aceptaDescargaSinRepresentante" >
                            <label class="form-check-label" for="aceptaDescargaSinRepresentante">Acepta descargar el alimento sin la presencia de un representante del establecimiento? </label>
                        </div>
                        <!-- <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="aceptaNotificaciones" checked formControlName="aceptaNotificaciones" (click)="aceptaNotificaciones()">
                            <label class="form-check-label" for="aceptaNotificaciones">Notificaciones</label>
                        </div> -->
                        <br>
                        <!-- <button (click)="grabarSettings()" class="btn btn-primary">Grabar</button> -->
                    </form>

    </div>
    <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="grabarSettings()">Confirmar</button>
        <!-- <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button> -->
       
       <!--  <button type="button" class="btn btn-danger" (click)="modal.close({cause:'Ok click jj', date: model })">Ok</button> -->
    </div>
</div>
