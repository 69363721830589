<div class="component ">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

           
            <h2>Detalle Remito   </h2>
            <!-- <span class="etiqueta black" *ngIf="perfilUsuario">{{perfilUsuario.data.EmpresaSelected.RAZON_SOCI  }}</span> -->
            
            <!-- <span class="etiqueta black" *ngIf="empresaSelected">{{empresaSelected.RAZON_SOCI  }}</span> -->

        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">

               
                <div class="text-center"><div class="loading" *ngIf="showSpinner"></div></div>

                <div *ngIf="mensajeError" >{{mensajeError}}</div>

                <div class="accordion" id="accordionExample">

 


                    <div *ngFor="let remito of remitos">

                        <div class="top-inner-estructura clearfix" id="headingOne">
                            <h3 data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                               
                               <b>Remito </b> <span class=" textosNormalesEncabezado ">{{remito.N_REMITO}} </span>
                             
                               <span class="marginEnTitulo"> <b>Fecha: </b> <span class=" textosNormalesEncabezado ">{{remito.FECHA_REMITO}}</span>   </span>
                               <span class="marginEnTitulo textosNormalesEncabezado" *ngIf="remito.ACEPTA_ENTREGA_SIN_CLIENTE"> (Puede entregar sin presencia del cliente) </span>
                             <p>
                              <a href="javascript:;" ngbTooltip="Ver en el mapa" (click)="mostrarMapa(remito.ID_INFOTRAK)" *ngIf="remito.ID_INFOTRAK && !remito.ESTADO_ENTREGA_TRANSPORTISTA"> Mapa</a>
                            
                              
                            </p> 
                            </h3>
                            
                          

                             <span class="etiqueta" *ngIf="!remito.ESTADO_ENTREGA_TRANSPORTISTA "><i class="icono ic-truck-2"></i><b> En Transporte</b></span>
                              
                             
                              <span class="etiqueta check" *ngIf="remito.ESTADO_ENTREGA_TRANSPORTISTA ">Entregado</span>
                            
                               
                                                
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="table-body">
                                <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y">
                                    <thead>
                                        <tr>
                                            <th>
                                                PRODUCTO
                                            </th>
                                            <th>
                                                CANTIDAD PEDIDO
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let detalle of remito.detalle">
                                            <td (click)="setPedidoSelected(pedido)" >
                                            {{detalle.DESCRIPCIO}}
                                            </td>
                                            <td (click)="setPedidoSelected(pedido)" >
                                             {{detalle.CANTIDAD_REMITO}} {{detalle.ID_MEDIDA_STOCK}}
                                            </td>
                                            
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>

