// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  
  production: false,
  //   firebase : {
  //    apiKey: "AIzaSyDRhEgNleV2oXekeIxHd9fCfAwtlefAcEk",
  //   authDomain: "nutraltest.firebaseapp.com",
  //   databaseURL: "https://nutraltest.firebaseio.com",
  //   projectId: "nutraltest",
  //   storageBucket: "nutraltest.appspot.com",
  //   messagingSenderId: "574418147348",
  //   appId: "1:574418147348:web:89775890505a3f01"
  // },
  //  nutralMix:{
  //   //  SQL_SERVER:'https://us-central1-nutraltest.cloudfunctions.net/'  //Desarrollo
  //    SQL_SERVER:'https://us-central1-nutralmix-77b0e.cloudfunctions.net/'  //Produccion
  //  },


  
   firebase : {
   apiKey: "AIzaSyBf9B91GUBlLnKtBAL9MhXeGFnqM1LsFDo",
   authDomain: "nutralmix-77b0e.firebaseapp.com",
   databaseURL: "https://nutralmix-77b0e.firebaseio.com",
   projectId: "nutralmix-77b0e",
   storageBucket: "nutralmix-77b0e.appspot.com",
   messagingSenderId: "233035898221",
   appId: "1:233035898221:web:6813c409cf1cb2f0"
 },
  nutralMix:{
    // SQL_SERVER:'https://us-central1-nutraltest.cloudfunctions.net/'  //Desarrollo
    SQL_SERVER:'https://us-central1-nutralmix-77b0e.cloudfunctions.net/'  //Produccion
  },
   
   enConstruccion        : false,
   consoleLog            : { header:true, funciones: true, variables:true },  
   cliente               : 'planifik',
   tipoServidor: {  
       hosting           : 'firebase',       // firebase  o externo
       login             : 'firebase',       // firebase  o sql
       baseDatos         : 'firestore',      // firestore o sql
       storage           : 'firebase',       // firebase  o externo
   },  
   
   cloudFunction: {
     url: 'https://us-central1-testcpsistemas.cloudfunctions.net/'
   },  
   serviciosExternos: {
     hosting: {
         documentoRoot   : 'planifi-k/www',
     },
     sql: {
         apiURL          : 'https://www.lincolnonline.com.ar/ionic/',           //Desarrollo
     },
     storage: {
         serverURL       : 'https://www.lincolnonline.com.ar/archivos/',        //Desarrollo
         user            : '',
         password        : '',
     },  
   },
   apis: {
     reCAPTCHA: {
       version       : 'v2',            
       claveSitioWeb : null,
       claveSecreta  : null
     },        
     googleMaps: { 
        key              : 'AIzaSyC3kGKOR7hsNhR6JjZFMbZnj8tmYcX5klI'
     },
     hereMaps: {
       apiKey      : 'mZeC5JXRMPDpsq7EbaM4ZZEgluoM-I4W8bPrET2LY9Q',  // Santiago
       app_id      : '4nYLZClXQgWpOEMKScrE',  // Javier
       app_code    : 'H-zbneNbZ5LfWj8CAjGjHw' // Javier
     },
     facebook: {
        app_id     : '',   // APP ID de la Fun Page de Facebook
     }
   }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
