import { Injectable } from '@angular/core';
import { AngularFireDatabaseModule, AngularFireDatabase  } from '@angular/fire/database';
// import {  FirebaseListObservable } from '@angular/fire/database-deprecated';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from "@angular/router";
import * as firebase from 'firebase';
import { AuthService } from '../../../services/firebase/auth.service';

import { AngularFirestore, AngularFirestoreDocument ,AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
// import { User } from '../../admin/users/user';

import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  refAlertasReposicion = firebase.firestore().collection('Alertas-reposicion');
  constructor(   private  af: AngularFirestore) {

  }







/// Firebase Server Timestamp
get timestamp() {
  return firebase.firestore.FieldValue.serverTimestamp;

}




timeStampToDDMMAAAA(fechaMiliseconds:number):string{
  let day= new Date(fechaMiliseconds*1000);
  // console.log('timeStampToDDMMAAAA fechaMiliseconds',fechaMiliseconds);
  // console.log('timeStampToDDMMAAAA day',day);
  // console.log('timeStampToDDMMAAAA day',day.getDate()+'/'+day.getMonth()+'/'+day.getFullYear());
 

  return day.getDate()+'/'+(day.getMonth()+1)+'/'+day.getFullYear();
}


crearAlerta(perfilUsuario: any, empresaSelected: any, alerta: any   ): Observable<any> {
  console.log('crearAlerta');
  console.log('crearAlerta perfilUsuario: ', perfilUsuario);
  console.log('crearAlerta codigoEmpresa',empresaSelected);
  console.log('crearAlerta alerta',alerta );
  return new Observable((observer) => {
    const COD_CLIENT=empresaSelected.COD_CLIENT;

    const refAlerta  =this.refAlertasReposicion.doc(COD_CLIENT);
    var transaction = firebase.firestore().runTransaction((t) => {



  let documento={COD_CLIENT:empresaSelected.COD_CLIENT,
                 empresa:empresaSelected,
                 };



      return  t.get(refAlerta).then(doc => {

        console.log('doc',doc);
          alerta['estado']= 'activo';
          alerta['timeStamp'] = new Date();

          if (!doc.data()) {
                const listaAlertas=[alerta];
                documento['listaAlertas']=listaAlertas;
                t.set(refAlerta,documento);
            } else {
                const listaAlertas = doc.data().listaAlertas;
                listaAlertas.push(alerta);
                t.update(refAlerta, { listaAlertas: listaAlertas });
            }

        return  Promise.resolve('listaAlertas increased to ' );

          }).catch(err => {
        console.log(' failure doc:', err);
        return  Promise.reject('NroPedido err to ' + err);

      });

      }).then(result => {
        console.log('crearAlerta success! ',result);
        observer.next(result);
      }).catch(err => {
        console.log('crearAlerta failure: ', err);
        observer.error(err);
      });
   });
 };



modificarAlerta(perfilUsuario: any, codCliente: any, alerta: any ,idAlerta:number   ):  Observable<any> {
  console.log('modificarAlerta');
  console.log('modificarAlerta perfilUsuario: ', perfilUsuario);
  console.log('modificarAlerta codigoEmpresa',codCliente);
  console.log('modificarAlerta alerta',alerta );
  console.log('modificarAlerta idAlerta',idAlerta );
  return new Observable((observer) => {
    const refAlerta  =this.refAlertasReposicion.doc(codCliente);
    var transaction = firebase.firestore().runTransaction((t) => {
      return  t.get(refAlerta)
      
      .then(doc => {
        console.log('doc',doc);
        console.log(' doc.data()', doc.data());
        console.log(' doc.alerta',alerta);
        alerta['estado']= 'activo';
        alerta['timeStamp'] = new Date();
         
        if (!doc.data()) {
          //Error no hago nadao
        } else {
          const listaAlertas = doc.data().listaAlertas;
          listaAlertas[idAlerta]=alerta;
          t.update(refAlerta, { listaAlertas: listaAlertas });
        }
        return  Promise.resolve('listaAlertas increased to ' );
        }).catch(err => {
          console.log(' failure doc:', err);
          return  Promise.reject('NroPedido err to ' + err);
        });

    }).then(result => {
      console.log('crearAlerta success! ',result);
        observer.next(result);
      }).catch(err => {
    console.log('crearAlerta failure: ', err);
     observer.error(err);
   });
   });
};



borrarAlerta( codEmpresa: any, alerta: any ,idAlerta:number   ):  Observable<any> {
  console.log('modificarAlerta');
  console.log('modificarAlerta codigoEmpresa',codEmpresa);
  console.log('modificarAlerta alerta',alerta );
  return new Observable((observer) => {
    // const COD_CLIENT=empresaSelected.COD_CLIENT;

    const refAlerta  =this.refAlertasReposicion.doc(codEmpresa);
    var transaction = firebase.firestore().runTransaction((t) => {
    return  t.get(refAlerta)
      .then(doc => {
        console.log('doc',doc.data());
        alerta['estado']= 'cancelada';
        alerta['timeStamp'] = new Date();

        if (!doc.data()) {
          //Error no hago nadao
        } else {
          const listaAlertas = doc.data().listaAlertas;
          listaAlertas.splice(idAlerta,1);[]=alerta;
          t.update(refAlerta, { listaAlertas: listaAlertas });
        }

        return  Promise.resolve('listaAlertas increased to ' );
      }).catch(err => {
        console.log(' failure doc:', err);
        return  Promise.reject('NroPedido err to ' + err);
      });
    }).then(result => {
        console.log('crearAlerta success! ',result);
        observer.next(result);
      }).catch(err => {
        console.log('crearAlerta failure: ', err);
        observer.error(err);
      });
    });
};



/// Retorna las alertas seguon el codigo de cliente
getAlertasCliente( codigoEmpresa: string  ): Observable<AlertaListado[]> {
  console.log('getAlertasCliente');

  return new Observable((observer) => {
    let listaAlertas : AlertaListado[]= [];
    this.refAlertasReposicion.doc(codigoEmpresa).get()
      .then(doc => {
        console.log("getAlertasCliente doc",doc);
        if(doc.exists){
          let data =  doc.data();
          
          data.listaAlertas.forEach((alerta:Alerta,index) => {
            console.log("getAlertasCliente alerta",alerta);
            let alertaListado:AlertaListado= this.ajustoDatosAlerta(data.empresa,alerta,index);
            listaAlertas.push(alertaListado);
          });
        }
        console.log('getAlertasCliente',listaAlertas);
        observer.next(listaAlertas);
    });
  });
}

/// Retorna las alertas seguon el codigo de cliente
getAlertasBorrar( codigoEmpresa: string  ): Observable<any> {
  console.log('getAlertasCliente');

  return new Observable((observer) => {
      let listaAlertas = [];
       this.refAlertasReposicion.doc(codigoEmpresa).get()
          .then(doc => {
          console.log("getAlertasCliente doc",doc);
          if(doc.exists){
          let data = doc.data();
          data.listaAlertas.forEach((alerta,index) => {
           
                    

          listaAlertas.push(alerta);
         });
        }

      console.log('getAlertasCliente',listaAlertas);
      observer.next(listaAlertas);
    });
  });
}


/// Retorna Todas alertas 
getTodasLasAlertas(): Observable<any> {
  console.log('getAlertasCliente');

  return new Observable((observer) => {


      let ref:any=this.refAlertasReposicion;

      ref.onSnapshot((querySnapshot) => {
      let listaDeAlertas = [];
      console.log("getTodasLasAlertas querySnapshot",querySnapshot);
      querySnapshot.forEach((doc) => {
        let data:Alertas = doc.data();
        console.log("getTodasLasAlertas",doc);
        console.log("getTodasLasAlertas data",data);
        console.log("getTodasLasAlertas doc.id",doc.id);


        let empresa:Empresa=data.empresa;
        for (var i = 0; i < data.listaAlertas.length; i++) {

          let alerta:Alerta =data.listaAlertas[i];
          console.log("alerta",alerta);
          let alertaListado:AlertaListado= this.ajustoDatosAlerta(empresa,alerta,i)
          listaDeAlertas.push(alertaListado);  
        }
 
      });
      console.log('getTodasLasAlertas',listaDeAlertas);
      observer.next(listaDeAlertas);
    })
    
  });
}

ajustoDatosAlerta(empresa:Empresa,alert:Alerta,alertaIndex:number):AlertaListado{
  console.log("diaActuliazacionCarga empresa", empresa);
  console.log("diaActuliazacionCarga alert", alert);
  console.log("diaActuliazacionCarga alertaIndex", alertaIndex);

  let diaActuliazacionCarga:DiaMesAno =alert.diaActuliazacionCarga;
  // let diaActuliazacionCarga:DiaMesAno ={dia:4,mes:5,ano:2021};
  console.log("diaActuliazacionCarga ", diaActuliazacionCarga);
  var d = new Date("02/07/2011"); // "mm/dd/yyyy"  
  var fechacalculo = new Date(diaActuliazacionCarga.mes+'/'+diaActuliazacionCarga.dia+'/'+diaActuliazacionCarga.ano).getTime();
  var hoy = new Date().getTime();
  var diff = Math.trunc((hoy - fechacalculo)/86400000);//1000*60*60*24
  console.log('diff: ',diff );
  let stokConsumido=(alert.cantidadDeAnimales*alert.consumoDiario*diff) ;
  console.log('diff stokConsumido: ',stokConsumido);
  let stockDisponible= alert.stockDespuesUltimaCarga-stokConsumido;
  if(stockDisponible<0){
    stockDisponible=0;
  }
  console.log('stockDisponible: ',stockDisponible );
  let diasDeAlimentoDisponible=   (stockDisponible/(alert.cantidadDeAnimales*alert.consumoDiario));  
  let capacidadAlamcenamientoDisponible=alert.capacidadDeAlmacenamiento - stockDisponible;
  let consumoDiarioTotal=alert.cantidadDeAnimales*alert.consumoDiario
  let alertaListado:AlertaListado={empresa:empresa,        
            alerta: alert,
            articulo:alert.articulo,
            diaActulizacionCargaTxt:diaActuliazacionCarga.dia+'/'+diaActuliazacionCarga.mes+'/'+diaActuliazacionCarga.ano,
            stockDisponible:stockDisponible,
            consumoDiarioTotal:consumoDiarioTotal,
            capacidadAlamcenamientoDisponible:capacidadAlamcenamientoDisponible,
            diasDeAlimentoDisponible: Math.trunc(diasDeAlimentoDisponible),
            alertaIndex:alertaIndex};
  return(alertaListado);  
}



actualizarStocksEnAlerta(COD_CLIENT: string, listaAlerta: Alerta[] ):  Observable<any> {
    console.log('actualizarStocksEnAlerta');
    console.log('actualizarStocksEnAlerta COD_CLIENT',COD_CLIENT);
    console.log('actualizarStocksEnAlerta listaAlerta', listaAlerta);
 
    return new Observable((observer) => {


     const refAlerta  =this.refAlertasReposicion.doc(COD_CLIENT);
        
        let doc={};
        doc['listaAlertas']=listaAlerta;
        

        console.log('actualizarStocksEnAlerta doc', doc);

        refAlerta.update(doc).then(result => {
            console.log('Transaction success! ',result);
            observer.next(result);
          }).catch(err => {
            console.log('Transaction failure: ', err);
           observer.error(err);
          });
        });
  }

 // updateToken(userKey: string, token: string  ) {
 //    // we can change this function to request our backend service

 //        const data = {};
 //        data[userKey] = token;

 //      const batch = firebase.firestore().batch();
 //           // this.reffcmTokens.doc(userKey+"/"+codigoEmpresa+"/"+codigoProducto)
 //    this.reffcmTokens.doc(userKey)
 //    .set(
 //      {
 //        token:token,
 //        // DESC_ADIC:descripcionAdicional,
 //        // ID_MEDIDA_STOCK:unidadMedStock
 //       }).then((Response) => {
 //      console.log('updateToken Response ',Response);
 //      // observer.next();
 //    }).catch((error)=>
 //    {console.log("updateToken",error);}
 //    );



 //  }


 
 
}


