<div class="component usuario-noautorizado">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>No autorizado</h2>
            <!-- <span class="etiqueta black" *ngIf="perfilUsuario">{{perfilUsuario.data.EmpresaSelected.RAZON_SOCI  }}</span> -->
        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">

                <div class="top-inner-estructura clearfix" id="headingOne">
                    <h2 ><b>No tiene acceso a esta funcionalidad</b></h2>
                   

                </div>


            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
