<div class="component pedidos" *ngIf="editar==false">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura" *ngIf="perfilUsuario">

           <h2  >Pedidos Web <span *ngIf="listadoPrincipal">({{listadoPrincipal?.length}})</span></h2>
        
           <span class="etiqueta black" *ngIf="empresaSelected && perfilUsuario.data.perfil== 'cliente' || perfilUsuario.data.perfil== 'distribuidor'"
           >{{empresaSelected.RAZON_SOCI  }}</span>




            <div class="FormularioEnCabecera">
                

             <form class="form-row"  [formGroup]="pedidoForm" >
                            <!-- Fecha de desde -->
                            <div class="form-group col-md-8 row" >
                                <div class=" col-md-4 " >
                                    <label>Fecha Desde</label>  
                                    <div class="input-group">
                                       
                                      <input class="form-control calendar" placeholder="dd-mm-yyyy" id="fechaInicio"
                                             name="dp"  ngbDatepicker #d="ngbDatepicker" formControlName="fechaInicio" placement="bottom-left"
                                             [minDate]="minDate"
                                             [maxDate]="maxDate">
                                        <div class="input-group-append" (click)="d.toggle()" ngbTooltip="Fecha Desde">
                                       
    
                                          <div class="input-group-text" ><span class="ic-calendario"></span></div>

                                        </div>
                                      
                                    </div>

                                    <div *ngIf="errorFechaInicio "  class="alerta alerta-3 "  >
                                                {{errorFechaInicio}}
                                     </div>
                                    
                                </div>
                       

                             <!-- Fecha de hasta -->
                           
                                <div class=" col-md-4  " >
                                    <label>Fecha Hasta</label>  
                                    <div class="input-group">
                                       
                                      <input class="form-control calendar" placeholder="dd-mm-yyyy" id="fechaHasta"
                                             name="dp"  ngbDatepicker #d1="ngbDatepicker" formControlName="fechaHasta" placement="bottom-left"
                                             [maxDate]="maxDate">
                                        <div class="input-group-append" (click)="d1.toggle()" ngbTooltip="Fecha Fin">
                                       
    
                                          <div class="input-group-text" ><span class="ic-calendario"></span></div>

                                        </div>
                                      
                                    </div>

                                    <div *ngIf="errorFechaHasta " class="alerta alerta-3"  >
                                                {{errorFechaHasta}}
                                     </div>
                                    
                                </div>
                            
                             <div class=" col-md-4  " >
                                

                            </div>
                        </div>
                        
              </form>  

               
                
            </div>


        </div>
      
    
        <div class="row inner-estructura">

            
           
             <div class="col-sm-12">
                <div class="text-center"><div class="loading" *ngIf="showSpinner"></div></div>
                <div *ngIf="mensajeError" >{{mensajeError}}</div>

                <!-- inidio listado -->
                <!-- inicio listado -->
                


                <table  class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt" 
                    style=" position:relative; margin:auto;   width:100%;">
                    <thead>
                         <tr>
                            <th (click)="setOrden('NRO_PEDIDO')">
                                
                                <span class="" *ngIf="ordenCampo!='NRO_PEDIDO'">Nro Pedido</span>
                                <span class="text-primary" *ngIf="ordenCampo=='NRO_PEDIDO'">Nro Pedido</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='NRO_PEDIDO' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='NRO_PEDIDO' && !ordenReverse" ></i>
                            </th>

                            
                            <th (click)="setOrden('COD_CLIENT')">

                                <span class="" *ngIf="ordenCampo!='COD_CLIENT'">C.Cliente</span>
                                <span class="text-primary" *ngIf="ordenCampo=='COD_CLIENT'">C.Cliente</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='COD_CLIENT' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='COD_CLIENT' && !ordenReverse" ></i>

                                
                            </th>
                            <th (click)="setOrden('RAZON_SOCI')">

                                <span class="" *ngIf="ordenCampo!='RAZON_SOCI'">RAZÓN SOCIAL</span>
                                <span class="text-primary" *ngIf="ordenCampo=='RAZON_SOCI'">RAZÓN SOCIAL</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='RAZON_SOCI' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='RAZON_SOCI' && !ordenReverse" ></i>
                            </th>

                                                    
                            <th (click)="setOrden('cabecera.timeStamp.seconds')">
                                
                                <span class="" *ngIf="ordenCampo!='cabecera.timeStamp.seconds'">F. Pedido</span>
                                <span class="text-primary" *ngIf="ordenCampo=='cabecera.timeStamp.seconds'">F. Pedido</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='cabecera.timeStamp.seconds' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='cabecera.timeStamp.seconds' && !ordenReverse" ></i>
                            </th>

                            <th (click)="setOrden('fechaEntregaConcat')">
                                
                                <span class="" *ngIf="ordenCampo!='fechaEntregaConcat'">F. Entrega</span>
                                <span class="text-primary" *ngIf="ordenCampo=='fechaEntregaConcat'">F. Entrega</span>
                                <span class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='fechaEntregaConcat' && ordenReverse" ></span>
                                <span class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='fechaEntregaConcat' && !ordenReverse" ></span>
                            </th>

                            <th (click)="setOrden('cabecera.comentario')"  style="width: 165px;" >

                                <span class="" *ngIf="ordenCampo!='cabecera.comentario'">Comentario</span>
                                <span class="text-primary" *ngIf="ordenCampo=='cabecera.comentario'">Comentario</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='cabecera.comentario' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='cabecera.comentario' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('dataProcesamiento.refTango')"  style="width: 165px;" >

                                <span class="" *ngIf="ordenCampo!='dataProcesamiento.refTango'">Ref Tango</span>
                                <span class="text-primary" *ngIf="ordenCampo=='dataProcesamiento.refTango'">Ref Tango</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='dataProcesamiento.refTango' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='dataProcesamiento.refTango' && !ordenReverse" ></i>
                            </th>

                            <th (click)="setOrden('estado')"  style="width: 165px;" >

                                <span class="" *ngIf="ordenCampo!='estado'">Estado</span>
                                <span class="text-primary" *ngIf="ordenCampo=='estado'">Estado</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='estado' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='estado' && !ordenReverse" ></i>
                            </th>

                            <th  style="width: 265px;" >

                                <span class=""  >Acciones</span>
                                
                            </th>

                        </tr>

                        <tr>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="NRO_PEDIDO"  
                                        [(ngModel)]="filtro['NRO_PEDIDO']" 
                                        placeholder="Remito"/>
                            </th>
      
                            <th>
                                <input  class="form-control" 
                                        type="text" id="cabecera.empresa.COD_CLIENT"  
                                        [(ngModel)]="filtro['cabecera.empresa.COD_CLIENT']" 
                                        placeholder="Codigo Cliente"/>
                            </th>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="cabecera.empresa.RAZON_SOCI"  
                                        [(ngModel)]="filtro['cabecera.empresa.RAZON_SOCI']" 
                                        placeholder="Razón Social"/>
                            </th>
                            
                            <th>
                                <input  class="form-control" 
                                        type="text" id="fechaPedidoConcat"  
                                        [(ngModel)]="filtro['fechaPedidoConcat']" 
                                        placeholder="F Pedido"/>
                            </th>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="fechaEntregaConcat"  
                                        [(ngModel)]="filtro['fechaEntregaConcat']" 
                                        placeholder="F Entrega"/>
                            </th>

                            <th>
                                <input  class="form-control" 
                                        type="text" id="cabecera.comentario"  
                                        [(ngModel)]="filtro['cabecera.comentario']" 
                                        placeholder="Comentario"/>
                            </th>
                            <th>
                                <input  class="form-control" 
                                        type="text" id="dataProcesamiento.refTango"  
                                        [(ngModel)]="filtro['dataProcesamiento.refTango']" 
                                        placeholder="Ref Tamgo"/>
                            </th>
                        
                            <th>
                                
                                <select class="form-control selectpicker" id="ESTADO_FILTRO"
                                        [(ngModel)]="filtro['estado']"
                                        (change)="getPedidosGestor()" >
                                    <option  [ngValue]=null>Estado</option>
                                    <option  [ngValue]="'creado'">Creado</option>
                                    <option  [ngValue]="'procesado'">Procesado</option>
                                    <option  [ngValue]="'cancelado'">Cancelado</option>
                                </select>

                            </th>

                            <th  class="text-center" >
                                <span class="mdi mdi-24px mdi-broom"  title="Limpiar filtros" (click)="limpiarfiltros()"  ></span>
                                <span class="text-primary ml-1 mdi mdi-24px mdi-plus" title="Nuevo Pedido Web" (click)="nuevoPedido()"   ></span>
                            </th>

                            
                        </tr>
                    </thead>

                     <tbody class="accordion" id="accordionTable">  
                        <tr *ngIf="listadoPrincipal?.length==0" >
                             <td colspan="9" >No hay datos para los filtros seleccionados.</td>
                        </tr>      
                        
                        <ng-container *ngFor="let documento of listadoPrincipal
                                    | orderBy :ordenCampo :ordenReverse :'case-insensitive'  
                              
                                    | searchFiltro:'local':'NRO_PEDIDO'                 :filtro['NRO_PEDIDO'] 
                                    | searchFiltro:'local':'cabecera.empresa.COD_CLIENT':filtro['cabecera.empresa.COD_CLIENT'] 
                                    | searchFiltro:'local':'cabecera.empresa.RAZON_SOCI':filtro['cabecera.empresa.RAZON_SOCI'] 
                                    | searchFiltro:'local':'dataProcesamiento.refTango' :filtro['dataProcesamiento.refTango'] 
                                    | searchFiltro:'local':'fechaPedidoConcat'           :filtro['fechaPedidoConcat'] 
                                    | searchFiltro:'local':'fechaEntregaConcat'          :filtro['fechaEntregaConcat'] 
                                    | searchFiltro:'local':'cabecera.comentario'         :filtro['cabecera.comentario'] 


                                    | searchFiltro:'local':'estado':filtro['estado'] 
                             
                                    | paginate: { itemsPerPage: grilla.paginadoCantidad, 
                                        currentPage : pagePaginado,
                                        totalItems  : cantidadFiltro}; let cantidadFiltro=count ;
                                        
                                    let i=index    " >
                            
                                <tr [ngClass]="{'filaSeleccionada': mostrarDetalle[documento.NRO_PEDIDO]}" >

                                   
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.NRO_PEDIDO}}
                                    </td>
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.cabecera?.empresa?.COD_CLIENT}}
                                    </td>
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.cabecera?.empresa?.RAZON_SOCI}}
                                    </td>
                                    
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.fechaPedidoConcat}}
                                        <!-- {{ documento?.cabecera?.timeStamp?.seconds * 1000 | date:'dd-MM-yyyy' }} -->
                                    </td>
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.fechaEntregaConcat}}
                                        <!-- {{documento?.cabecera?.fechaEntrega?.day}}/{{documento?.cabecera?.fechaEntrega?.month}}/{{documento?.cabecera?.fechaEntrega?.year}} -->
                                    </td>
                                    
                                     <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.cabecera?.comentario}}
                                    </td>
                                     <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.dataProcesamiento?.refTango}}
                                    </td>
                                    <td (click)="visualizarDetalle(documento.NRO_PEDIDO)">
                                        {{documento?.estado}}
                                    </td>
                                    <td>
                                        <span *ngIf="(perfilUsuario.data.perfil == 'cliente'       && documento.estado=='creado') || 
                                                  (perfilUsuario.data.perfil == 'administrador' && documento.estado=='creado') && !documento?.dataProcesamiento?.refTango"
                                                  class="text-primary  mdi mdi-24px mdi-pencil-outline"
                                                  (click)="editarPedido(documento)"  ngbTooltip="Modificar"></span>

                                        <span *ngIf="(perfilUsuario.data.perfil == 'cliente' && documento.estado=='creado') || 
                                                  (perfilUsuario.data.perfil == 'administrador' && documento.estado=='creado') && !documento?.dataProcesamiento?.refTango" 
                                                  class=' text-danger mdi mdi-24px  mdi-delete-outline'   
                                                  (click)="cancelarPedido(documento)" 
                                                  ngbTooltip="Borrar"></span>
              
                                                 
              
                                        <span *ngIf="(perfilUsuario.data.perfil == 'gestorPedidos' || perfilUsuario.data.perfil== 'administrador')  && !documento?.dataProcesamiento?.refTango && documento.estado=='creado' "
                                                class="text-warning mdi mdi-24px mdi-factory"
                                                (click)="ProcesarPedidoModal(documento)"  
                                                ngbTooltip="Procesar en Tango"></span>
                                                             
                              </tr>
                                <tr *ngIf="mostrarDetalle[documento.NRO_PEDIDO]">
                                    <td colspan="9"  style="background-color: white;">
                                    
                                        <div class="tarjetaDetalle">
                                    
                                        
                                            <div class="row tarjetaTitulo ">
                                                <div class="col-1"></div>
                                                <div class="col-4" style="font: bold;">ARTICULO</div>
                                                <div class="col-3" style="font: bold;">PRODUCTO</div>
                                                <div class="col-4" style="font: bold;">CANTIDAD</div>
                                              
                                            </div>

                                            <div class="row tarjetaDetalleRow " *ngFor="let detalle of documento.detalle">
                                                <div class="col-1"></div>
                                                <div class="col-4">{{detalle?.articulo?.COD_ARTICU}}</div>
                                                <div class="col-3">{{detalle?.articulo?.DESCRIPCIO}}</div>
                                                <div class="col-4">{{detalle?.cantidad}} {{detalle?.unidad}}</div>
                                            </div>
                                        </div>   
                            
                                    </td>                            
                                </tr>
                            </ng-container>    
                        </tbody>
                    </table>


                    <!-- nuevo Paginado -->
                    <div class="row" style="border-top:1px solid #ccc;" *ngIf="listadoPrincipal?.length>0">
                        <div class="col-3" style="padding:10px;">
                            <div class="bg-white text-center">
                                <select class="form-control selectpicker" 
                                        id="grilla.paginadoCantidad"                                         
                                        [(ngModel)]="grilla.paginadoCantidad"
                                        (change)="onChangeGrilla('paginadoCantidad',grilla.paginadoCantidad)"> 
                                    <ng-container *ngFor="let opcion of grilla.paginadoDropDown">
                                        <option [ngValue]="opcion">Items x Página {{opcion}}</option>
                                    </ng-container>
                                </select>
                            </div>    
                        </div>    
                        <div class="col-9" style="padding:10px;">
                            <div class="bg-white text-center">

                    
                                    <pagination-controls 
                                    autoHide="true" 
                                    previousLabel="{{'anterior' }}" 
                                    nextLabel="{{'siguiente'}}" 
                                    (pageChange)="pagePaginado = $event" 
                                    maxSize="15"></pagination-controls>

                                </div>
                            </div>                        
                        </div>
                
             
             
             
             
             <!-- fin listado -->
             <!-- fin listado -->

                             
            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
<app-pedido-creado-detalle *ngIf="editar==true" (setEditar)="setEditar($event)">

</app-pedido-creado-detalle>