<div class="component clientes">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Clientes</h2>

            <!-- <span class="etiqueta black">pepe@gmail.com</span> -->
            <span class="etiqueta black" *ngIf="empresaSelected">{{empresaSelected.RAZON_SOCI  }}</span>
        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">

                <div class="spinner-grow text-primary"></div>
                <div *ngIf="mensajeError" >{{mensajeError}}</div>

                <div *ngIf="perfilUsuario &&  perfilUsuario.data &&  perfilUsuario.data.empresas.length>0">


                    <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y table-hover highlight-opt">
                        <thead>
                            <tr>
                                <th>
                                    CÓDIGO
                                </th>
                                <th>
                                    RAZÓN SOCIAL
                                </th>
                                <th>
                                    LOCALIDAD
                                </th>
                                <th>
                                    CUIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let empresa of perfilUsuario.data.empresas" (click)="setEmpresaSelected(empresa)">
                                <td>
                                    {{empresa.COD_CLIENT}}
                                </td >
                                <td>
                                    {{empresa.RAZON_SOCI}}
                                </td>
                                <td>
                                    {{empresa.LOCALIDAD}}
                                </td>
                                <td>
                                    {{empresa.CUIT}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
