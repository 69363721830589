<div class="component pedidos">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Pedido Web</h2>
            <!-- <span class="etiqueta black" *ngIf="perfilUsuario && !pedidoParaEditar">{{perfilUsuario.data.EmpresaSelected.RAZON_SOCI  }}</span>
            <span class="etiqueta black" *ngIf="pedidoParaEditar">{{pedidoParaEditar?.cabecera?.empresa?.RAZON_SOCI  }}</span> -->

           
            <select class="form-control selectpicker float-right selectEmpresa" id="empresaSelected"
                    (change)="onChangeEmpresaSelected()"
                    [(ngModel)]="empresaSelected" 
                    [compareWith]="compareEmpresas">
                <option  [ngValue]=''>Empresa</option>
                <option  *ngFor="let empresa of perfilUsuario?.data.empresas" [ngValue]="empresa" >{{empresa?.RAZON_SOCI}}</option>
            </select>

        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">
               
                <div class="top-inner-estructura clearfix" id="headingOne">
                    <h3 *ngIf="!pedidoParaEditar"><b>Pedido: Nuevo</b></h3>
                    <h3 *ngIf="pedidoParaEditar"><b>Pedido: {{pedidoParaEditar?.NRO_PEDIDO}}</b></h3>
                </div>

                <div class="table-body">


                    <form class="form-row" *ngIf="listaDeProductos" [formGroup]="pedidoForm" >
                            <!-- Fecha de entrega -->
                            <div class="form-group col-md-12 ">
                                <div class=" col-md-3 cal" style="padding-left: 0px !important;">
                                    <label>Fecha Entrega</label>  
                                    <div class="input-group">
                                       
                                      <input class="form-control calendar" placeholder="dd-mm-yyyy" id="fechadeEntrega"
                                             name="dp"  ngbDatepicker #d="ngbDatepicker" formControlName="fechadeEntrega" placement="bottom-left"
                                             [minDate]="minDate">
                                      <div class="input-group-append">
                                       
    
                                        <div class="input-group-text" (click)="d.toggle()" ngbTooltip="Primer fecha de entrega en 48hs" ><span class="ic-calendario"></span></div>

                                      </div>
                                      
                                    </div>

                                        <div *ngIf="errorFecha " class="alerta alerta-3"  >
                                                {{errorFecha}}
                                         </div>
                                    
                                 </div>
                             </div>


                            
                            <!-- Artículo -->
                        <div class="form-group col-md-4">
                            <label for="codArticulo">Articulo</label>
                             <!-- [compareWith]="compareFn" -->
                             <!-- -{{pedidoForm.value.codArticulo}}a -->
                             <!-- {{listaDeProductos|json}} -->
                            <select class="form-control " id="codArticulo"  formControlName="codArticulo"  
                                    (change)="setArticuloSeleccionado(pedidoForm.value.codArticulo)"  >
                                    <option [ngValue]="null">Productos</option>
                                    <ng-container *ngFor="let producto of listaDeProductos">
                                        <option  [ngValue]="producto.COD_ARTICU">{{producto.DESCRIPCIO}}</option>  
                                    </ng-container>
                                
                            </select >
                            
                        </div>

                            <!-- Perfil -->

                        <div class="form-group col-md-3">
                            <label>Cantidad</label>
                            <input type="number" class="form-control" id="cantidad" required formControlName="cantidad">
                            <div *ngIf=" (pedidoForm.controls.cantidad.invalid && (pedidoForm.controls.cantidad.dirty|| pedidoForm.controls.cantidad.touched  ) )" class="alerta alerta-3"  >
                            La cantidad es no es válida.
                            </div>
                        </div>

                        <div class="form-group col-md-1">
                            <label>Unidades</label>
                            <input type="text" class="form-control"  id="unidadDeMedida" formControlName="unidadDeMedida"   disabled>
                        </div>

                        <div class="form-group col-md-4">
                            <label>&nbsp;</label>
                            <button class="btn btn-primary" (click)="agregarItem(pedidoForm.value.codArticulo,pedidoForm.value.cantidad,pedidoForm.value.unidadDeMedida)">+ Agregar</button>
                        </div>

                    

                    

                    <hr>

                    <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y " *ngIf="items.length>0">
                        <thead>
                            <tr>
                                <th>
                                    ARTICULO
                                </th>
                                <th>
                                    CANTIDAD
                                </th>
                                <th>
                                    UNIDADES
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items; index as i">
                                <!-- {{item|json}} -->
                                <td>
                                    {{item?.articulo?.DESCRIPCIO}}
                                </td>
                                <td>
                                    <span *ngIf="editCantidad[i]==0">{{item.cantidad}}</span>
                                     <!-- <div  class="form-group col-md-3"> -->
                                        <input  *ngIf="editCantidad[i]!=0" type="number"  class="w-50" id="cantidadEditada"  [(ngModel)]="editCantidad[i]" [ngModelOptions]="{standalone: true}">
                                    <!-- </div> -->
                                </td>
                                <td>
                                    {{item.unidad}}
                                </td>
                                <td *ngIf="editCantidad[i]==0">
                                    <a href="javascript:;" (click)="modificarItem(i)"><i class="icono ic-edit"  ngbTooltip="Modificar el ítem"></i></a>
                                    <a href="javascript:;" (click)="borrarItem(i)"><i class="icono ic-delete" ngbTooltip="Borrar el ítem"></i></a>
                                </td>
                                <td *ngIf="editCantidad[i]!=0">
                                    <a href="javascript:;" (click)="confirmarModificacionItem(i)"><i class="icono ic-confirmar"  ngbTooltip="Confirmar"></i></a>
                                   
                                </td>
                            </tr>
                            <!-- Total de Kilos en el pedido -->
                            <!-- <tr >
                                <td>
                                   <b>Peso Total</b> 
                                </td>
                                <td>
                                    <b>{{pesoTotal}}</b> 
                                </td>
                                <td>
                                   
                                </td>
                                <td>
                                  
                                </td>
                            </tr> -->
                        </tbody>

                        <h3>Peso Total: {{pesoTotal}} KGS</h3>
                    </table>

                    <!-- Comentarios -->
                            <div class="form-group col-md-10 ">
                                       <label for="comentario">Comentario:</label>
                            <textarea class="form-control" rows="5" id="comentario" formControlName="comentario" placeholder="Deje Su Comentario"></textarea>
                           
                        </div>
                        <div *ngIf=" (pedidoForm.controls.comentario.invalid && (pedidoForm.controls.comentario.dirty|| pedidoForm.controls.comentario.touched  ) )" class="alert alert-danger">
                            Ingrese un comentario.
                        </div>
                        <div class="alerta alerta-3" *ngIf="mensajeError">{{mensajeError}}</div>

                     </form>   
                    <div class="text-center pt-2 pb-2">
                        <button class="btn btn-primary mb-2" (click)="grabarPedido()">Grabar</button>
                        <button class="btn btn-secondary" (click)="cancelar()">Cancelar </button>     
                    </div>

                </div>


            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
