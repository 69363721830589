import { NgModule,ModuleWithProviders   } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


/* Librerias Externas */
import { NgxSpinnerModule }          from "ngx-spinner";
import { OrderModule }               from 'ngx-order-pipe';
import { PerfectScrollbarModule }    from 'ngx-perfect-scrollbar';

    //favorites
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';

  // Mapas
import { AgmCoreModule } from '@agm/core';

// ngx-mat-select-search
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// View Json
import { NgxJsonViewerModule } from 'ngx-json-viewer';

// Editor CSS/HTML
// import { CKEditorModule } from 'ng2-ckeditor';

// 'ng-pick-datetime';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
// import { SetPickerLabels } from './components/ng-pick-datetime/set-picker-labels.component';
// import { OWL_DATE_TIME_LOCALE , OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';

// ng-moment (reemplazo el momento js que estaba instalado en la maqueta)
import { MomentModule } from 'ngx-moment';

// ngx-color-picker
import { ColorPickerModule } from 'ngx-color-picker';

// ngx-youtube-player
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';



import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
    datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timePickerInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

// export class OwlDateCustomLabels extends OwlDateTimeIntl {
//   public cancelBtnLabel = 'TEXT FOR CANCEL';
//   public setBtnLabel = 'TEXT FOR SET';
// };


/* Esto se copio del core, Verificar si es necesario para este módulo */
import { NgxPaginationModule } from 'ngx-pagination';


import {NgxMaskModule,IConfig } from 'ngx-mask' // mascaras para inputs  https://www.npmjs.com/package/ngx-mask
export const options: Partial<IConfig> | (() => Partial<IConfig>)={};

// Manejo de fechas
import { NgbDateCustomParserFormatter}                 from '@maq-servicios/dateformat/dateformat';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'

// ngx-extended-pdf-viewer
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

/* Componentes */
import { ApplicationsComponent }          from '@maq-shared/components/applications/applications.component';
import { BackTopComponent }               from '@maq-shared/components/back-top/back-top.component';
import { BindTranslateComponent }         from '@maq-shared/components/bind-translate/bind-translate.component';
import { BreadcrumbComponent }            from '@maq-shared/components/breadcrumb/breadcrumb.component';
// import { FavoritesComponent }             from '@maq-shared/components/favorites/favorites.component';
import { FlagsMenuComponent }             from '@maq-shared/components/flags-menu/flags-menu.component';
import { FormButtonsComponent }           from '@maq-shared/components/form-buttons/form-buttons.component';
// import { FormDireccionComponent }         from '@maq-shared/components/form-direccion/form-direccion.component';
import { FormRedSocialComponent }         from '@maq-shared/components/form-redSocial/form-redSocial.component';
import { FormSettingsComponent }          from '@maq-shared/components/form-settings/form-settings.component';
import { FormTelefonoComponent }          from '@maq-shared/components/form-telefono/form-telefono.component';
import { FormZonasComponent }             from '@maq-shared/components/form-zonas/form-zonas.component';
import { FullScreenComponent }            from '@maq-shared/components/fullscreen/fullscreen.component';
// import { InputCroppieComponent }          from '@maq-shared/components/input-croppie/input-croppie.component';
import { InputImageComponent }            from '@maq-shared/components/input-image/input-image.component';
// import { GoogleMapAutocompleteComponent } from '@maq-shared/components/google-map-autocomplete/google-map-autocomplete.component';
// import { HereMapAutocompleteComponent }   from '@maq-shared/components/here-map-autocomplete/here-map-autocomplete.component';
import { MessagesComponent }              from '@maq-shared/components/messages/messages.component';
import { PaginadoServidorComponent }      from '@maq-shared/components/paginado-servidor/paginado-servidor.component';
import { PickIconoComponent }             from '@maq-shared/components/pick-icono/pick-icono.component';
import { InputFileComponent }             from '@maq-shared/components/input-file/input-file.component';
// import { SearchAutocompleteComponent }    from '@maq-shared/components/search-autocomplete/search-autocomplete.component';
// import { SideChatComponent }              from '@maq-shared/components/side-chat/side-chat.component';
// import { CotizacionesComponent }          from '@maq-shared/components/cotizaciones/cotizaciones.component';
import { IsOnLineComponent }              from '@maq-shared/components/is-on-line/is-on-line.component';
import { InputUrlYoutubeComponent }       from '@maq-shared/components/input-url-youtube/input-url-youtube.component';

// import { TransferenciaArchivosComponent } from '@maq-shared/components/transferencia-archivos/transferencia-archivos.component';


// directives
import { WidgetDirective }                from '@maq-shared/directives/widget/widget.directive';
// Pipes
import { PrettyJsonPipe }                 from '@maq-shared/pipes/prettyJson/prettyJson.pipe';

import { DatePipe }                       from '@maq-shared/pipes/date/date.pipe';
import { FechaHoraPipe }                  from '@maq-shared/pipes/date/fecha-hora.pipe';
import { FechaPipe }                      from '@maq-shared/pipes/date/fecha.pipe';
import { HoraPipe }                       from '@maq-shared/pipes/date/hora.pipe';

import { MonedaArgentinaPipe }            from '@maq-shared/pipes/monedaArgentina/moneda-argentina.pipe';

import { ProfilePicturePipe }             from '@maq-shared/pipes/profilePicture/profilePicture.pipe';

import { SafeHtmlPipe }                   from '@maq-shared/pipes/safeHtml/safeHtml.pipe';

import { ChatPersonSearchPipe }           from '@maq-shared/pipes/search/chat-person-search.pipe';
import { DistribuidorSearchPipe }         from '@maq-shared/pipes/search/distribuidor-search.pipe';
import { GenericSearchPipe }              from '@maq-shared/pipes/search/generic-search.pipe';
import { MailSearchPipe }                 from '@maq-shared/pipes/search/mail-search.pipe';
import { RangoFechasSearchPipe }          from '@maq-shared/pipes/search/rango-fechas-search.pipe';
import { SearchFiltroPipe }               from '@maq-shared/pipes/search/search-filtro.pipe';
import { SearchFiltroValorONullPipe }     from '@maq-shared/pipes/search/search-filtro-valor-o-null.pipe';
import { SucursalSearchPipe }             from '@maq-shared/pipes/search/sucursal-search.pipe';
import { UserSearchPipe }                 from '@maq-shared/pipes/search/user-search.pipe';

import { TruncateTextPipe }               from '@maq-shared/pipes/show/truncateText.pipe';
import { Show2daParteResaltadaPipe }      from '@maq-shared/pipes/show/show2daParteResaltada.pipe';

import { TruncatePipe }                   from '@maq-shared/pipes/truncate/truncate.pipe';




@NgModule({
  declarations: [

  //Components
  ApplicationsComponent,
  BackTopComponent,
  BindTranslateComponent,
  BreadcrumbComponent,
  // FavoritesComponent,
  FlagsMenuComponent,
  FormButtonsComponent,
  // FormDireccionComponent,
  FormRedSocialComponent,
  FormSettingsComponent,
  FormTelefonoComponent,
  FormZonasComponent,
  FullScreenComponent,
  // InputCroppieComponent,
  InputFileComponent,
  InputImageComponent,
  // GoogleMapAutocompleteComponent,
  // HereMapAutocompleteComponent,
  MessagesComponent,
  PaginadoServidorComponent,
  PickIconoComponent,
  // SearchAutocompleteComponent,
  // SideChatComponent,
  // CotizacionesComponent,
  IsOnLineComponent,
  InputUrlYoutubeComponent,

  // TransferenciaArchivosComponent,
  
  // directives
  WidgetDirective,
  // Pipes
  PrettyJsonPipe,
  DatePipe,
  FechaHoraPipe,
  FechaPipe,         
  HoraPipe,             
  MonedaArgentinaPipe,
  ProfilePicturePipe,   
  SafeHtmlPipe,    
  ChatPersonSearchPipe,
  DistribuidorSearchPipe,
  GenericSearchPipe,
  MailSearchPipe,     
  RangoFechasSearchPipe,
  SearchFiltroPipe, 
  SearchFiltroValorONullPipe,
  SucursalSearchPipe,   
  UserSearchPipe,       
  TruncateTextPipe,     
  Show2daParteResaltadaPipe,
  TruncatePipe,        


  ],
  imports: [
    // NgModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiselectDropdownModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    ImageCropperModule,
    NgxSpinnerModule,
    OrderModule,
    PerfectScrollbarModule,
    
    MatSelectModule, MatIconModule, MatFormFieldModule, MatToolbarModule, NgxMatSelectSearchModule,
        
    NgxJsonViewerModule,
    AgmCoreModule,
    // CKEditorModule,

    OwlDateTimeModule, OwlNativeDateTimeModule,

    ColorPickerModule,
    MomentModule,
    NgxExtendedPdfViewerModule,
    NgxYoutubePlayerModule.forRoot(),
    // AngMusicPlayerModule,
 
    // inicio copia Core
    MultiselectDropdownModule,
    NgbModule,
    NgxMaskModule.forRoot(options), //mascaras
    NgxPaginationModule,
    PerfectScrollbarModule,
    // fin    copia Core
  
  ],
   exports: [    
    
    //Components
    ApplicationsComponent,
    BackTopComponent,
    BindTranslateComponent,
    BreadcrumbComponent,
    // FavoritesComponent,
    FlagsMenuComponent,
    FormButtonsComponent,
    // FormDireccionComponent,
    FormRedSocialComponent,
    FormSettingsComponent,
    FormTelefonoComponent,
    FormZonasComponent,
    FullScreenComponent,
    // InputCroppieComponent,
    InputFileComponent,
    InputImageComponent,
    // GoogleMapAutocompleteComponent,
    // HereMapAutocompleteComponent,
    MessagesComponent,
    PaginadoServidorComponent,
    PickIconoComponent,
    // SearchAutocompleteComponent,
    // SideChatComponent,
    // TransferenciaArchivosComponent,
    // CotizacionesComponent,    
    IsOnLineComponent,
    InputUrlYoutubeComponent,
    
    // directives
    WidgetDirective,
    // Pipes
    PrettyJsonPipe,
    DatePipe,
    FechaHoraPipe,
    FechaPipe,         
    HoraPipe,             
    MonedaArgentinaPipe,
    ProfilePicturePipe,   
    SafeHtmlPipe,    
    ChatPersonSearchPipe,
    DistribuidorSearchPipe,
    GenericSearchPipe,
    MailSearchPipe,     
    RangoFechasSearchPipe,
    SearchFiltroPipe,
    SearchFiltroValorONullPipe, 
    SucursalSearchPipe,   
    UserSearchPipe,       
    TruncateTextPipe,     
    Show2daParteResaltadaPipe,
    TruncatePipe,        

    //revisar
    NgxSpinnerModule,
    OrderModule,
    MatSelectModule, MatIconModule, MatFormFieldModule, MatToolbarModule, NgxMatSelectSearchModule,
    NgxJsonViewerModule,
    // CKEditorModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    ColorPickerModule,
    MomentModule,    
    NgxExtendedPdfViewerModule,
    
  ],
   providers: [
    // SetPickerLabels,
    //  {provide: OwlDateTimeIntl, useClass: SetPickerLabels },
    //  {provide: OWL_DATE_TIME_LOCALE, useValue: 'es'},
    //  {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
     TranslateService, 
     {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
  ],
  // schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }

