import { Component, OnInit,ChangeDetectorRef }             from '@angular/core';
import { AngularFireDatabaseModule, AngularFireDatabase  } from '@angular/fire/database';

import { MensajesService }                                 from '../../../services/mensajes/mensajes.service';
import { ModalMensajeComponent }  from '../../../services/modal-mensaje/modal-mensaje.component';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FiredatabaseService }  from '@proyecto/servicios/firebase/firedatabase.service';;
import { AutorizacionesService }  from '../../../services/autorizaciones/autorizaciones.service';
import { User } from '../user'
import { Router,NavigationEnd } from "@angular/router";
import { Grilla }                                       from '@maq-models/grilla/grilla.model';


@Component({
  selector: 'app-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.css']
})
export class UsuarioListComponent implements OnInit {
  userSelected:User=new User();
  mostrarFormulario: boolean=false;
  cantidadFiltro:number=0;
  listaUsuarios: Array<User>;
  filtroMail: string=null;
  filtroEmpresa: string=null;
  pageSize:number=25;
  page:number=1;
  collectionSize:number=26;
  previoMail:string='';
  navigationSubscription;



  public tiposPerfil=[{id:'administrador',val:'Administrador'},
                      {id:'gestorPedidos',val:'Gestor de Pedidos'},
                      {id:'cliente',val:'Cliente'},
                      {id:'distribuidor',val:'Distribuidor'},
                      {id:'transportista',val:'Transportista'}
                      ];

  public tiposEstado=[
                      // {id:'pendiente',val:'Pendiente'},
                      {id:'activo',val:'Activo'},
                      {id:'suspendido',val:'Suspendido'} 
                      ];
                  
  public empresaFiltro    : string='';
  public emailFiltro      : string='';
  public perfilFiltro     : string=null;
  public estadoFiltro     : string=null;

  public grilla                   : Grilla;
  public pipePaginado             : any;
  public pagePaginado             : any;

  public ordenCampo   : string ='';    // Se usa en el listado para ordenar por este campo
  public ordenReverse : boolean =true;  // Determina el orden del filtro anterios

  constructor(db: AngularFireDatabase,
      private mensageService:MensajesService,
      private router:Router,
      private autorizaciones:AutorizacionesService,
      private cd: ChangeDetectorRef,
      private fs:FiredatabaseService) {
        
        let grilla_ = {
          paginadoTipo          : 'local',    // local / servidor
          paginadoCantidad      : 20,
          paginadoAutoHide      : false,
       }
       this.grilla = new Grilla({});
       this.setGrilla(grilla_);
      

  }

  ngOnInit() {
    console.log("UsuarioListComponent");

    if(this.autorizaciones.validarAutorizacion("UsuarioListComponent")){
       console.log("settings  autorizado");
       // this.fs.getUsers().subscribe(data=>{
        this.fs.getUsersFilterMail('').subscribe(data=>{
    
                this.listaUsuarios =data;
                // this.collectionSize=data.length;
                console.log('this.listaUsuarios get', data);
                 if (!this.cd['destroyed']) {
                      this.cd.detectChanges();
                  }
		    this.mensageService.getUserObs().subscribe(user=>{
            console.log("user",user);
            this.userSelected=user;
        });
    })}else{
           console.log("settings NO  autorizado");
        }


     this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        console.log(e);
        if (e instanceof NavigationEnd) {
          this.ngOnDestroy();
          this.ngOnInit();

          }
     });      

 
  }

nuevoUsuario(){
  let user= new User();
  this.setUserSelected(user);
}
limpiarfiltros(){
  this.empresaFiltro='';
  this.emailFiltro='';
  this.perfilFiltro=null;
  this.estadoFiltro=null;

}

//copia los datos del usuario al componente usuario Nuevo para su edicion
  setUserSelected(userSelected:User){
    console.log(this.userSelected);
    this.userSelected=userSelected;
    this.mostrarFormulario=true;
    this.mensageService.setUserObs(userSelected);
     if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
    console.log(this.userSelected);
    console.log('this.listaUsuarios', this.listaUsuarios);
  }



ejecutarFiltroMail(){
  console.log('ejecutarFiltroMail this.filtroMail', this.filtroMail);
        this.fs.getUsersFilterMail(this.filtroMail).subscribe(data=>{
                this.listaUsuarios =data;
                console.log('this.ejecutarFiltroMail data', data);
                this.listaUsuarios;
            },error=>{
                console.log('this.ejecutarFiltroMail error', error);
            });
  }

ejecutarFiltroEmpresa(){

  console.log(this.filtroEmpresa);
this.filtroEmpresa =this.filtroEmpresa.toUpperCase();
console.log(this.filtroEmpresa);

  console.log('ejecutarFiltroEmpresa this.filtroMail', this.filtroEmpresa);
        this.fs.getUsersFilterEmpresa(this.filtroEmpresa).subscribe(data=>{
                this.listaUsuarios =data;
                console.log('this.ejecutarFiltroEmpresa data', data);
                this.listaUsuarios;
            },error=>{
                console.log('this.ejecutarFiltroEmpresa error', error);
            });
  }

onChanceFiltroMail(filtroMail:string){
  console.log('onChanceFiltroMail',filtroMail);
}

ejecutarGetNext(){
        console.log('ejecutarGetNext');
        let last=this.listaUsuarios[this.listaUsuarios.length-1].email;
        this.previoMail=this.listaUsuarios[0].email; // se usa para buscar el previo
        this.fs.getUsersFilterMail(last).subscribe(data=>{
                this.listaUsuarios =data;
                console.log('this.ejecutarFiltroMail data', data);
                this.listaUsuarios;
            },error=>{
                console.log('this.ejecutarFiltroMail error', error);
            });
  };

  ejecutarGetPrevio(){
        console.log('ejecutarGetPrevio');
       
        this.fs.getUsersFilterMail(this.previoMail).subscribe(data=>{
                this.listaUsuarios =data;
                console.log('this.ejecutarFiltroMail data', data);
                this.listaUsuarios;
            },error=>{
                console.log('this.ejecutarFiltroMail error', error);
            });
  };

  loadPage(page: number) {
     console.log('loadPage', page);
     if(page==2){
       this.ejecutarGetNext();
     } else if( page == 1){
       this.ejecutarFiltroMail();
     }
   
    // if (page !== this.previousPage) {
    //   this.previousPage = page;
    //   this.loadData();
  
  }


ngOnDestroy() {
       if (this.navigationSubscription) {  
           this.navigationSubscription.unsubscribe();
          }
  
}

// Selecciona el campo y el orden con el que se ordena la tabla .
setOrden(campo:string){
    this.ordenCampo=campo;
    this.ordenReverse=!this.ordenReverse;
}

setGrilla(valores:any) {
  //   console.log("setGrilla",valores);

    let objValores = {};
    if(valores.paginadoTipo!==undefined)              objValores['paginadoTipo']              = valores.paginadoTipo;
    if(valores.orderField!==undefined)                objValores['orderField']                = valores.orderField;
    if(valores.orderReverse!==undefined)              objValores['orderReverse']              = valores.orderReverse;
    if(valores.orderServer!==undefined)               objValores['orderServer']               = valores.orderServer;
    if(valores.filtroNombre!==undefined)              objValores['filtroNombre']              = valores.filtroNombre;
    if(valores.filtrosServer!==undefined)             objValores['filtrosServer']             = valores.filtrosServer;
    if(valores.filtrosGrupos!==undefined)             objValores['filtrosGrupos']             = valores.filtrosGrupos;
    if(valores.filtrosInicial!==undefined)            objValores['filtrosInicial']            = valores.filtrosInicial;
    if(valores.campoKeywords!==undefined)             objValores['campoKeywords']             = valores.campoKeywords;      
    if(valores.paginadoCantidad!==undefined)          objValores['paginadoCantidad']          = valores.paginadoCantidad;
    if(valores.paginadoAutoHide!==undefined)          objValores['paginadoAutoHide']          = valores.paginadoAutoHide;
    if(valores.verColumnaKey!==undefined)             objValores['verColumnaKey']             = valores.verColumnaKey;
    if(valores.whereArray !==undefined)               objValores['whereOriginal']             = valores.whereArray;
    if(valores.whereArray !==undefined)               objValores['whereArray']                = valores.whereArray;
    if(valores.camposDecimal!==undefined)             objValores['camposDecimal']             = valores.camposDecimal;
    if(valores.cotizacionesActivas!==undefined)       objValores['cotizacionesActivas']       = valores.cotizacionesActivas;

    if(valores.paginadoTipo=='local') {
        objValores['paginaRequerida'] = 'todas';
        this.pipePaginado = {  
          paginate: { 
            itemsPerPage: 20, 
            currentPage: this.pagePaginado 
          }
        }
    } else {
        objValores['paginaRequerida'] = 'primera';
    }    
    
    //this.grilla = new Grilla(objValores);
    this.grilla.setValue('this.configComponente.nombreColeccion', objValores);

    // Inicializo Filtros
    for(let i=0; i<this.grilla.filtrosServer.length; i++) {
      let nombreFiltro=this.grilla.filtrosServer[i];
      this.grilla.filtros[nombreFiltro] = null;
    } 

}        
onChangeGrilla(cual, value:any) {

  // Hizo click en alguna de las columnas para cambiar el orden
  if(cual=='order') {
      if(this.grilla.paginadoTipo=='servidor') { 
          if(this.grilla.orderServer.indexOf(value)!=-1) {  
              // Sólo ejecuto el ordenamiento en modo servidor
              // si la columana está incluída entre las disponibles para ordenar
              this.grilla.setOrder(value);
              this.grilla.paginaRequerida = 'primera';
        
          }    
      } else {
          this.grilla.setOrder(value);
      }           
  }

  // Hizo click en el dropDown de cantidad de elementos a mostrar en el paginado
  if(cual=='paginadoCantidad') {
      if(this.grilla.paginadoTipo=='servidor') { 
          this.grilla.paginaRequerida = 'primera';
        
      }  
  }  
}  

  accion(data:any){
    console.log('accion',data);
    this.mostrarFormulario=false;
  }


}





