    <form class="row" [formGroup]="crearLabelForm">

        <p class="alerta alerta-2 col-md-12" *ngIf="crearLabelForm.status!='VALID'">
            Complete todos los datos corectamente
            <!-- Form Status: {{ crearLabelForm.status }}
            Form value: {{ crearLabelForm.value }} -->
        </p>
        <br>

        <!-- E mail -->
        <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email" required />
            <div *ngIf=" (crearLabelForm.controls.email.invalid && (crearLabelForm.controls.email.dirty|| crearLabelForm.controls.email.touched  ) )" class="alerta alerta-3">
                El email es no es válido.
            </div>
            <div *ngIf="existeEmailenlaBase" class="alerta alerta-3">
                Ya existe un usuario con ese Email!!!
            </div>
        </div>

        <!-- Estado -->
        <div class="form-group col-md-6">
            <label for="estado">Estado</label>
            <select class="form-control selectpicker" id="estado" formControlName="estado" required>
                <option  *ngFor="let estado of tiposEstado" [value]="estado.id" >{{estado.val}}</option>
            </select>
            <div *ngIf=" (crearLabelForm.controls.estado.invalid && (crearLabelForm.controls.estado.dirty|| crearLabelForm.controls.estado.touched  ) )" class="alerta alerta-3">
                El estado es no es válido.
            </div>
        </div>

        <!-- Perfil -->
        <div class="form-group col-md-6">
            <label for="estado">Perfil</label>
            <select class="form-control selectpicker" id="perfil" formControlName="perfil" (change)="cambioPerfil(crearLabelForm.value.perfil)" >
                <option  *ngFor="let perfil of tiposPerfil" [value]="perfil.id" [ngValue]="perfil">{{perfil.val}}</option>
            </select>
            <div *ngIf=" (crearLabelForm.controls.perfil.invalid && (crearLabelForm.controls.perfil.dirty|| crearLabelForm.controls.perfil.touched  ) )" class="alerta alerta-3">
                El estado es no es válido.
            </div>
            <div *ngIf="mostrarValidacion"  class="alerta alerta-3">
                {{mensajeErrorEmpresa}}   {{mensajeErrorInfoTrak}}
            </div>

        </div>

        <!--Empresa -->
        <div class="form-group col-md-6" 
        *ngIf="crearLabelForm.value.perfil=='cliente'||crearLabelForm.value.perfil=='distribuidor' || crearLabelForm.value.perfil=='administrador'">
            <label for="empresa">Razón Social</label>
            <div class="form-control">
                <input  type="text" class="" id="empresa" formControlName="empresa" />
                <!-- <button class="btn btn-primary" (click)="agregarEmpresa()">Agregar</button> -->
                <button class="btn btn-primary" (click)="buscarEmpresa()">Buscar</button>
            </div>
            <!--<button class="btn btn-primary" (click)="buscarEmpresa()">Buscar</button>-->
             <div *ngIf=" (crearLabelForm.controls.empresa.invalid && (crearLabelForm.controls.empresa.dirty|| crearLabelForm.controls.empresa.touched  ) )" class="alerta alerta-3">
                Empresa es válida
            </div>
        </div>

        <!--InfoTrak -->
        <div class="form-group col-md-6" *ngIf="crearLabelForm.value.perfil=='transportista'">
            <label for="infoTrak">Referencias Infotrak</label>
             <select class="form-control selectpicker" id="infoTrak" formControlName="infoTrak">
                <option  *ngFor="let tramporte of ListaTransporteInfotrak" [value]="tramporte.id_infotrak" >{{tramporte.id_infotrak}}-{{tramporte.alias}}</option>
            </select>
            <div *ngIf=" (crearLabelForm.controls.infoTrak.invalid && (crearLabelForm.controls.infoTrak.dirty|| crearLabelForm.controls.infoTrak.touched  ) )" class="alerta alerta-3">
                Seleccione un Transporte
            </div>
        </div>
    

    </form>


<div >
                                
    <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt" *ngIf="listaEmpresas"  style="width:100%;" >
        <thead>
            <tr >
                <th style="width:2%;">
                    Código Cliente
                </th >
                <th style="width:45%;">
                    Razón Social
                </th >
                <th style="width:45%;">
                    Cuit
                </th  >
                <th style="width:6%;">
                    Localidad
                </th>
                <th style="width:2%;">
                    Acciones
                </th>
            </tr>
        </thead>
        <tbody>

            <tr  *ngFor="let emp of listaEmpresas; let i = index" [attr.data-index]="i" style="width:100%;" >
                <td class="listaEmpresas" style="width:2%;">
                    {{emp.COD_CLIENT}}
                </td>
                <td class="listaEmpresas" style="width:45%;">
                    {{emp.RAZON_SOCI}}
                </td>
                <td class="listaEmpresas" style="width:45%;">
                    {{emp.CUIT}}
                </td>
                <td class="listaEmpresas" style="width:6%;">
                    {{emp.LOCALIDAD}}
                </td>
                <td (click)="borrarEmpresa(i)" class="listaEmpresas" style="width:2%;">
                    <i class="icono ic-trash" ngbTooltip="Borrar"></i> 
                </td>
            </tr>
        </tbody>
    </table>
</div>



    <div class="col-sm-12 text-center">
            <button class="btn btn-primary" (click)="updateUserData()" *ngIf="crearLabelForm.status=='VALID'"> Enviar </button>
            <button class="btn btn-secondary" (click)="borrarFormulario()"> Limpiar </button>
        </div>
    <!-- </div> -->
