<div class="component remitos">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2 style="width: 100%;">Remitos
                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']==null  ">({{listadoPrincipal.length  }}) </span>
                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']==true  ">({{cantidadPedidosEntregados  }}) </span>
                <span *ngIf="listadoPrincipal && filtro['ESTADO_FILTRO']==false ">({{listadoPrincipal.length-cantidadPedidosEntregados  }}) </span>
                <span *ngIf="!mdiSpin" (click)="refrescarListado()" class="float-right"><i class="mdi mdi-refresh text-primary mdi-24px"></i>  Refrescar </span>
                <span *ngIf="mdiSpin" class="float-right" ><i class="mdi mdi-refresh mdi-spin text-primary mdi-24px"></i>  Refrescar </span>
            </h2>
           
           
            

        </div>

        
        <div class="row inner-estructura">


            <div class="col-sm-12">

             
            <div class="text-center"><div class="loading" *ngIf="showSpinner"></div></div>

                <div *ngIf="mensajeError" >{{mensajeError}}</div>
             
                <!-- inidio listado -->
                <!-- inicio listado -->
                
                

                <table *ngIf="listadoPrincipal.length>0" class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt" 
                       style=" position:relative; margin:auto;   width:100%;">
                    <thead>
                        <tr>
                            <th (click)="setOrden('N_REMITO')">
                                
                                <span class="" *ngIf="ordenCampo!='N_REMITO'">Remito</span>
                                <span class="text-primary" *ngIf="ordenCampo=='N_REMITO'">Remito</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='N_REMITO' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='N_REMITO' && !ordenReverse" ></i>
                            </th>
                        
                            <th (click)="setOrden('FECHA_REMITO_PARAORDEN')">
                                
                                <span class="" *ngIf="ordenCampo!='FECHA_REMITO_PARAORDEN'">Fecha</span>
                                <span class="text-primary" *ngIf="ordenCampo=='FECHA_REMITO_PARAORDEN'">Fecha</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='FECHA_REMITO_PARAORDEN' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='FECHA_REMITO_PARAORDEN' && !ordenReverse" ></i>
                            </th>
                           
                            <th (click)="setOrden('COD_CLIENT')">

                                <span class="" *ngIf="ordenCampo!='COD_CLIENT'">C.Cliente</span>
                                <span class="text-primary" *ngIf="ordenCampo=='COD_CLIENT'">C.Cliente</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='COD_CLIENT' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='COD_CLIENT' && !ordenReverse" ></i>

                                
                            </th>
                            <th (click)="setOrden('RAZON_SOCI')">

                                <span class="" *ngIf="ordenCampo!='RAZON_SOCI'">RAZÓN SOCIAL</span>
                                <span class="text-primary" *ngIf="ordenCampo=='RAZON_SOCI'">RAZÓN SOCIAL</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='RAZON_SOCI' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='RAZON_SOCI' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('NOMBRE_TRA')">

                                <span class="" *ngIf="ordenCampo!='NOMBRE_TRA'">Transportista</span>
                                <span class="text-primary" *ngIf="ordenCampo=='NOMBRE_TRA'">Transportista</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='NOMBRE_TRA' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='NOMBRE_TRA' && !ordenReverse" ></i>
                            </th>
                            <th (click)="setOrden('ACEPTA_ENTREGA_SIN_CLIENTE')"  style="width: 155px;">

                                <span class="" *ngIf="ordenCampo!='ACEPTA_ENTREGA_SIN_CLIENTE'">Modo</span>
                                <span class="text-primary" *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE'">Modo Entrega</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='ACEPTA_ENTREGA_SIN_CLIENTE' && !ordenReverse" ></i>
                            </th>

                            <th (click)="setOrden('ESTADO_ENTREGA_TRANSPORTISTA')"  style="width: 165px;" >

                                <span class="" *ngIf="ordenCampo!='ESTADO_ENTREGA_TRANSPORTISTA'">Estado</span>
                                <span class="text-primary" *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA'">Estado</span>
                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA' && ordenReverse" ></i>
                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='ESTADO_ENTREGA_TRANSPORTISTA' && !ordenReverse" ></i>
                            </th>

                            <th >

                                <span class="" >Mapa</span>
                                
                            </th>

                        </tr>

                        <tr>
                     <th>
                         <input  class="form-control" 
                                 type="text" id="N_REMITO"  
                                 [(ngModel)]="filtro['N_REMITO']" 
                                 placeholder="Remito"/>
                     </th>
                     <th>
                         <input  class="form-control" 
                                 type="text" id="FECHA_REMITO"  
                                 [(ngModel)]="filtro['FECHA_REMITO']" 
                                 placeholder="Fecha"/>
                     </th>
                     <th>
                         <input  class="form-control" 
                                 type="text" id="COD_CLIENT"  
                                 [(ngModel)]="filtro['COD_CLIENT']" 
                                 placeholder="Codigo Cliente"/>
                     </th>
                     <th>
                         <input  class="form-control" 
                                 type="text" id="RAZON_SOCI"  
                                 [(ngModel)]="filtro['RAZON_SOCI']" 
                                 placeholder="Razón Social"/>
                     </th>
                     <th>
                         <input  class="form-control" 
                                 type="text" id="NOMBRE_TRA"  
                                 [(ngModel)]="filtro['NOMBRE_TRA']" 
                                 placeholder="Transportista"/>
                     </th>
                    
                     <th>
                        <select class="form-control selectpicker" id="ACEPTA_ENTREGA_SIN_CLIENTE"
                                    [(ngModel)]="filtro['ACEPTA_ENTREGA_SIN_CLIENTE']">
                                <option  [ngValue]=null>Modo</option>
                                <option  [ngValue]=true>C.Cliente</option>
                                <option  [ngValue]=false>S.Cliente</option>
                        </select>
                     
                     </th>
                     <th>
                        
                        <select class="form-control selectpicker" id="ESTADO_ENTREGA_TRANSPORTISTA"
                                 [(ngModel)]="filtro['ESTADO_ENTREGA_TRANSPORTISTA']" 
                                 (change)="cambioFiltroEntrega()">
                             <option  [ngValue]=null>Estado</option>
                             <option  [ngValue]="'Entregado '">Entregado</option>
                             <option  [ngValue]="'Cancelado'">Cancelado</option>
                             <option  [ngValue]=false>Transporte</option> 
                        </select>

                     </th>

                     <th  ngbTooltip="Limpiar filtros"  class="text-center" (click)="limpiarfiltros()" >
                        <i class="mdi mdi-24px mdi-broom"></i>
                    </th>

                   
                 </tr>
             </thead>
  
             <tbody class="accordion" id="accordionTable">    
              
                <ng-container *ngFor="let documento of listadoPrincipal
                         | orderBy :ordenCampo :ordenReverse :'case-insensitive'  
                         | searchFiltro:'local':'N_REMITO':filtro['N_REMITO'] 
                         | searchFiltro:'local':'FECHA_REMITO':filtro['FECHA_REMITO'] 
                         | searchFiltro:'local':'COD_CLIENT':filtro['COD_CLIENT'] 
                         | searchFiltro:'local':'RAZON_SOCI':filtro['RAZON_SOCI'] 
                         | searchFiltro:'local':'NOMBRE_TRA':filtro['NOMBRE_TRA'] 
                         | searchFiltro:'local':'ESTADO_ENTREGA_TRANSPORTISTA':filtro['ESTADO_ENTREGA_TRANSPORTISTA'] 
                         | searchFiltro:'local':'ACEPTA_ENTREGA_SIN_CLIENTE':filtro['ACEPTA_ENTREGA_SIN_CLIENTE']:'boolean'
                         | paginate: { itemsPerPage: grilla.paginadoCantidad, 
                             currentPage : pagePaginado,
                             totalItems  : cantidadFiltro}; let cantidadFiltro=count ;
                             
                         let i=index    " >
                
                     <tr [ngClass]="{'filaSeleccionada': mostrarDetalle[documento.N_REMITO]}" >

                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.N_REMITO}}
                        </td>
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.FECHA_REMITO}}
                        </td>
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.COD_CLIENT}}
                        </td>
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.RAZON_SOCI}}
                        </td>
                        
                        <td (click)="visualizarDetalle(documento.N_REMITO)">
                            {{documento.NOMBRE_TRA}} <span *ngIf="documento.NOMBRE_TRA=='PROPIO'"> {{documento.PATENTE_CHASIS}} </span>
                        </td>
                        <td class="text-center" (click)="visualizarDetalle(documento.N_REMITO)" >
                            <i  title="Entrega con el cliente presente" *ngIf="!documento.ACEPTA_ENTREGA_SIN_CLIENTE"    class="text-danger  mdi mdi-24px mdi-boom-gate-outline"></i>
                            <i  title="Entregar sin el cliente" *ngIf="documento.ACEPTA_ENTREGA_SIN_CLIENTE"     class="text-success mdi mdi-24px mdi-boom-gate-up-outline"></i>
                        </td>
                        <td   class="text-center">
                            <i title="sin Id Infotrack"  *ngIf="!documento.ID_INFOTRAK "  class="text-danger mdi mdi-24px mdi-crosshairs-gps"></i>
                            <i title="Entregar"  *ngIf="documento.ID_INFOTRAK && !( documento.ESTADO_ENTREGA_TRANSPORTISTA==='Entregado ' || documento.ESTADO_ENTREGA_TRANSPORTISTA==='Cancelado ') "  (click)="confirmarNotificarEntrega(documento,i)" class="mdi mdi-24px mdi-truck-fast"></i>
                            <i title="Entregado" *ngIf="documento.ID_INFOTRAK &&  documento.ESTADO_ENTREGA_TRANSPORTISTA==='Entregado '" class="text-success mdi mdi-24px mdi-truck-check"></i>
                            <i title="Cancelado" *ngIf="documento.ID_INFOTRAK &&  documento.ESTADO_ENTREGA_TRANSPORTISTA==='Cancelado '" class="text-danger mdi mdi-24px mdi-truck-check"></i>
                        </td>
                        <td  title="Mapa" class="text-center" >
                            <i *ngIf="documento.ID_INFOTRAK && !( documento.ESTADO_ENTREGA_TRANSPORTISTA==='Entregado ' || documento.ESTADO_ENTREGA_TRANSPORTISTA==='Cancelado ') " 
                            (click)="mostrarMapa(documento.ID_INFOTRAK)"     class="mdi mdi-24px mdi-map-marker-multiple"></i>
                        </td>
                    </tr>
                    <tr *ngIf="mostrarDetalle[documento.N_REMITO]">
                        <td colspan="8"  style="background-color: white;">
                         
                            <div class="tarjetaDetalle">
                        
                            
                                <div class="row tarjetaTitulo ">
                                    <!-- <div class="col-2"></div> -->
                                    <div class="col-4" style="font: bold;">CODIGO</div>
                                    <div class="col-4" style="font: bold;">PRODUCTO</div>
                                    <div class="col-4" style="font: bold;">CANTIDAD REMITO</div>
                                </div>

                                <div class="row tarjetaDetalleRow " *ngFor="let detalle of documento.detalle">
                                    <!-- <div class="col-2"></div> -->
                                    <div class="col-4">{{detalle?.COD_ARTICU}}</div>
                                    <div class="col-4">{{detalle?.DESCRIPCIO}}</div>
                                    <div class="col-4">{{detalle?.CANTIDAD_REMITO}} {{detalle?.ID_MEDIDA_STOCK}}</div>
                                </div>
                            </div>   
                
                        </td>                            
                    </tr>
                </ng-container>    
             </tbody>
         </table>

 
         <!-- nuevo Paginado -->
         <div class="row" style="border-top:1px solid #ccc;" *ngIf="listadoPrincipal.length>0">
             <div class="col-3" style="padding:10px;">
                 <div class="bg-white text-center">
                     <select class="form-control selectpicker" 
                             id="grilla.paginadoCantidad"                                         
                             [(ngModel)]="grilla.paginadoCantidad"
                             (change)="onChangeGrilla('paginadoCantidad',grilla.paginadoCantidad)"> 
                         <ng-container *ngFor="let opcion of grilla.paginadoDropDown">
                             <option [ngValue]="opcion">Items x Página {{opcion}}</option>
                         </ng-container>
                     </select>
                 </div>    
             </div>    
             <div class="col-9" style="padding:10px;">
                 <div class="bg-white text-center">

         
                        <pagination-controls 
                         autoHide="true" 
                         previousLabel="{{'anterior' }}" 
                         nextLabel="{{'siguiente'}}" 
                         (pageChange)="pagePaginado = $event" 
                         maxSize="15"></pagination-controls>

                    </div>
                </div>                        
            </div>
      
                
                
                
                
                <!-- fin listado -->
                <!-- fin listado -->
               
            

            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
