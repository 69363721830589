<div class="component log-mail">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Configuración</h2>

        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">

                <div *ngIf="componenteHabilitado">

                    <h3>Seleccione los parámetros.</h3>

                    <form [formGroup]="formSettings">
                        <div class="form-group w-25">
                            <label for="ventanaVisualizacionPedidos">Ventana de visualización de pedidos (días):</label>
                            <input type="number" class="form-control" id="ventanaVisualizacionPedidos" placeholder="Ingrese la cantidad de días que quiere visualizar" value="30" formControlName="ventanaVisualizacionPedidos">
                        </div>
                        <div *ngIf=" (formSettings.controls.ventanaVisualizacionPedidos.invalid && (formSettings.controls.ventanaVisualizacionPedidos.dirty|| formSettings.controls.ventanaVisualizacionPedidos.touched  ) )" class="alert alert-danger">
                            Ingrese un Numero valido
                        </div>

                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="aceptaDescargaSinRepresentante" formControlName="aceptaDescargaSinRepresentante" >
                            <label class="form-check-label" for="aceptaDescargaSinRepresentante">Acepta descargar el alimento sin la presencia de un representante del establecimiento? </label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="aceptaNotificaciones" checked formControlName="aceptaNotificaciones" (click)="aceptaNotificaciones()">
                            <label class="form-check-label" for="aceptaNotificaciones">Notificar cuando hay nuevos remitos o pedidos</label>
                        </div>
                        <br>
                        <button (click)="grabarSettings()" class="btn btn-primary">Grabar</button>
                    </form>

                </div>

            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>

