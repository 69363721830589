import { Component, OnInit,OnDestroy  } from '@angular/core';

import { MensajesService }              from '../../services/mensajes/mensajes.service';
import { Router,NavigationEnd }         from "@angular/router";
import { SqlserviceService}             from '../../services/sql/sqlservice.service';

import {MapaComponent}                  from '../../mapa/cliente/mapa.component';

import { FiredatabaseService}           from '@proyecto/servicios/firebase/firedatabase.service';
import { AlertasService}                from '@proyecto/servicios/firebase/alertas.service';
import { ModalMensajeComponent }        from '../../services/modal-mensaje/modal-mensaje.component';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {RemitosServiceService}          from'../../util/remitos/remitos-service.service'


import {GrillaGenerica}                 from '../../generico/grillaGenerica'
import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'
import {Remito,DetalleRemito } from '@proyecto-models/remitos/remitos'
@Component({
  selector: 'app-remito-listado-tranportista',
  templateUrl: './remito-listado-tranportista.component.html',
  styleUrls: ['./remito-listado-tranportista.component.css']
})
export class RemitoListadoTranportistaComponent  extends GrillaGenerica  implements OnInit  {
    perfilUsuario:any=null;
    pedidoSelected:any=null;
    listadoPrincipal:any[]=[];
    vehiculos:any=null;
    mensajeSpinner = "mensaje spinner";
    showSpinner:boolean = false;
    mensajeError:String = '';

    cantidadPedidosEntregados:number=0;
    navigationSubscription;
    mdiSpin:boolean=false;
   
  
    constructor(  
              
                private mensageService    : MensajesService,
                private router            : Router,
                public  sql               : SqlserviceService,
                private db                : FiredatabaseService,
                private alertasService     : AlertasService,
                private _modal            : NgbModal,
                private remitosServcice   :RemitosServiceService) {
                
                    super();
    }
                   
  

    ngOnInit() {
      console.log("remitos");
      this.filtro['ACEPTA_ENTREGA_SIN_CLIENTE']=null;
      this.filtro['ESTADO_ENTREGA_TRANSPORTISTA']=null;
      this.getPerfil();
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();

       }
     });
    }


  getPerfil():void{
    console.log("remitos transportista  get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
    console.log("remitos transportista",perfil);
    this.perfilUsuario=perfil;

      this.mensajeError=null;
      
      this.refrescarListado();
       
    }) 
  }

  refrescarListado(){
    this.mdiSpin=true;
    this.mensajeError=null;

    if(this.perfilUsuario.data.perfil=="administrador"){
      this.buscarRemitosAdministrador(this.perfilUsuario.data.settings.ventanaVisualizacionPedidos );

    } else if(this.perfilUsuario.data.perfil=="transportista"){
      this.buscarRemitosTrasportista(this.perfilUsuario.data.settings.ventanaVisualizacionPedidos, this.perfilUsuario.data.infoTrak );
    } else if(this.perfilUsuario.data.perfil=="cliente" || this.perfilUsuario.data.perfil=="distribuidor" ){
      
       if(!this.perfilUsuario.data.EmpresaSelected ){
         this.mensajeError="Seleccione un Cliente "
       }else{
         this.mensajeError=null;
         this.buscarRemitosCliente(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT,this.perfilUsuario.data.settings.ventanaVisualizacionPedidos )
       }
    }

  }

  buscarRemitosTrasportista(cantidadDeDias,idInfotrak){
    console.log('buscarRemitos');


 
    console.log('buscarRemitos cantidadDeDias',cantidadDeDias);

    this.showSpinner=true;

  
    this.sql.getRemitoDetalladoParaTransportista_IdInfotrak(cantidadDeDias,idInfotrak)
    .subscribe(data=>{
        console.log(data);


        this.remitosServcice.acomodaRemitos(data)
          .then(remitosAcomodado=> {
            // return remitosAcomodado;
            this.listadoPrincipal=remitosAcomodado;
            this.cuentaEntregados(this.listadoPrincipal);
            console.log('acomodaRemitos ok',this.listadoPrincipal);  
            if(this.listadoPrincipal.length==0){
               this.mensajeError="No hay remitos para mostrar";
            }
            this.showSpinner=false;
            this.remitosServcice.grabarArticulosUsadosEnPedidosTango(this.listadoPrincipal,this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);
          })
          .catch(resultError=>{
            this.showSpinner=false;
            console.log('acomodaRemitos error',resultError);  

            this.alertService.confirm({ 
              title:   'Muchos remitos', 
              message:  'sobrepasó el limite de 100 remitos', 
           
            }).then(data=>{console.log('ok confirm',data);  });
            
            this.listadoPrincipal=resultError.datos;
            this.cuentaEntregados(this.listadoPrincipal);
            console.log(this.listadoPrincipal);  
            if(this.listadoPrincipal.length==0){
               this.mensajeError="No hay remitos para mostrar";
            }
  
            
            
          }) 

    },error =>{
      console.log(error);
      this.showSpinner=false;
      this.mensajeError=" buscarRemitos Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";
    });
 }


 buscarRemitosAdministrador(cantidadDeDias){
    console.log('buscarRemitosAdministrador');


 
    console.log('buscarRemitosAdministrador cantidadDeDias',cantidadDeDias);

    this.showSpinner=true;

   

    this.sql.getRemitoDetalladoTransportista(cantidadDeDias).subscribe(data=>
         {
          this.remitosServcice.acomodaRemitos(data)
          .then(remitosAcomodado=> {
   
            this.listadoPrincipal=remitosAcomodado;
            this.cuentaEntregados(this.listadoPrincipal);
            console.log(this.listadoPrincipal);  
            if(this.listadoPrincipal.length==0){
               this.mensajeError="No hay remitos para mostrar";
            }
            this.showSpinner=false;
            this.mdiSpin        = false;
            this.remitosServcice.grabarArticulosUsadosEnPedidosTango(this.listadoPrincipal,this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);
          })
          .catch(resultError=>{
            this.showSpinner=false;
            this.mdiSpin        = false;
            this.mensajeError=resultError.error;
            
            console.log('acomodaRemitos error',resultError);
            
            this.alertService.confirm({ 
              title:   'Muchos remitos', 
              message:  'sobrepasó el limite de '+this.remitosServcice.SAFE_LIMIT+'  remitos', 
           
            }).then(data=>{console.log('ok confirm',data);  });
            
            this.listadoPrincipal=resultError.datos;
            this.cuentaEntregados(this.listadoPrincipal);
            console.log(this.listadoPrincipal);  
            if(this.listadoPrincipal.length==0){
               this.mensajeError="No hay remitos para mostrar";
            }
     

            
          }) 


      
          },
          error =>{console.log(error);
              this.showSpinner=false;
              this.mdiSpin        = false;
              this.mensajeError=" buscarRemitos Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";

         }
   );
 }


 buscarRemitosCliente(codEmpresa,cantidadDeDias){
  console.log('buscarRemitosCliente');



  console.log('buscarRemitosCliente cantidadDeDias',cantidadDeDias);

  this.showSpinner    = true;
  this.mdiSpin        = false;

  this.sql.getRemitoDetallado(codEmpresa,cantidadDeDias).subscribe(data=>
       {
      console.log(data);
      // this.remitos=this.acomodaRemitos(data);

      this.remitosServcice.acomodaRemitos(data).then(  
            remitosAcomodado=> {
              // return remitosAcomodado;
              this.listadoPrincipal=remitosAcomodado;
            this.cuentaEntregados(this.listadoPrincipal);
            console.log(this.listadoPrincipal);  
             if(this.listadoPrincipal.length==0){
              this.mensajeError="No hay remitos para mostrar";
              }
              this.showSpinner=false;
              this.mdiSpin        = false;
              this.remitosServcice.grabarArticulosUsadosEnPedidosTango(this.listadoPrincipal,this.perfilUsuario.data.EmpresaSelected.COD_CLIENT);

            }).catch(
            error=>this.mensajeError=error) 

      
    
        },
        error =>{console.log(error);
            this.showSpinner=false;
            this.mdiSpin        = false;
            this.mensajeError=" buscarRemitos Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";

       }
 );
}

cuentaEntregados(listaPedidos){
  console.log('cuentaEntregados',listaPedidos);
  this.cantidadPedidosEntregados=0;
  for (var i = 0; i < listaPedidos.length; i++) {
    if(listaPedidos[i].ESTADO_ENTREGA_TRANSPORTISTA=='Entregado ')// ojo que lleva un espacio al final de entregados
      {
      this.cantidadPedidosEntregados=this.cantidadPedidosEntregados+1;
      console.log('cuentaEntregados',this.cantidadPedidosEntregados);
      }
  }
}
  
cambioFiltroEntrega(){
  console.log('this.filtro',this.filtro);
}

mostrarMapa(idInfotrak){
console.log('buscarEmpresa');

const modalRef =    this._modal.open(MapaComponent);
    modalRef.componentInstance.idInfotrak = idInfotrak;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.empresa: "+result.empresa);
            console.log("result.empresa: "+result.empresa.COD_CLIENT);
            console.log("result.cause: "+result.cause);
            if(result.empresa){

               let e={"COD_CLIENT": result.empresa.COD_CLIENT,
                   "RAZON_SOCI": result.empresa.RAZON_SOCI,
                   "NOM_COM": result.empresa.NOM_COM,
                   "CUIT": result.empresa.CUIT,
                   "LOCALIDAD": result.empresa.LOCALIDAD};

            }

          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );
           console.log('ss');

}
confirmarNotificarEntrega(entrega,i){
  this.confirmService.confirm({ title:'Confirmación de Entrega', message: 'Los productos ya fueron entregados?'})
  .then(result=>{
    console.log('result',result);
    if(result=='confirmed'){
      this.notificarEntrega(entrega,i)
    }
  })
  .catch(error=>{
    console.log('error',error);
  })
}

notificarEntrega(entrega,i){
  console.log(entrega,i);
  console.log(this.listadoPrincipal[i]);
  this.showSpinner=true;
  this.sql.setNotificacionesTransportista(entrega.COD_CLIENT,entrega.N_REMITO,"Entregado",this.perfilUsuario.key,this.perfilUsuario.data.email,'sinComentar').subscribe(data=>
         {
        console.log(data);

        this.listadoPrincipal[i]['ESTADO_ENTREGA_TRANSPORTISTA']="Entregado";
        this.getAlertasdelCliente(entrega);
         
          
          },
          error =>{console.log(error);
             this.mostrarMensajeModal("Transportista","Error al registar la entrega",error);
            console.log(error);
              this.showSpinner=false;
              this.mdiSpin        = false;
              this.mensajeError=" buscarRemitos Se produjo un error: " +error.name+" Mensaje: "+error.message+"/n";

         }
   );

  }

  getAlertasdelCliente(remito:Remito){
    console.log('getAlertasdelCliente',remito);
    this.alertasService.getAlertasCliente(remito.COD_CLIENT).subscribe((alertaListado:AlertaListado[])=>{
          console.log("alertaListado ", alertaListado);
          if(alertaListado.length==0){
            this.showSpinner=false
            this.mdiSpin    = false;
            console.log('getAlertasdelCliente. No hay entregas para actualizar',alertaListado);
            return;
          }

         // ELIMINO UNDEFINED PAR PODER GRABAR
         if(!remito.LEYENDA1){
            remito.LEYENDA1='';
         } 

         for (var i = 0; i < remito.detalle.length; i++) {
           if(!remito.detalle[i].LEYENDA1){
            remito.detalle[i].LEYENDA1='';
           }
         }
       
          
        for (var i = 0; i < remito.detalle.length; i++) {
             console.log("entrega.detalle[i].COD_ARTICU ", remito.detalle[i].COD_ARTICU);
             console.log("entrega.detalle[i].CANTIDAD_REMITO ", remito.detalle[i].CANTIDAD_REMITO);

            let listaAlertasActualizada: Alerta[]=[] 
            for (var j = 0; j < alertaListado.length; j++) {
              let alerta:Alerta=alertaListado[j].alerta;
              let articulo=alertaListado[j].articulo;
              console.log("alertaListado articulo", j, articulo);
              if(articulo.COD_ARTICU==remito.detalle[i].COD_ARTICU){
                   console.log("alertas.detalle[i].nombreAlerta ", alerta.nombreAlerta);
                   console.log("alertas.detalle[i].COD_ARTICU ", alerta.articulo.COD_ARTICU);
                   console.log("alertas.detalle[i].stockInicial ", alerta.stockInicial);
                   console.log("alertas.detalle[i].cantidadDeAnimales ", alerta.cantidadDeAnimales);
                   console.log("alertas.detalle[i].consumoDiario ", alerta.consumoDiario);
                   console.log("alertas.detalle[i].capacidadDeAlmacenamiento ", alerta.capacidadDeAlmacenamiento);
                   console.log("alertas.detalle[i].diasDeMargen ", alerta.diasDeMargen);
                   console.log("alertas.detalle[i].diaAviso ", alerta.diaAviso);
                   console.log("alertas.detalle[i].diaActuliazacionCarga ", alerta.diaActuliazacionCarga);
                   console.log("alertas.detalle[i].estado ", alerta.estado);

                   let diaActuliazacionCarga=alerta.diaActuliazacionCarga;
                   var fechacalculo = new Date(diaActuliazacionCarga.ano+'-'+diaActuliazacionCarga.mes+'-'+diaActuliazacionCarga.dia).getTime();

                   var hoy = new Date().getTime();
                 
                   var diff = Math.trunc((hoy - fechacalculo)/86400000);//1000*60*60*24

                    console.log('diff: ',diff );
                
                    let stokConsumido=(alerta.cantidadDeAnimales*alerta.consumoDiario*diff) ;
                    console.log('diff stokConsumido: ',stokConsumido);
                    let stockAlaFecha= alerta.stockDespuesUltimaCarga-stokConsumido;
                    if(stockAlaFecha<0){
                      stockAlaFecha=0;
                    }
                    console.log('stockAlaFecha: ',stockAlaFecha );

                    alerta.stockDespuesUltimaCarga=stockAlaFecha+ remito.detalle[i].CANTIDAD_REMITO;
                  
                    console.log('alertas[j].stockInicial: ',alerta.stockInicial );

                 
                    var diaHoy = new Date();
                    alerta.diaActuliazacionCarga= {dia:diaHoy.getDate(),mes:diaHoy.getMonth()+1,ano:diaHoy.getFullYear()};

                    var diaAviso = new Date();
                    let dias=  Math.trunc( (alerta.stockDespuesUltimaCarga/(alerta.cantidadDeAnimales*alerta.consumoDiario)) -  alerta.diasDeMargen );  
                    diaAviso.setDate( diaAviso.getDate() + dias );
                    alerta.diaAviso={dia:diaAviso.getDate(),mes:diaAviso.getMonth()+1,ano:diaAviso.getFullYear()};
                 
                    alerta['metodoAcutlaizastockDespuesUltimaCarga']='por remito';
                  

                    console.log("alertas.actualizacionStock ", alerta.actualizacionStock);
                    if(alerta.actualizacionStock){
                      console.log("alertas.detalle[i].actualizacionStock ","ingresa para el push");
                      alerta.actualizacionStock.push(remito);
                    }else{
                      alerta['actualizacionStock']=[remito];
                    }

                    
                  } 
                  
                listaAlertasActualizada.push(alerta);
              
              }  
              this.actualizarDatosAlerta(remito.COD_CLIENT,listaAlertasActualizada);
            }         

       },(error)=>{
         this.showSpinner=false;
         this.mdiSpin        = false;
         console.log("error",error);
         this.mensajeError="Error al leer Alertas: "+error;
       });
}


actualizarDatosAlerta(codCliente:string,listaAlerta:Alerta[]){
  console.log("alerta ", listaAlerta);

  this.alertasService.actualizarStocksEnAlerta(codCliente,listaAlerta).subscribe(
          (result)=>{console.log(result);
             this.mostrarMensajeModal("Transportista","Se registró corectamente la entrega","");
             this.showSpinner=false;
             this.mdiSpin        = false;
            this.getPerfil();
          },
          (error)=>{console.log(error)
            this.showSpinner=false;
            this.mdiSpin        = false;
            this.mensajeError="Error al actualizar Alertas:  "+error;
    
            }
          );

}



actulizarListado(){
  // this.listaPedidos=null;
  // this.showSpinner=true;
  // if(this.buscarEntregados&&this.buscarEnTransporte){
  //     this.getPedidosWeb(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT );
  // } else  if(this.buscarEntregados){
  //   this.getPedidosWebEstado(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT, 'Procesado' );
  //    console.log("buscar Procesado");
  // }else  if(this.buscarEnTransporte){
  //   this.getPedidosWebEstado(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT, 'creado' );
  //   console.log("buscar creado");
  // }

}

  mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}


  ngOnDestroy() {
        if (this.navigationSubscription) {  
            this.navigationSubscription.unsubscribe();
            }
    
  }



}
