
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 class="modal-title">{{titulo}}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                           <p>
                                <strong *ngIf="mensaje"> {{mensaje}} 
                                    <span class="text-primary" *ngIf="name">"{{name}}"</span>
                                </strong>.
                            </p>
                           
                           <p>
                                <span class="text-success" *ngIf="dato">"{{dato}}"</span>
                           </p>

                           <input [(ngModel)]="data" type="text">
                            <p>
                                <span class="text-danger" *ngIf="validacionMensaje!=''">"{{validacionMensaje}}"</span>
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Aceptar</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.dismiss('Cancel click')">Cancelar</button>
                        </div>

                    </div>
  <!--               </div>
            </div> -->

      