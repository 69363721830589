import { Component, OnInit,OnDestroy  } from '@angular/core';
import {Observable, Subscription }      from 'rxjs';

import { MensajesService }              from '../../services/mensajes/mensajes.service';
import { Router, NavigationEnd }        from "@angular/router";
import {SqlserviceService}              from '../../services/sql/sqlservice.service';

import {MapaComponent}                                  from '../../mapa/cliente/mapa.component';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {FiredatabaseService}                            from '@proyecto/servicios/firebase/firedatabase.service';
import {ModalInicialComponent}                          from '../../admin/users/setings/modal-inicial/modal-inicial.component';
import { ModalMensajeComponent }                        from '../../services/modal-mensaje/modal-mensaje.component';

import {GrillaGenerica}                 from '../../generico/grillaGenerica'
import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent extends GrillaGenerica implements OnInit,OnDestroy {
getProductosSuscription: Subscription;
perfilUsuario:any=null;
empresaSelected:any=null;
pedidoSelected:any=null;
remitoSelected:any=null;
listadoPrincipal:any[]=[];
navigationSubscription;
   mensajeSpinner = "mensaje spinner";
    showSpinner:boolean = false;
    mensaje:String = '';

    buscarEntregados:boolean=true;
    buscarEnTransporte:boolean=true;
    cantidadPedidosEntregados:number=0;
    editar:boolean=false;
  constructor(
            private mensageService:MensajesService,
            private router:Router,
            public  sql:SqlserviceService,
            private db: FiredatabaseService,
            private _modal: NgbModal) {  
  
            super();
  }

  ngOnInit() {
      console.log("pedidos");
      this.filtro['ACEPTA_ENTREGA_SIN_CLIENTE']=null;
      this.filtro['ESTADO_FILTRO']='';
      this.getPerfil();

this.navigationSubscription = this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     console.log(e);
     if (e instanceof NavigationEnd) {
       this.ngOnDestroy();
       this.ngOnInit();
       // this.router.navigated = false;
       // this.router.navigate([this.router.url]);
     }
   });

  }

getPerfil():void{
    console.log("pedido  get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
      console.log("pedido  perfil",perfil);
      this.perfilUsuario=perfil;
    
      if(this.perfilUsuario){
        console.log("pedido  this.perfilUsuario.data.fechaSolicitudDatatos",this.perfilUsuario.data.fechaSolicitudDatatos);
        if(this.perfilUsuario.data.fechaSolicitudDatatos!=null){
          let now= new Date();

          console.log("pedido  this.perfilUsuario.data.fechaSolicitudDatatos now",now.getTime());
          console.log("pedido  this.perfilUsuario.data.fechaSolicitudDatatos seconds ",this.perfilUsuario.data.fechaSolicitudDatatos.seconds);
          let diferencia=(now.getTime()/1000)-this.perfilUsuario.data.fechaSolicitudDatatos.seconds;
          console.log(' fechaSolicitudDatatos diferencia',diferencia);
                           // 86400 = 60 x 60 x 24         
          if(diferencia>86400){

            if(!this.perfilUsuario.data.settings.aceptaDescargaSinRepresentante && this.perfilUsuario.data.perfil=="cliente"){
                 const modalRef =    this._modal.open(ModalInicialComponent, { size: 'lg' });//sm,, lg , xl
                 modalRef.componentInstance.name = 'jj';
                 modalRef.result.then(result=>{
                  console.log("result: "+result);
                   if(result){      }            
              },reason=>{
                  console.log("rison: "+reason);
                   if (reason === ModalDismissReasons.ESC) {
                        return 'by pressing ESC';
                      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
                        return 'by clicking on a backdrop';
                      } else {
                        return  `with: ${reason}`;
                      }
                      } );
            }
        }

      }

       


      if(!this.perfilUsuario.data.EmpresaSelected ){
        this.mensaje="Seleccione un Cliente "
        this.mostrarMensajeModal('Lista de Pedidos', 'No hay perdidos para mostrar: '+"Seleccione un Cliente",'');
      } else {
        this.buscarPedidos(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT );
        this.empresaSelected=this.perfilUsuario.data.EmpresaSelected;
      }
    }

   }) ;
}
onChangeEmpresaSelected(){
  console.log('onChangeEmpresaSelected',JSON.stringify( this.empresaSelected));
  this.listadoPrincipal=[];
  this.buscarPedidos(this.empresaSelected.COD_CLIENT );
  this.perfilUsuario['data']['EmpresaSelected']=this.empresaSelected;
  this.mensageService.setPerfilUsuarioObs(this.perfilUsuario);
  this.mensageService.setEmpresaSelectedObs(this.empresaSelected);
  this.db.updateUserEmpresaSelected(this.perfilUsuario.key,this.empresaSelected);
}

compareEmpresas(c1: any, c2:any): boolean {     
  // console.log("compareFn c1", c1);
  // console.log("compareFn c2", c2);
    return c1 && c2 ? c1.COD_CLIENT === c2.COD_CLIENT : c1 === c2; 
}
mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}


buscarPedidos(codEmpresa){
    console.log('buscarPedidos');


    console.log('buscarPedidos this.empresaSelected', this.empresaSelected);
    console.log('buscarPedidos ventana',this.perfilUsuario.data.settings.ventanaVisualizacionPedido);

    this.showSpinner=true;
    this.mensaje="Buscando datos";
    // this.sql.getPedidos(codEmpresa,500).subscribe(data=>
    // this.sql.getPedidoDetallado(codEmpresa,500).subscribe(data=>
    this.sql.getPedidoDetallado(codEmpresa,this.perfilUsuario.data.settings.ventanaVisualizacionPedidos).subscribe(data=>
         {
        console.log(data);
        this.listadoPrincipal=this.acomodaPedidos(data);
        this.grabarArticulosUsadosEnPedidosTango(this.listadoPrincipal);
        this.cuentaEntregados(this.listadoPrincipal);
        console.log(this.listadoPrincipal);
        this.mensaje=null;
        if(this.listadoPrincipal.length==0){
          this.mensaje="No hay pedidos para mostrar";
        }

        this.showSpinner=false;
          },
          error =>{console.log(error);
              this.showSpinner=false;
              this.mensaje="Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";

         }
   );
 }

cuentaEntregados(listaPedidos){
  console.log('cuentaEntregados',listaPedidos);
  this.cantidadPedidosEntregados=0;
  for (var i = 0; i < listaPedidos.length; i++) {
    if(listaPedidos[i].ESTADO_ENTREGA_TRANSPORTISTA=='Entregado ')// ojo que lleva un espacio al final de entregados
      {
      this.cantidadPedidosEntregados=this.cantidadPedidosEntregados+1;
      console.log('cuentaEntregados',this.cantidadPedidosEntregados);
      }
  }
}
  

  acomodaPedidos(pedidos){
    console.log('acomodaPedidos pedidos',pedidos);

    let pedidoAcomodado=new Array();
    let indexPedidoAcomodado=0;
    let estadoFiltro:string=null;
    for( let i=0;i<pedidos.length;i++){
      console.log('acomodaPedidos pedidos i',i);
      console.log('acomodaPedidos pedidos pedidos i',pedidos[i]);
      console.log('acomodaPedidos pedidosProduccion',pedidos[i].Desc_Estado=='Aprobado' && pedidos[i].N_REMITO==' 0000000000000');

      estadoFiltro='';
      if(pedidos[i].Desc_Estado!='Aprobado' && pedidos[i].Desc_Estado!='Cumplido' ){
        console.log('acomodaPedidos estadoFiltro1');
        estadoFiltro='NoAprobado';
      } else if(pedidos[i].Desc_Estado=='Aprobado' && pedidos[i].N_REMITO==' 0000000000000' ){
        console.log('acomodaPedidos estadoFiltro2');
        estadoFiltro='Produccion';
      } else if(pedidos[i].Desc_Estado=='Aprobado' && !pedidos[i].ID_INFOTRAK ){
        console.log('acomodaPedidos estadoFiltro3');
        estadoFiltro='Transporte';   //'Transporte - sin IdInfotrack'
      } else if((pedidos[i].Desc_Estado=='Aprobado' || pedidos[i].Desc_Estado=='Cumplido') && pedidos[i].ID_INFOTRAK && !pedidos[i].ESTADO_ENTREGA_TRANSPORTISTA   ){
        console.log('acomodaPedidos estadoFiltro4');
        estadoFiltro='Transporte';   //'Transporte - con IdInfotrack'
      } else if((pedidos[i].Desc_Estado=='Aprobado' || pedidos[i].Desc_Estado=='Cumplido') && pedidos[i].ID_INFOTRAK && pedidos[i].ESTADO_ENTREGA_TRANSPORTISTA   ){
        console.log('acomodaPedidos estadoFiltro5');
        estadoFiltro='Entregado';
      }  

      console.log('acomodaPedidos estadoFiltro',estadoFiltro);
      let p={
        NRO_PEDIDO:+pedidos[i].NRO_PEDIDO,
        FECHA_PEDI:pedidos[i].FECHA_PEDI,
        ESTADO:pedidos[i].ESTADO,
        FECHA_ENTR:pedidos[i].FECHA_ENTR, 
        ID_INFOTRAK:pedidos[i].ID_INFOTRAK,
        FECHA_REMITO:pedidos[i].FECHA_REMITO,
        ESTADO_ENTREGA_TRANSPORTISTA:pedidos[i].ESTADO_ENTREGA_TRANSPORTISTA,
        ACEPTA_ENTREGA_SIN_CLIENTE:pedidos[i].ACEPTA_ENTREGA_SIN_CLIENTE,
        NOMBRE_TRA:pedidos[i].NOMBRE_TRA,
        ESTADO_FILTRO:estadoFiltro,
        Desc_Estado:pedidos[i].Desc_Estado,
        detalle:new Array(),
        IdPedido:pedidos[i].IdPedido
      };
      pedidoAcomodado.push(p);
      let idAProcesar=0;
      for( let j=0;j<pedidos.length-i;j++){ //la longitud que falta recorrer es el total menos i (es lo recorrido)
        console.log('acomodaPedidos pedidos j',j);
        idAProcesar=i+j;
        if(pedidoAcomodado[indexPedidoAcomodado]['NRO_PEDIDO']==pedidos[(idAProcesar)].NRO_PEDIDO){ //
          pedidoAcomodado[indexPedidoAcomodado]['detalle'].push(pedidos[(idAProcesar)]);
          console.log('acomodaPedidos encontro Iguales',pedidos[(idAProcesar)]);
          if(pedidos[(idAProcesar)].N_REMITO!=''){
            pedidoAcomodado[indexPedidoAcomodado]['N_REMITO']=pedidos[(idAProcesar)].N_REMITO
          }
          continue; //Sigo con el siguiente
        } else {
          console.log('acomodaPedidos diferentes',pedidos[(idAProcesar)]);
          console.log('acomodaPedidos  sale i+j',(i+j));
          console.log('acomodaPedidos  sale idAProcesar',(idAProcesar));
          indexPedidoAcomodado++;
          break; // esto lo puedo hacer porque el listado de pedidos viene ordenado por Nro pedido
      };
       // console.log('acomodaPedidos  termina el for -> llegó al final i+j',(i+j));
      // i=i+j; // Cuando termina el fo
    }
    
      console.log('acomodaPedidos  termina el for -> llegó al final i+j',(i));
      console.log('acomodaPedidos  termina el for -> llegó al final idAProcesar',(idAProcesar));
      console.log('acomodaPedidos  pedidos.length',(pedidos.length));
      console.log('acomodaPedidos  pedidos.length-1',(pedidos.length-1));

      if (idAProcesar==(pedidos.length-1)){ // llegó al fin del array
        console.log('acomodaPedidos  final final',(i));
        break;
      }

      i=idAProcesar-1;  // el for externo le suma uno al ingresar nuevamente.
    }
    console.log('acomodaPedidos pedidoAcomodado',pedidoAcomodado);
    return pedidoAcomodado;
  }

  cambiofiltro(){
    console.log(this.filtro);
  }

  setPedidoSelected(pedidoSelected){
    console.log(this.pedidoSelected);
    this.pedidoSelected=pedidoSelected;
    console.log(this.pedidoSelected);
    this.mensageService.setPedidoSelectedObs(pedidoSelected);
    this.router.navigate(['/remitosDetalles']);
  }

  clonarPedido(pedidoParaCopiar:any){

    console.log("pedido lista pedidoParaCopiar",pedidoParaCopiar);
    let pedido= {detalle:[],
              cabecera:{
                empresa:this.empresaSelected
              }};
    for(let i=0; i< pedidoParaCopiar.detalle.length; i++ ) {
         
         pedido.detalle.push(
           {articulo:{COD_ARTICU:pedidoParaCopiar.detalle[i].COD_ARTICU},
           cantidad:pedidoParaCopiar.detalle[i].CANT_PEDID,
           unidad:pedidoParaCopiar.detalle[i].ID_MEDIDA_STOCK
           });
    };

    console.log(pedido);
    this.mensageService.setPedidoWebSelectedObs(pedido);
    // this.ngZone.run(() => this.router.navigate(['/pedidoCrearDetalles']));
    // this.router.navigate(['/pedidoCrearDetalles']);
    this.editar=true;
  }

  nuevoPedido(){
    this.mensageService.setPedidoWebSelectedObs(null);
    this.router.navigate(['/pedidoCrearDetalles']);
  }

  grabarArticulosUsadosEnPedidosTango(pedidos){
    console.log("grabarArticulosUsadosEnPedidosTango pedidos TAngo",pedidos);
    let listaDeProductos:Articulo[]=[];
    let articulosNuevos:Articulo[]=[];
      // busco los productos ese cliente y los almaceno en lista de productos.
    this.getProductosSuscription= this.db.getProductos(this.empresaSelected.COD_CLIENT).subscribe(listaDeProductos=>{
        listaDeProductos=listaDeProductos;            
        console.log("grabarArticulosUsadosEnPedidosTango listaDeProductos Firebase Productos-Pedidos",listaDeProductos);
     
 
      const map = new Map();
      for(let i=0; i< pedidos.length; i++ ) {      // Listado que bajó de Tango

        let pedido=pedidos[i];
        
        // if(pedido.COD_CLIENT != this.perfilUsuario.data.EmpresaSelected.COD_CLIENT){
        //   continue;
        // }

        for(let k=0; k< pedido.detalle.length; k++ ) {       // recorro el detalle
          let detalle=pedido.detalle[k];
          if(!map.has(detalle.COD_ARTICU)){
            map.set(detalle.COD_ARTICU, true);    // set any value to Map
            let estagrabado=listaDeProductos.find(producto=>producto.COD_ARTICU==detalle.COD_ARTICU);
            console.log("grabarArticulosUsadosEnPedidosTango estagrabado1" ,estagrabado);
            if(!estagrabado){
              articulosNuevos.push(
                {COD_ARTICU: detalle.COD_ARTICU,
                  DESCRIPCIO: detalle.DESCRIPCIO,
                  DESC_ADIC: detalle.DESC_ADIC,
                  ID_MEDIDA_STOCK:detalle.ID_MEDIDA_STOCK});
              }
            }  
              
        } 
      }    
      console.log("grabarArticulosUsadosEnPedidosTango articulosNuevos",articulosNuevos);
      this.setProductosPedidos(articulosNuevos);
      });
  }

  setProductosPedidos(pedidoDetalle:Articulo[]){  // ToDo: esto es mejor almacenarlo desde una funci'on al leerlas novedades?
    pedidoDetalle.forEach((detalle) => {
    console.log("setProductosPedidos detalle",detalle);
    this.db.setProductos(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT,detalle.COD_ARTICU,detalle.DESCRIPCIO,detalle.DESC_ADIC,detalle.ID_MEDIDA_STOCK );

    });
  }


  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
         // this.subscriptionEmpresaSelected.unsubscribe();
    if(this.getProductosSuscription){
      this.getProductosSuscription.unsubscribe();
    }
   
    console.log(this.navigationSubscription);
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }


  mostrarMapa(idInfotrak){
    console.log('buscarEmpresa');
    const modalRef =    this._modal.open(MapaComponent,{size:'lg'});
    modalRef.componentInstance.idInfotrak = idInfotrak;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.empresa: "+result.empresa);
            console.log("result.empresa: "+result.empresa.COD_CLIENT);
            console.log("result.cause: "+result.cause);
            if(result.empresa){

               let e={"COD_CLIENT": result.empresa.COD_CLIENT,
                   "RAZON_SOCI": result.empresa.RAZON_SOCI,
                   "NOM_COM": result.empresa.NOM_COM,
                   "CUIT": result.empresa.CUIT,
                   "LOCALIDAD": result.empresa.LOCALIDAD};

               // if(this.listaEmpresas){
               //    this.listaEmpresas.push(e);
               //  }else{
               //     this.listaEmpresas=[e];
               //  }
            }
            // console.log("result.cause: "+result.cause);
            // console.log("result.date: "+result.date.year);
            // console.log("result.date: "+result.date.month);
            // console.log("result.date: "+result.date.day);
            // Cross click
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );
           console.log('ss');
  }

  enviarMail(){
    console.log('enviarMail');
    this.sql.sendMail("perez.juan.jose@gmail.com","titulo","<h2>este es el mensaje</h2>").subscribe(
       resp=>{console.log("resp",resp);},
      error=>{console.log("error",error);}
    );  
  }


  buscarEntregadosCambio(buscarEntregados){
    this.buscarEntregados=buscarEntregados;
    if(!this.buscarEnTransporte){
      this.buscarEnTransporte=true;
    }
    console.log(buscarEntregados);
    this.actulizarListado();
  }

  buscarEnTransporteCambio(buscarCreado){
    this.buscarEnTransporte=buscarCreado;
    if(!this.buscarEntregados){
      this.buscarEntregados=true;
    } 
    console.log(buscarCreado);
    this.actulizarListado();
  }

  actulizarListado(){
  // this.listaPedidos=null;
  // this.showSpinner=true;
  // if(this.buscarEntregados&&this.buscarEnTransporte){
  //     this.getPedidosWeb(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT );
  // } else  if(this.buscarEntregados){
  //   this.getPedidosWebEstado(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT, 'Procesado' );
  //    console.log("buscar Procesado");
  // }else  if(this.buscarEnTransporte){
  //   this.getPedidosWebEstado(this.perfilUsuario.data.EmpresaSelected.COD_CLIENT, 'creado' );
  //   console.log("buscar creado");
  // }

  }

  setEditar(editar:boolean){
    console.log('setEditar',editar);
    this.editar=editar; 
  }


}
