<div class="component alertas" *ngIf="editar==false">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Control de Alertas </h2>
            <select *ngIf="perfilUsuario?.data?.perfil=='cliente' || perfilUsuario?.data?.perfil=='distribuidor'"
                    class="form-control selectpicker float-right selectEmpresa" id="empresaSelected"
                    (change)="onChangeEmpresaSelected()"
                    [(ngModel)]="empresaSelected" 
                    [compareWith]="compareEmpresas">
                <option  [ngValue]=''>Empresa</option>
                <option  *ngFor="let empresa of perfilUsuario?.data.empresas" [ngValue]="empresa" >{{empresa?.RAZON_SOCI}}</option>
            </select>
          
        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">

            
                <div class="text-center"><div class="loading" *ngIf="showSpinner"></div></div>

           
            
                <div class="accordion" id="accordionExample">
                    <div >

                        <div class="top-inner-estructura clearfix" id="headingOne">
                            <h3  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                <b>Listado de Alertas:</b>
                               
                                
                                <p>

                                </p>
                            </h3>

                          
                            
                        </div>

                        <div id="collapseOne1" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="table-body" >
                                <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y highlight-opt">
                                    <thead>
                                        <tr  >

                                            <th (click)="setOrden('empresa.RAZON_SOCI')">
                                
                                                <span class="" *ngIf="ordenCampo!='empresa.RAZON_SOCI'">Empresa</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='empresa.RAZON_SOCI'">Empresa</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='empresa.RAZON_SOCI' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='empresa.RAZON_SOCI' && !ordenReverse" ></i>
                                            </th>

                                            <th (click)="setOrden('articulo.DESCRIPCIO')">
                                
                                                <span class="" *ngIf="ordenCampo!='articulo.DESCRIPCIO'">Producto</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='articulo.DESCRIPCIO'">Producto</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='articulo.DESCRIPCIO' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='articulo.DESCRIPCIO' && !ordenReverse" ></i>
                                            </th>
                                            
                                            <th  ngbTooltip="Fecha en la que se entregó" container="body"  (click)="setOrden('diaActulizacionCargaTxt')">
                                
                                                <span class="" *ngIf="ordenCampo!='diaActulizacionCargaTxt'">Fecha Recarga</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='diaActulizacionCargaTxt'">Fecha Recarga</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='diaActulizacionCargaTxt' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='diaActulizacionCargaTxt' && !ordenReverse" ></i>
                                            </th>
                                            
                                            <th ngbTooltip="Capacidad de almacenamiento en cliente" container="body" (click)="setOrden('capacidadDeAlmacenamiento')">
                                                <span class="" *ngIf="ordenCampo!='capacidadDeAlmacenamiento'">Capacidad</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='capacidadDeAlmacenamiento'">Capacidad</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='capacidadDeAlmacenamiento' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='capacidadDeAlmacenamiento' && !ordenReverse" ></i>
                                            </th>
                                            <th ngbTooltip="Stock Inicial (incluye las engregas)" container="body" (click)="setOrden('alerta.capacidadDeAlmacenamiento')">
                                                <span class="" *ngIf="ordenCampo!='alerta.capacidadDeAlmacenamiento'">Stock Inicial</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='alerta.capacidadDeAlmacenamiento'">Stock Inicial</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='alerta.capacidadDeAlmacenamiento' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='alerta.capacidadDeAlmacenamiento' && !ordenReverse" ></i>
                                            </th>

                                            <th ngbTooltip="Estimación de Stock en cliente" container="body" (click)="setOrden('alerta.stockDisponible')">
                                                <span class="" *ngIf="ordenCampo!='alerta.stockDisponible'">Stock diario</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='alerta.stockDisponible'">Stock diario</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='alerta.stockDisponible' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='alerta.stockDisponible' && !ordenReverse" ></i>
                                            </th>
                                                                               
                                            <th ngbTooltip="Cantidad de días con las que podemos sumplementar con el stock disponible en cliente" container="body" (click)="setOrden('diasDeAlimentoDisponible')">
                                                <span class="" *ngIf="ordenCampo!='diasDeAlimentoDisponible'">Días Disponibles</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='diasDeAlimentoDisponible'">Días Disponibles</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='diasDeAlimentoDisponible' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='diasDeAlimentoDisponible' && !ordenReverse" ></i>
                                            </th>
                                                                               
                                            <th ngbTooltip="cantidad de animales * consumo/animal" container="body" (click)="setOrden('stockDisponible')">
                                                <span class="" *ngIf="ordenCampo!='stockDisponible'">Consumo Diario</span>
                                                <span class="text-primary" *ngIf="ordenCampo=='stockDisponible'">Consumo Diario</span>
                                                <i class="mdi mdi-arrow-down text-primary" *ngIf="ordenCampo=='stockDisponible' && ordenReverse" ></i>
                                                <i class="mdi mdi-arrow-up text-primary"   *ngIf="ordenCampo=='stockDisponible' && !ordenReverse" ></i>
                                            </th>
                                                                               
                                            <th >
                                              Alarma
                                            </th>
                                            
                                        </tr>
                                    <tr>
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="empresa.RAZON_SOCI"  
                                                    [(ngModel)]="filtro['empresa.RAZON_SOCI']" 
                                                    placeholder="Empresa"/>
                                        </th>    
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="articulo.DESCRIPCIO"  
                                                    [(ngModel)]="filtro['articulo.DESCRIPCIO']" 
                                                    placeholder="Producto"/>
                                        </th>    
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="diaActulizacionCargaTxt"  
                                                    [(ngModel)]="filtro['diaActulizacionCargaTxt']" 
                                                    placeholder="Fecha"/>
                                        </th>   
                                        
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="capacidadDeAlmacenamiento"  
                                                    [(ngModel)]="filtro['alerta.capacidadDeAlmacenamiento']" 
                                                    placeholder="Capacidad"/>
                                        </th> 
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="stockDespuesUltimaCarga"  
                                                    [(ngModel)]="filtro['alerta.stockDespuesUltimaCarga']" 
                                                    placeholder="Stock"/>
                                        </th> 
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="stockDisponible"  
                                                    [(ngModel)]="filtro['stockDisponible']" 
                                                    placeholder="Disponible"/>
                                        </th> 
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="diasDeAlimentoDisponible"  
                                                    [(ngModel)]="filtro['diasDeAlimentoDisponible']" 
                                                    placeholder="Dias"/>
                                        </th> 
                                        <th>
                                            <input  class="form-control" 
                                                    type="text" id="consumoDiarioTotal"  
                                                    [(ngModel)]="filtro['consumoDiarioTotal']" 
                                                    placeholder="Consumo Diario"/>
                                        </th> 

                                        <th  class="text-center" >
                                            <span class="mdi mdi-24px mdi-broom"  title="Limpiar filtros" (click)="limpiarfiltros()"  ></span>
                                            <span class="text-primary ml-1 mdi mdi-24px mdi-plus" title="Nueva Alerta" (click)="nuevoPedido()"   ></span>
                                        </th>
                                    </tr>     
                                        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let documento of listadoPrincipal
                                        | orderBy :ordenCampo :ordenReverse    :'case-insensitive'  
                                        | searchFiltro:'local':'empresa.RAZON_SOCI'                     :filtro['empresa.RAZON_SOCI'] 
                                        | searchFiltro:'local':'articulo.DESCRIPCIO'                    :filtro['articulo.DESCRIPCIO'] 
                                        | searchFiltro:'local':'diaActulizacionCargaTxt'                :filtro['diaActulizacionCargaTxt'] 
                                        | searchFiltro:'local':'alerta.capacidadDeAlmacenamiento'              :filtro['alerta.capacidadDeAlmacenamiento'] 
                                        | searchFiltro:'local':'alerta.stockDespuesUltimaCarga'                :filtro['alerta.stockDespuesUltimaCarga'] 
                                        | searchFiltro:'local':'stockDisponible'                        :filtro['stockDisponible'] 
                                        | searchFiltro:'local':'diasDeAlimentoDisponible'               :filtro['diasDeAlimentoDisponible'] 
                                        | searchFiltro:'local':'consumoDiarioTotal'                     :filtro['consumoDiarioTotal'] 
                                        
                                        | paginate: { itemsPerPage: grilla.paginadoCantidad, 
                                            currentPage : pagePaginado,
                                            totalItems  : cantidadFiltro}; let cantidadFiltro=count ;
                                            
                                        let i=index    " >

                                            <!-- Razón Social -->
                                            <td  >
                                                {{documento?.empresa?.RAZON_SOCI}}
                                            </td >
                                            
                                            <!-- Producto -->
                                             <td >
                                                {{documento?.articulo?.DESCRIPCIO}} - {{documento?.articulo?.COD_ARTICU}}
                                                <!-- {{documento?.alerta?.articulo.COD_ARTICU}}-{{documento?.alerta?.articulo?.DESCRIPCIO}} -->
                                            </td >
                                            
                                            <!-- Fecha de recarga, entrega por transportista o creacion de la alerta -->
                                             <td  >
                                                {{documento?.alerta?.metodoAcutlaizastockDespuesUltimaCarga}}: {{documento.alerta?.diaActuliazacionCarga?.dia}}/{{documento?.alerta?.diaActuliazacionCarga?.mes}}/{{documento?.alerta?.diaActuliazacionCarga?.ano}}
                                            </td >
                                            
                                            <!-- Capacidad de Almacenamiento -->                                            
                                            <td  >
                                                {{documento?.alerta?.capacidadDeAlmacenamiento}}
                                            </td >

                                            <!-- Stock Inicial, Incluye el stoK entregado en la última entrega descontando el consumido -->                                                                                        
                                            <td  >
                                                {{documento?.alerta?.stockDespuesUltimaCarga}}
                                            </td >
                                            
                                            <!-- Stock a la fecha:? -->                                           
                                            <td  >
                                                {{documento?.stockDisponible}}
                                            </td >
                                            <!-- Días disponibles:? -->                                                                                       
                                             <td  >
                                                {{documento?.diasDeAlimentoDisponible}}
                                            </td >
                                            
                                            <!-- Consumo diario: Cantidad de animales * consumo por animal. -->                                                                                       
                                            <td  >
                                                {{documento?.consumoDiarioTotal}}
                                            </td >

      
                                            <th >
                                                <div class="infoAlertaContenedor text-center">
                                                    <div class=" icono ic-delete infoAlertaItem" title="Borrar"  (click)="borrarAlerta(documento)" > </div>
                                                    <div class=" icono ic-edit infoAlertaItem" title="Editar" (click)="editarAlerta(documento)"> </div>
                                                    <div class=" icono alarma infoAlertaItem" *ngIf="(documento.diasDeAlimentoDisponible <= documento.alerta.diasDeMargen)" > </div> 
                                                </div>
                                            </th>
                                     
                                        </tr>
                                        <!-- <tr *ngIf="mostrarDetalle[documento.N_REMITO]">
                                            <td colspan="8"  style="background-color: white;">
                                             
                                                <div class="tarjetaDetalle">
                                            
                                                
                                                    <div class="row tarjetaTitulo ">
                                                        <div class="col-2"></div>
                                                        <div class="col-5" style="font: bold;">PRODUCTO</div>
                                                        <div class="col-4" style="font: bold;">CANTIDAD REMITO</div>
                                                    </div>
                    
                                                    <div class="row tarjetaDetalleRow " *ngFor="let detalle of documento.detalle">
                                                        <div class="col-2"></div>
                                                        <div class="col-5">{{detalle?.DESCRIPCIO}}</div>
                                                        <div class="col-4">{{detalle?.CANTIDAD_REMITO}} {{detalle?.ID_MEDIDA_STOCK}}</div>
                                                    </div>
                                                </div>   
                                    
                                            </td>                            
                                        </tr> -->

                                    </tbody>
                                </table>
                                <div *ngIf="mensajeError" >{{mensajeError}}</div>
                                 <!-- nuevo Paginado -->
                                <div class="row" style="border-top:1px solid #ccc;" *ngIf="listadoPrincipal?.length>0">
                                    <div class="col-3" style="padding:10px;">
                                        <div class="bg-white text-center">
                                            <select class="form-control selectpicker" 
                                                    id="grilla.paginadoCantidad"                                         
                                                    [(ngModel)]="grilla.paginadoCantidad"
                                                    (change)="onChangeGrilla('paginadoCantidad',grilla.paginadoCantidad)"> 
                                                <ng-container *ngFor="let opcion of grilla.paginadoDropDown">
                                                    <option [ngValue]="opcion">Items x Página {{opcion}}</option>
                                                </ng-container>
                                            </select>
                                        </div>    
                                    </div>    
                                    <div class="col-9" style="padding:10px;">
                                        <div class="bg-white text-center">

                                
                                            <pagination-controls 
                                                autoHide="true" 
                                                previousLabel="{{'anterior' }}" 
                                                nextLabel="{{'siguiente'}}" 
                                                (pageChange)="pagePaginado = $event" 
                                                maxSize="15"></pagination-controls>

                                        </div>
                                    </div>                        
                                </div>
                            
               
               
               
               
               <!-- fin listado -->

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
<app-alertas-reposicion-crear *ngIf="editar==true" (setEditar)="setEditar($event)"
></app-alertas-reposicion-crear>
