<div class="component pedidos">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

         
            <h2>Monitor 

               
            </h2>

        </div>



        <div class="row inner-estructura">

            <div class="col-sm-12">

                
                <div class="text-center" > <div class="loading" *ngIf="showSpinner"> </div></div>

                <div *ngIf="mensaje" >{{mensaje}}</div>

                <!-- iniciolistado -->
                <!-- inicio listado -->
                
                <!--MAPA-->
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <agm-map  [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                            [zoomControl]="false"  [fullscreenControl]='true' [mapTypeControl]='true' id="map" >
                            
                            <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                                <ng-container *ngFor="let vehiculo of vehiculos;  let i=index">
                                    <agm-marker  [latitude]="vehiculo.latitud" [longitude]="vehiculo.longitud" [label]="label" [iconUrl] ="icono[i]" class="rot90"> 
                                    <agm-info-window [disableAutoPan]="false" [isOpen]="false">
                                        <strong>{{etiquetaMapa[i]}}</strong>
                                    </agm-info-window>
                                    </agm-marker>
                                </ng-container>   
                            </agm-marker-cluster>
                        </agm-map>
                    </div>
                    <style>.mapouter{position:relative;text-align:right;}.gmap_canvas {overflow:hidden;background:none!important;}</style>
                </div>           

                
                
                <!-- fin listado -->
                <!-- fin listado -->







            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>
