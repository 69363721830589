import { Component, OnInit,Input,ChangeDetectorRef, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireDatabaseModule, AngularFireDatabase  } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/firebase/auth.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import {EmpresaModalComponent} from '../empresa-modal/empresa-modal.component';
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';
import {SqlserviceService} from '../../../services/sql/sqlservice.service';
import { MensajesService }  from '../../../services/mensajes/mensajes.service';
import { User } from '../user';
import { LISTA_TRANSPORTES_INFOTRAK } from '../../../util/tablas/lista_transportes_infotrak';
import { TransporteInfotrak } from '../../../util/objetos/transportes_infotrak';

import { ModalMensajeComponent }  from '../../../services/modal-mensaje/modal-mensaje.component';
@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})

export class NuevoUsuarioComponent implements OnInit {

// copia los datos del usuario seleecionado en el formulario y copia
// la lista de empresas del usuario
// y key del usuario
  @Output() accion= new EventEmitter<string>();
  @Input() set userSelected  (userSelected: User ) {

    console.log('NuevoUsuarioComponent 1 = ',userSelected);
    this.mostrarValidacion=false;
    this.mensajeErrorEmpresa=null;
    this.crearLabelForm.patchValue( userSelected);
    this.crearLabelForm.value.empresa='';
     // this.crearLabelForm.setValue({'empresa':''});
    this.listaEmpresas=userSelected.empresas;
    this.key=userSelected.key;
    this.settings=userSelected.settings;
  };

crearLabelForm = this.fb.group({
  email: ['', [Validators.required,Validators.email]],
  perfil: ['', Validators.required],
  estado: ['', Validators.required],
  empresa: ['', ],
  infoTrak: ['', ],
    });

  datosEmpresas: Observable<any[]>;

  tiposEstado=[
              // {id:'pendiente',val:'Pendiente'},
              {id:'activo',val:'Activo'},
              {id:'suspendido',val:'Suspendido'} ];

  tiposPerfil=[];


ListaTransporteInfotrak:TransporteInfotrak[]=LISTA_TRANSPORTES_INFOTRAK;

listaEmpresas:any[]= [];

seleccionEmpresas:string[]=[];

key:string=null;
settings:string=null;
existeEmailenlaBase:boolean=false;
mostrarValidacion:boolean=false;
mensajeErrorEmpresa:string=null;
mensajeErrorInfoTrak:string=null;

    constructor(private db: FiredatabaseService,
                private fb: FormBuilder,
                 private _modal: NgbModal,
                 private cd: ChangeDetectorRef,
                public sql:SqlserviceService,
                private mensageService:MensajesService) {}



  ngOnInit() {
    this.getPerfil();
 

  }

getPerfil():void{
    console.log("usuarioNuevo  get Perfil");
    this.mensageService.getPerfil().subscribe(usuario=>{
      console.log("usuarioNuevo  usuario",usuario);
      switch (usuario.data.perfil) {
        case "administrador":
          this. tiposPerfil=[{id:'administrador',val:'Administrador'},
              {id:'gestorPedidos',val:'Gestor de Pedidos'},
              {id:'cliente',val:'Cliente'},
              {id:'distribuidor',val:'Distribuidor'},
              {id:'transportista',val:'Transportista'}
               ];
          break;

        default:
          // code...
          break;
      }


   }) ;
}



// updateUserData
// crea o modifica un usuario existente
// en caso de key=null crea uno nuevo, de lo contrario modifica el existente.

updateUserData(): void {
  if(this.validaciones()){
    console.log('infoTrak',this.crearLabelForm.value.infoTrak);
    var susc= this.db.validatUserMailExist(this.crearLabelForm.value.email).subscribe(existe=>{
      console.log('existeEmailenlaBase  validatUserMailExist existe',existe);
      console.log('existeEmailenlaBase  validatUserMailExist this.key',this.key);
      if(existe && this.key==null){
        console.log('existeEmailenlaBase  validatUserMailExist true existe');
        this.existeEmailenlaBase=true;
      } else{
        console.log('existeEmailenlaBase false validatUserMailExist no existe');
        this.existeEmailenlaBase=false;
        susc.unsubscribe();
        this.db.updateUserData(this.key,this.crearLabelForm.value.email,this.crearLabelForm.value.perfil,this.crearLabelForm.value.estado,this.crearLabelForm.value.infoTrak,this.listaEmpresas, this.settings );
        this.mostrarMensajeModal('Usuario','Se han grabado los datos correctamente','');
        this.accion.emit('graboOK');
      }

    });
  }else{
    //No pasamos las validaciones deben mostrarse
    
  }
}

 
       
 validaciones():boolean{
   let resultado:boolean=true;
   this.mostrarValidacion=false;
   this.mensajeErrorEmpresa=null;
   this.mensajeErrorInfoTrak=null;
   console.log(this.crearLabelForm.value.perfil);
   console.log(this.listaEmpresas);
   switch (this.crearLabelForm.value.perfil) {
        case "administrador":
          break;
        case "gestorPedidos":
          break;
        case "cliente":
             
             if(this.listaEmpresas&&this.listaEmpresas.length==0){
             

                resultado=false;

                this.mensajeErrorEmpresa="Un cliente debe tener una empresa asignada.";
              }
               if(!this.listaEmpresas){
             

                resultado=false;

                this.mensajeErrorEmpresa="Un cliente debe tener almenos una empresa asignada.";
              }
              // if(this.listaEmpresas&&this.listaEmpresas.length>1){
             

              //   resultado=false;

              //   this.mensajeErrorEmpresa="Un cliente solo puede tener una empresa asignada.";
              // }
            
              if(this.crearLabelForm.value.infoTrak!=''){
              resultado=false;
              this.mensajeErrorInfoTrak="Un cliente No puede tener asignado un tranportista.";
              }
           
          break;
        case "transportista":
          if(this.listaEmpresas!=null){
              resultado=false;
              this.mensajeErrorEmpresa="Un transportista No debe tener  empresas Asignadas.";
            }
          break;

        case "distribuidor":
          if(this.listaEmpresas==null){
              resultado=false;
              this.mensajeErrorEmpresa="Debe tener almenos una empresa asignada.";
            }
            if(this.crearLabelForm.value.infoTrak!=''){
              resultado=false;
              this.mensajeErrorInfoTrak="Un distribuidor No puede tener asignado un tranportista.";
            }
          break;
        

        default:
          resultado=false;
          this.mensajeErrorEmpresa="Un transportista No tiene empresas Asignadas."
          break;
      }
  console.log(this.mensajeErrorEmpresa);
  console.log(this.mensajeErrorInfoTrak);
 
  if (!resultado){ // hay errores
    this.mostrarValidacion=true;
  }
   if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }

  return resultado;     

 }

cambioPerfil(perfil){ // ante un cambio de perfil boora el listado de empresas y el campo infoTrak
                      // key queda igual por el la clave del usuario que estamo trabajando.  
  console.log('cambioPerfil',perfil);  
  let temp={
          email: this.crearLabelForm.value.email,
          perfil: this.crearLabelForm.value.perfil,
          estado: this.crearLabelForm.value.estado,
          empresa: '',
          infoTrak: '',    };
  this.crearLabelForm.patchValue( temp);
  this.listaEmpresas=null;
  this.mostrarValidacion=false;
  this.mensajeErrorEmpresa=null;
  this.mensajeErrorInfoTrak=null;
   if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
}



  agregarEmpresa(empresa){
    console.log('agregarEmpresa');
    console.log(this.crearLabelForm);

   
    console.log(this.crearLabelForm.value.empresa);
    this.seleccionEmpresas.push(this.crearLabelForm.value.empresa);
    // this.sql.test();
    this.sql.getEmpresas(this.crearLabelForm.value.empresa).subscribe(
          data => {
             
            if(this.listaEmpresas){
              this.listaEmpresas.push(data[0]);
            }else{
             this.listaEmpresas=data;
            }
         if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
        
        console.log(data);

          },
          error =>{
     console.log(error);
       if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
         }    
   );

  }

  borrarEmpresa(index){
    console.log('borrarEmpresa', index);
    this.listaEmpresas.splice(index, 1)

   console.log(this.listaEmpresas);
   

  }

borrarFormulario() {
  console.log("borrarFormulario");
  let temp={
          email: '',
          perfil: '',
          estado: '',
          empresa: '',
          infoTrak: '',    };
  this.crearLabelForm.patchValue( temp);

  this.listaEmpresas=null;
  this.key=null;
  this.accion.emit('limpiar');
  };

buscarEmpresa(){
  console.log('buscarEmpresa');
  this.mostrarValidacion=false;
  this.mensajeErrorEmpresa='';
  // if(this.listaEmpresas && this.listaEmpresas.length>0 && this.crearLabelForm.value.perfil=='cliente'){
     
  //         this.mostrarValidacion=true;
  //         this.mensajeErrorEmpresa="Un cliente solo puede tener una empresa asignada.";
  //         this.cd.detectChanges();
      
  // }else{
  const modalRef =    this._modal.open(EmpresaModalComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'jj';
    modalRef.componentInstance.empresa = this.crearLabelForm.value.empresa;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.empresa: "+result.empresa);
            console.log("result.empresa: "+result.empresa.COD_CLIENT);
            console.log("result.cause: "+result.cause);
            if(result.empresa){

               let e={"COD_CLIENT": result.empresa.COD_CLIENT,
                   "RAZON_SOCI": result.empresa.RAZON_SOCI,
                   "NOM_COM": result.empresa.NOM_COM,
                   "CUIT": result.empresa.CUIT,
                   "LOCALIDAD": result.empresa.LOCALIDAD};

               if(this.listaEmpresas){
                  this.listaEmpresas.push(e);
                }else{
                   this.listaEmpresas=[e];
                }
            }
            // console.log("result.cause: "+result.cause);
            // console.log("result.date: "+result.date.year);
            // console.log("result.date: "+result.date.month);
            // console.log("result.date: "+result.date.day);
            // Cross click
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );
  
      // } // if lista empresas y perfil cliente.
   }


   mostrarMensajeModal(titulo, mensaje, dato){
 console.log(titulo);
 console.log(mensaje);
 console.log(dato);
 const modalRef =    this._modal.open(ModalMensajeComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.dato = dato;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.cause: "+result.cause);
            console.log("result.date: "+result.date.year);
            console.log("result.date: "+result.date.month);
            console.log("result.date: "+result.date.day);
          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );

}

 }





