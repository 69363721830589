import { Component, OnInit,OnDestroy  } from '@angular/core';
import {Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/firebase/auth.service';
import { MensajesService }  from '../../services/mensajes/mensajes.service';
import {FiredatabaseService} from '@proyecto/servicios/firebase/firedatabase.service';
import { Router,NavigationEnd } from "@angular/router";
@Component({
  selector: 'app-clientes-list',
  templateUrl: './clientes-list.component.html',
  styleUrls: ['./clientes-list.component.css']
})
export class ClientesListComponent implements OnInit, OnDestroy  {

perfilUsuario:any=null;
empresaSelected:any=null;
mensajeError:string='';
navigationSubscription;

  constructor(
      private authService:AuthService,
      private db: FiredatabaseService,
      private mensageService:MensajesService,
      private router:Router
      ) { }

  ngOnInit() {
      console.log("clientes");
      this.perfilUsuario=null;
      this.empresaSelected=null;
      this.getPerfil();
      console.log('startCliente list');

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
      console.log(e);
         if (e instanceof NavigationEnd) {
         this.ngOnDestroy();
         this.ngOnInit();
       
     }
   });




  }

getPerfil():void{
    console.log("get Perfil");
    this.mensageService.getPerfil().subscribe(perfil=>{
      console.log(perfil);
      if(!perfil.data.empresas){
        this.mensajeError="no hay clientes para mostar";
      }
      if(perfil['data']['EmpresaSelected']) {
        this.empresaSelected=perfil['data']['EmpresaSelected'];
     }
    console.log(perfil);
    this.perfilUsuario=perfil;
   }) ;
}

setEmpresaSelected(empresaSelected){
     console.log(this.empresaSelected);
    this.empresaSelected=empresaSelected;
    console.log(this.perfilUsuario);

    this.perfilUsuario['data']['EmpresaSelected']=empresaSelected;
    this.mensageService.setPerfilUsuarioObs(this.perfilUsuario);


    this.mensageService.setEmpresaSelectedObs(empresaSelected);
    this.db.updateUserEmpresaSelected(this.perfilUsuario.key,this.empresaSelected);
    this.router.navigate(['/pedidos']);


}


ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
         // this.subscription.unsubscribe();


           // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    
    console.log(this.navigationSubscription);
    if (this.navigationSubscription) {  

       this.navigationSubscription.unsubscribe();
    }
  
    }

  

}

