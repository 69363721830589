import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';






//// Componentes Externos


import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule,IConfig } from 'ngx-mask' // mascaras para inputs  https://www.npmjs.com/package/ngx-mask
// https://github.com/angular/angularfire2

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';  //Firedatabase
import { AngularFirestoreModule   } from '@angular/fire/firestore/'; //Cloud Firestore
import { AngularFireMessagingModule } from '@angular/fire/messaging'; //Messaging

import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule,HttpClientXsrfModule } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

// Maps
import { AgmCoreModule } from '@agm/core'; //https://angular-maps.com/guides/getting-started/

import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer'; //https://nayan.co/blog/Web/angular-maps/


//// Servicios Internos
import { AuthService }         from './services/firebase/auth.service';

import {SqlserviceService}     from './services/sql/sqlservice.service';
import {FiredatabaseService}   from '@proyecto/servicios/firebase/firedatabase.service';
import {AlertasService}   from '@proyecto/servicios/firebase/alertas.service';
import {MensajesService}       from './services/mensajes/mensajes.service';
import {CloudMessagingService} from './services/cloud_messagin/cloud-messaging.service';
import {AutorizacionesService} from './services/autorizaciones/autorizaciones.service';

//// Componentes Internos

import { HeaderComponent }                from './header/header.component';
import { FooterComponent }                from './footer/footer.component';

import { ClientesListComponent }          from './clientes/clientes-list/clientes-list.component';
import { ClientesCrearComponent }         from './clientes/clientes-crear/clientes-crear.component';

import { LogMailComponent }               from './log/log-mail/log-mail.component';
import { LogGoogleComponent }             from './log/log-google/log-google.component';
import { RegistrarseComponent }           from './log/registrarse/registrarse.component';

import { ModalMensajeComponent }           from './services/modal-mensaje/modal-mensaje.component';
import { ModalSiNoComponent }              from './services/modal-si-no/modal-si-no.component';
import { ModalGetData }              from './services/modal-getData/modal-getData.component';
import { SpinnerComponent }                from './util/spinner/spinner.component';

import { NuevoUsuarioComponent }           from './admin/users/nuevo-usuario/nuevo-usuario.component';
import { UsuarioListComponent }            from './admin/users/usuario-list/usuario-list.component';
import { UsuarioModificarComponent }       from './admin/users/usuario-modificar/usuario-modificar.component';
import { SetingsComponent }                from './admin/users/setings/setings.component';
import { EmpresaModalComponent }           from './admin/users/empresa-modal/empresa-modal.component';
import { UsuarioNoautorizadoComponent }    from './admin/users/usuario-noautorizado/usuario-noautorizado.component';

import { PedidosComponent }                 from './pedidos/pedidos-listado/pedidos.component';



import { AvisosListComponent }              from './avisos/avisos-list/avisos-list.component';
import { AvisosNuevoComponent }             from './avisos/avisos-nuevo/avisos-nuevo.component';


import { PedidoCreadoDetalleComponent }      from './pedidos/pedido-creado-detalle/pedido-creado-detalle.component';

import { RemitosDetalleComponent }           from './remitos/remitos-detalle/remitos-detalle.component';

import { MapaComponent }                      from './mapa/cliente/mapa.component';
import { MapaMonitorComponent }                      from './mapa/monitor/mapa-monitor.component';
import { AlertasReposicionCrearComponent }    from './alertasReposicion/alertas-reposicion-crear/alertas-reposicion-crear.component';
import { AlertasReposicionControlComponent  } from './alertasReposicion/alertas-reposicion-control/alertas-reposicion-control.component';


import { NgbDateCustomParserFormatter}                 from './util/dateformat/dateformat';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

//Plantillas
import { LoginComponent }                     from './plantillas/login/login.component';
import { EstilosComponent }                   from './plantillas/estilos/estilos.component';
import { SolicituEmpresaComponent }           from './admin/users/solicitu-empresa/solicitu-empresa.component';
import { SolicitudEmpresaListadoComponent }   from './admin/users/solicitud-empresa-listado/solicitud-empresa-listado.component';
import { ModalInicialComponent }              from './admin/users/setings/modal-inicial/modal-inicial.component';
import { NgbdSortableHeader }                 from './directive/sortable.directive';
import { RemitoListadoTranportistaComponent } from './remitos/remito-listado-tranportista/remito-listado-tranportista.component';
import { PedidosGestorListadoComponent }      from './pedidos/pedidos-gestor-listado/pedidos-gestor-listado.component';
import { RemitoDetallePorIdComponent }        from './remitos/remito-detalle-por-id/remito-detalle-por-id.component';
import { EmpresaUsuarioPipe, EmpresaSeleccionUsuarioPipe,LengthPipe }        from './admin/users/pipe-empresa-usuario/empresa-usuario.pipe';


import { SharedModule }                       from '@maq-shared/shared.module';
import { ConfirmService, ConfirmState, ConfirmTemplateDirective, ConfirmModalComponent } from '@maq-servicios/confirm/confirm-service';
import { AlertService, AlertState, AlertTemplateDirective, AlertModalComponent } from '@maq-servicios/alert/alert-service';


export const options: Partial<IConfig> | (() => Partial<IConfig>)={};

@NgModule({
  declarations: [
    AppComponent,


    HeaderComponent,
    ClientesListComponent,
    ClientesCrearComponent,
    LogMailComponent,
    LogGoogleComponent,
    RegistrarseComponent,
    FooterComponent,
    NuevoUsuarioComponent,
    UsuarioListComponent,
    UsuarioModificarComponent,
    ModalMensajeComponent,
    ModalSiNoComponent,
    ModalGetData,

    PedidosComponent,
  
    SpinnerComponent,
    SetingsComponent,
    AvisosListComponent,
    AvisosNuevoComponent,
    UsuarioNoautorizadoComponent,
  
    PedidoCreadoDetalleComponent,
    
    EmpresaModalComponent,
    MapaComponent,
    MapaMonitorComponent,
    AlertasReposicionCrearComponent,
    RemitosDetalleComponent,

   

    //Plantillas
    LoginComponent,
    EstilosComponent,
    SolicituEmpresaComponent,
    SolicitudEmpresaListadoComponent,
    AlertasReposicionControlComponent,
    ModalInicialComponent,
    // ordenador
     NgbdSortableHeader,
    RemitoListadoTranportistaComponent,
    PedidosGestorListadoComponent,
    RemitoDetallePorIdComponent,

    // Pipe
    EmpresaUsuarioPipe,EmpresaSeleccionUsuarioPipe,LengthPipe,
  
  
    // componentes del servicio de confirmación
    ConfirmTemplateDirective, ConfirmModalComponent ,
    AlertTemplateDirective, AlertModalComponent ,

  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        // AIzaSyBYiQsn22HtELxjpNVvx2lddT1yeNQCsh4
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBYiQsn22HtELxjpNVvx2lddT1yeNQCsh4'
    }),
    AgmJsMarkerClustererModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
 	  NgxMaskModule.forRoot(options),
    SharedModule,
    NgxPaginationModule
  ],
    
    exports:[HeaderComponent, FooterComponent,EmpresaUsuarioPipe, EmpresaSeleccionUsuarioPipe ,LengthPipe],

 entryComponents: [
   ModalMensajeComponent, 
   ModalSiNoComponent,
   ModalGetData,
   EmpresaModalComponent,
   MapaComponent,
   MapaMonitorComponent,
   ModalInicialComponent
  ],


  providers: [
    AuthService,
    SqlserviceService,
    FiredatabaseService,
    MensajesService,
    CloudMessagingService,
    AutorizacionesService,
    
    ConfirmService,
    ConfirmState,
    AlertService,
    AlertState,
    AlertasService,
   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}],

  bootstrap: [AppComponent]
})
export class AppModule { }
