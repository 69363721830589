import { Injectable } from '@angular/core';
import {FiredatabaseService}            from '@proyecto/servicios/firebase/firedatabase.service';
import {Alertas, Alerta,Empresa,DiaMesAno,Articulo,AlertaListado } from '@proyecto-models/alertas/alertas'

@Injectable({
  providedIn: 'root'
}) 
export class RemitosServiceService {

  constructor(private db: FiredatabaseService,) { }

 SAFE_LIMIT=4000;

  acomodaRemitos(remitos:any):Promise<any>{
   console.log('acomodaRemitos: ',remitos);
   return new Promise((resolve,reject) => {
   let remitoAcomodado=new Array();
   let indexRemitoAcomodado=0;
   let safe=0;
   for( let i=0;i<remitos.length;i++){
    //  console.log('acomodaRemitos remitos i',i);
    //  console.log('acomodaRemitos remitos remitos[i]',remitos[i]);
     safe++;
     if(safe>this.SAFE_LIMIT){
    
      console.log('acomodaRemitos supero los 100')
    
         break;
     }
     let r={
       N_REMITO:remitos[i].N_REMITO,
       FECHA_REMITO:remitos[i].FECHA_MOV,
       FECHA_REMITO_PARAORDEN:this.diaMesAno_AnoMesDia(remitos[i].FECHA_MOV,'/'),
       ESTADO:remitos[i].ESTADO_MOV,
       ID_INFOTRAK:remitos[i].ID_INFOTRAK,
       ACEPTA_ENTREGA_SIN_CLIENTE:remitos[i].ACEPTA_ENTREGA_SIN_CLIENTE,
       LEYENDA1:remitos[i].LEYENDA1,
       // Desc_Estado:remitos[i].Desc_Estado,
       NOMBRE_TRA:remitos[i].NOMBRE_TRA,
       COD_CLIENT:remitos[i].COD_PRO_CL,
       RAZON_SOCI:remitos[i].RAZON_SOCI,
       PATENTE_CHASIS:remitos[i].PATENTE_CHASIS,
       ESTADO_ENTREGA_TRANSPORTISTA:remitos[i].ESTADO_ENTREGA_TRANSPORTISTA,
       ESTADO_FILTRO:remitos[i].ESTADO_ENTREGA_TRANSPORTISTA=="Entregado "?true:false,
       detalle:new Array()
     
 


     };
     let idAProcesar=0;
     remitoAcomodado.push(r);
        //  console.log('acomodaRemitos remitoAcomodado[indexRemitoAcomodado][N_REMITO]',remitoAcomodado[indexRemitoAcomodado]['N_REMITO']);
      let sonIguales:boolean=false;   
      for( let j=0;j<remitos.length-i;j++){ //la longitud que falta recorrer es el total menos i (es lo recorrido)
        idAProcesar=i+j;
        
        // console.log('acomodaRemitos pedidos j',j);
        // console.log('acomodaRemitos remitos[(i+j)].N_REMITO',remitos[(idAProcesar)].N_REMITO);

      if(remitoAcomodado[indexRemitoAcomodado]['N_REMITO']==remitos[(idAProcesar)].N_REMITO){
         remitoAcomodado[indexRemitoAcomodado]['detalle'].push(remitos[(idAProcesar)]);
         sonIguales=true;
          // console.log('acomodaRemitos encontro Iguales',remitos[(idAProcesar)]);
      } else {
        // console.log('acomodaRemitos  sale i+j',(idAProcesar));
        indexRemitoAcomodado++;
        sonIguales=false;
        // i=i+j-1;
        break; // esto lo puedo hacer porque el listado de pedidos viene ordenado por Nro 
      }
     }
    // console.log('acomodaPedidos idAProcesar',(idAProcesar));
    // console.log('acomodaPedidos sonIguales',(sonIguales));
     if (idAProcesar==(remitos.length-1) && sonIguales){ // llegó al fin del array
        //  console.log('acomodaPedidos  final final',(i));
        break;
      }
      i=idAProcesar-1;  // el for externo le suma uno al ingresar nuevamente.
     //  // para que no tome el último caso para por acá cuando agrega el último objeto y sale del for
     //  // no sale por el else porque ya no hay mas remitos en otros casos siempre hay un objeto para evaluar
     //  // y sale por el else porque se trata de distintos objetos.
     // if(i==remitos.length-1){
     //     break;
     // }

   }
  // console.log('acomodaRemitos pedidoAcomodado',remitoAcomodado);
  // console.log('acomodaRemitos safe',safe);
  
  if(safe>=this.SAFE_LIMIT){
      console.log('acomodaRemitos supero los 100')
     reject({error:'La cantidad de remitos es mas de '+this.SAFE_LIMIT,datos:remitoAcomodado});
      
  } else {
      resolve(remitoAcomodado);
  }
  
 
  
 }  

 ) 

}

  diaMesAno_AnoMesDia(date:string,delimiter:string){
    
      if(date=='' || date==null || date===undefined) {
        return date;
      }

      var dateItems=date.split(delimiter);
      let fechaOrden    : number=0;
      let multiplicador : number=1;
      for(let i=0;i<dateItems.length;i++) {
        // 0 dia
        // 1 mes   (100) meses van de 100 a 1200
        // año mes (*1000) 2021000
        fechaOrden+=+dateItems[i]*multiplicador;
        multiplicador=multiplicador*100;
      }
      
      return fechaOrden;
  }


  
  grabarArticulosUsadosEnPedidosTango(remitos, COD_CLIENT:string){
    console.log("grabarArticulosUsadosEnPedidosTango remitos TAngo",remitos);
    console.log("grabarArticulosUsadosEnPedidosTango this.perfilUsuario.data.EmpresaSelected.COD_CLIENT",COD_CLIENT);
  
     let listaDeProductos:Articulo[]=[];
     let articulosNuevos:Articulo[]=[];
       // busco los productos ese cliente y los almaceno en lista de productos.
      let getProductosSuscription= this.db.getProductos(COD_CLIENT).subscribe(listaDeProductos=>{
        console.log("grabarArticulosUsadosEnPedidosTango data",listaDeProductos);
        getProductosSuscription.unsubscribe();
        listaDeProductos=listaDeProductos;            
        console.log("grabarArticulosUsadosEnPedidosTango listaDeProductos Firebase Productos-Pedidos",listaDeProductos);
    
  
       const map = new Map();
       for(let i=0; i< remitos.length; i++ ) {      // Listado que bajó de Tango
         
         let remito=remitos[i];

         if(remito.COD_CLIENT !=COD_CLIENT){
           continue;
         }
 
         for(let k=0; k< remito.detalle.length; k++ ) {       // recorro el detalle
           let detalle=remito.detalle[k];
           if(!map.has(detalle.COD_ARTICU)){
            map.set(detalle.COD_ARTICU, true);    // set any value to Map
            let estagrabado=listaDeProductos.find(producto=>producto.COD_ARTICU==detalle.COD_ARTICU);
            console.log("grabarArticulosUsadosEnPedidosTango estagrabado1" ,estagrabado);
            if(!estagrabado){
              articulosNuevos.push({
                COD_ARTICU: detalle.COD_ARTICU,
                DESCRIPCIO: detalle.DESCRIPCIO,
                DESC_ADIC: detalle.DESC_ADIC,
                ID_MEDIDA_STOCK:detalle.ID_MEDIDA_STOCK});
              }
            }   
         } 
       }    
       console.log("grabarArticulosUsadosEnPedidosTango articulosNuevos",articulosNuevos);
       this.setProductosPedidos(articulosNuevos,COD_CLIENT);
       });
   
 
 }
 
 setProductosPedidos(pedidoDetalle:Articulo[],COD_CLIENT:string){  // ToDo: esto es mejor almacenarlo desde una funci'on al leerlas novedades?
     pedidoDetalle.forEach((detalle) => {
     console.log("setProductosPedidos detalle",detalle);
     this.db.setProductos(COD_CLIENT,detalle.COD_ARTICU,detalle.DESCRIPCIO,detalle.DESC_ADIC,detalle.ID_MEDIDA_STOCK );
 
     });
 }

}
