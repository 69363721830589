import { Component, OnInit,  Input} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
 selector: 'app-modal-getData',
  templateUrl: './modal-getData.component.html',
  styleUrls: ['./modal-getData.component.css']
})
export class ModalGetData implements OnInit {
 @Input() name;
 @Input() titulo;
 @Input() mensaje;
 @Input() dato;

 model;
 data:string='' ;
 validacionMensaje:string='';

 constructor(public modal: NgbActiveModal) {

 }
 
  ngOnInit() {
      console.log(this.name);
      console.log(this.titulo);
      console.log(this.mensaje);
      console.log(this.dato);

  }

  cerrarModal(){
    if(this.data==''){
      this.validacionMensaje="Ingrese un valor";
    } else{
      this.modal.close({cause:'SI',data:this.data});
    }
  }

}
