import { TransporteInfotrak } from '../objetos/transportes_infotrak';


// export const LISTA_TRANSPORTES_INFOTRAK: TransporteInfotrak[] = [
  
// {id_infotrak:1045  ,nombre:'Nutralmix-CSK608'  ,alias:'MADERO RICARDO - CSK608' ,patente:'CSK608',chofer:'Madero Ricardo',telefono:'231 751-9445'},
// {id_infotrak:1643  ,nombre:'Nutralmix-AA159IO' ,alias:'FLOTA PROPIA-AA159IO'    ,patente:'AA159IO',chofer:'Jose Gonzalez',telefono:'231 744-9822'},
// {id_infotrak:1702  ,nombre:'Nutralmix-EXU185'  ,alias:'PAGANI - EXU185'         ,patente:'EXU185',chofer:'Balda Martin',telefono:'231 755-9052'},
// {id_infotrak:2038  ,nombre:'Nutralmix-DJY113'  ,alias:'ARGÜERO - DJY113'        ,patente:'DJY113',chofer:'Valguarnera Luis',telefono:'231 745-2638'},
// {id_infotrak:2090  ,nombre:'Nutralmix-LBJ752'  ,alias:'MARENDA - LBJ752'        ,patente:'LBJ752',chofer:'Varela Cristian	',telefono:'234 650-9805'},
// {id_infotrak:2187  ,nombre:'Nutralmix-JOI304'  ,alias:'PAGANI - JOI304'         ,patente:'JOI304',chofer:'Bordone Daniel',telefono:'31 750-5490'},
// {id_infotrak:2226  ,nombre:'Nutralmix-EFD526'  ,alias:'MADERO - EFD526'         ,patente:'EFD526',chofer:'Nestor Madero',telefono:'231 740-7824'},
// {id_infotrak:2263  ,nombre:'Nutralmix-AA159IM' ,alias:'FLOTA PROPIA - AA159IM'  ,patente:'AA159IM',chofer:'Santiago Susseret',telefono:'231 757-6730'},
// {id_infotrak:2339  ,nombre:'Nutralmix-BEI069'  ,alias:'MADERO ABEL - BEI069'    ,patente:'BEI069',chofer:'Mardero Abel',telefono:'231 757-6012'},
// {id_infotrak:2446  ,nombre:'Nutralmix-UAB435'  ,alias:'MADERO NESTOR - UAB435'  ,patente:'UAB435',chofer:'Nutralmix-UAB435',telefono:'02317-430195'},
// {id_infotrak:2623  ,nombre:'Nutralmix-OJD064'  ,alias:'FLOTA PROPIA - OJD064'   ,patente:'OJD064',chofer:'David Esposito',telefono:'235 547-2751'},
// {id_infotrak:2780  ,nombre:'Nutralmix-ORF058'  ,alias:'FLOTA PROPIA - ORF058'   ,patente:'ORF058',chofer:'Mauro Acuña',telefono:'231 746-3803'},
// {id_infotrak:3967  ,nombre:'Nutralmix-AA159IL' ,alias:'FLOTA PROPIA - AA159IL'  ,patente:'AA159IL',chofer:'Ivan Seijo',telefono:'231 751-1452'},
// {id_infotrak:4567  ,nombre:'Nutralmix-LMQ916'  ,alias:'FLOTA PROPIA - LMQ916'   ,patente:'LMQ916',chofer:'Diego Cufre',telefono:'231 740-9101'},
// {id_infotrak:5146  ,nombre:'Nutralmix-LSH308'  ,alias:'Nutralmix-LSH308'        ,patente:'LSH308',chofer:'Nutralmix-LSH308',telefono:'02317-430195'}
// ];

export const LISTA_TRANSPORTES_INFOTRAK: TransporteInfotrak[] = [
  
    // {id_infotrak:1045  ,nombre:'Nutralmix-CSK608'  ,alias:'MADERO RICARDO - CSK608' ,patente:'CSK608',chofer:'Madero Ricardo',telefono:'231 751-9445'},
    // {id_infotrak:1200  ,nombre:'Nutralmix-AD912MJ'  ,alias:'Ford 9 - AD912MJ' ,patente:'AD912MJ',chofer:'Madero Ricardo',telefono:'231 746-3803'},
    			
    // {id_infotrak:1643  ,nombre:'Nutralmix-AA159IO' ,alias:'Ford 8 - AA159IO'    ,patente:'AA159IO',chofer:'Jose Gonzalez',telefono:'231 744-9822'},
    	

    // {id_infotrak:2090  ,nombre:'Nutralmix-LBJ752'  ,alias:'MARENDA - LBJ752'        ,patente:'LBJ752',chofer:'Jaume Santiago	',telefono:'2317-573357'},
    
    // {id_infotrak:2187  ,nombre:'Nutralmix-JOI304'  ,alias:'PAGANI - JOI304'         ,patente:'JOI304',chofer:'sin dato',telefono:'sin dato'},
  
    // {id_infotrak:2263  ,nombre:'Nutralmix-AA159IM' ,alias:'Ford 7 - AA159IM'  ,patente:'AA159IM',chofer:'Santiago Susseret',telefono:'231 757-6730'},
    // {id_infotrak:2339  ,nombre:'Nutralmix-BEI069'  ,alias:'MADERO ABEL - BEI069'    ,patente:'BEI069',chofer:'Mardero Abel',telefono:'231 757-6012'},
  
    	
    // {id_infotrak:2780  ,nombre:'Nutralmix-ORF058'  ,alias:'FLOTA PROPIA - ORF058'   ,patente:'ORF058',chofer:'David Esposito',telefono:'235 547-2751'},
    				
    // {id_infotrak:3700  ,nombre:'Nacini-GAW245'  ,alias:'Nacini-GAW245'   ,patente:'GAW245',chofer:'Spignolo Gillermo',telefono:'2392 553882'},
    // {id_infotrak:3967  ,nombre:'Nutralmix-AA159IL' ,alias:'ford 6 - AA159IL'  ,patente:'AA159IL',chofer:'Ivan Seijo',telefono:'231 751-1452'},
    // {id_infotrak:4567  ,nombre:'Nutralmix-LMQ916'  ,alias:'VW 1 - LMQ916'   ,patente:'LMQ916',chofer:'Diego Cufre',telefono:'231 740-9101'},
    


    {id_infotrak:3967 ,nombre:'DAVID ESPOSITO',                       alias:'DAVID ESPOSITO - AA159IL' ,                patente:'AA159IL' ,     chofer:'DAVID ESPOSITO', telefono: '2317 511452'},
    {id_infotrak:4567 ,nombre:'CUFRE DIEGO (VIEJO) NO USAR',          alias:'CUFRE DIEGO (VIEJO) NO USAR - LMQ 916' ,   patente:'LMQ 916' ,     chofer:'CUFRE DIEGO (VIEJO) NO USAR', telefono: '2317 40-9101'},
    {id_infotrak:2263 ,nombre:'SUSSERET SANTIAGO (NUTRALMIX S.R.L)',  alias:'SUSSERET SANTIAGO (NUTRALMIX S.R.L) - AA159IO' ,patente:'AA159IO' ,chofer:'SUSSERET SANTIAGO (NUTRALMIX S.R.L)', telefono: '2317 576730'},
    {id_infotrak:1200 ,nombre:'ACUÑA MAURO (NUTRALMIX SRL)',          alias:'ACUÑA MAURO (NUTRALMIX SRL) -  AD912MJ' ,  patente:' AD912MJ' ,    chofer:'ACUÑA MAURO (NUTRALMIX SRL)', telefono: '2317 463803'},
    {id_infotrak:2780 ,nombre:'PADULO NICOLAS',                       alias:'PADULO NICOLAS - OJD064' ,                 patente:'OJD064' ,      chofer:'PADULO NICOLAS', telefono: '2317 464488'},
    {id_infotrak:2339 ,nombre:'MADERO ABEL      BALANZIN ',           alias:'MADERO ABEL      BALANZIN  - BEI-069' ,    patente:'BEI-069' ,     chofer:'MADERO ABEL      BALANZIN ', telefono: '2317 576012'},
    {id_infotrak:2187 ,nombre:'JAUME SANTIAGO  (PAGANI MIGUEL)',      alias:'JAUME SANTIAGO  (PAGANI MIGUEL) - JOI-304',patente:'JOI-304' ,     chofer:'JAUME SANTIAGO  (PAGANI MIGUEL)', telefono: '2317-462998'},
    {id_infotrak:4958 ,nombre:'BALDA MARTIN   (PAGANI MIGUEL)',       alias:'BALDA MARTIN   (PAGANI MIGUEL) - EXU-185' ,patente:'EXU-185' ,     chofer:'BALDA MARTIN   (PAGANI MIGUEL)', telefono: '2317-15-487685'},
    {id_infotrak:2090 ,nombre:'VARELA CRISTIAN  (MARENDA ROBERTO)',   alias:'VARELA CRISTIAN  (MARENDA ROBERTO) - LBJ-752' ,patente:'LBJ-752' , chofer:'VARELA CRISTIAN  (MARENDA ROBERTO)', telefono: '2346 685107'},
    {id_infotrak:1643 ,nombre:'GONZALEZ JOSE  (NUTRALMIX S.R.L)',     alias:'GONZALEZ JOSE  (NUTRALMIX S.R.L) - AA-159 IO' ,patente:'AA-159 IO',chofer:'GONZALEZ JOSE  (NUTRALMIX S.R.L)', telefono: '2317 449822'},
    {id_infotrak:3700 ,nombre:'GUILLERMO SPINOLO (NACINI E.)',        alias:'GUILLERMO SPINOLO (NACINI E.) 20-27603051-6 - GAW245' ,patente:'GAW245' ,chofer:'GUILLERMO SPINOLO (NACINI E.) 20-27603051-6', telefono: ''},
    {id_infotrak:3150 ,nombre:'CUFRE DIEGO (NUTRALMIX S.R.L)',        alias:'CUFRE DIEGO (NUTRALMIX S.R.L) - AE889ME' , patente:'AE889ME' ,chofer:'CUFRE DIEGO (NUTRALMIX S.R.L)', telefono: '2317 40-9101'},
    {id_infotrak:5690 ,nombre:'SEIJO IVAN ',                          alias:'SEIJO IVAN  - AE889MF' ,                   patente:'AE889MF' ,chofer:'SEIJO IVAN', telefono: '2317 511452'},
        
    
    ];
    
