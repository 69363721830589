<div class="component avisos">

    <app-header></app-header>

    <div class="estructura">

        <div class="top-estructura">

            <h2>Avisos</h2>

        </div>

        <div class="row inner-estructura">

            <div class="col-sm-12">


                <div class="spinner-grow text-primary"></div>

                <div *ngIf="perfilUsuario">

                    <p >{{perfilUsuario.email  }} sss</p>

                    <h3 class="text-center" >perfilUsuario: {{perfilUsuario.email}}</h3>

                    <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y ">
                        <thead>
                            <tr >
                                <th>
                                    CÓDIGO
                                </th>
                                <th>
                                    RAZÓN SOCIAL
                                </th>
                                <th>
                                    LOCALIDAD
                                </th>
                                <th>
                                    CUIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let empresa of perfilUsuario.empresas  " (click)="setEmpresaSelected(empresa)">
                                <td>
                                    {{empresa.COD_CLIENT}}
                                </td >
                                <td>
                                    {{empresa.RAZON_SOCI}}
                                </td>
                                <td>
                                    {{empresa.LOCALIDAD}}
                                </td>
                                <td>
                                    {{empresa.CUIT}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

    </div></div></div>

    <app-footer></app-footer>

</div>
