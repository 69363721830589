<div class="container-fluid estilos">
    <div class="row">
        <div class="col-12">

            <!-- GENERAL -->
            <h1>H1. Lorem ipsum dolor amet</h1>

            <h2>H2. Lorem ipsum dolor sit amet</h2>

            <h3>H3. Lorem ipsum dolor sit amet</h3>

            <h4>H4. Lorem ipsum dolor sit amet</h4>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce egetorci vel turpis hendrerit pharetra. Nunc sit amet faucibus nunc.Quisque vel ultrices ipsum. Nullam vestibulum diam neque aliqueturna elementum ut. Sed nec arcu non magna feugiat </p>

            <p><a>Link link link</a></p>
            <!-- FIN de GENERAL -->

            <hr>

            <!-- BOTONES -->
            <h2>Botones</h2>

            <a href="#" class="btn btn-primary">Enviar</a>
            <a href="#" class="btn btn-secondary">Enviar</a>
            <a href="#" class="btn btn-default">Enviar</a>
            <a href="#" class="btn btn-link">Enviar</a>

            <br>

            <button class="btn btn-primary">Enviar</button>
            <button class="btn btn-secondary">Enviar</button>
            <button class="btn btn-default">Enviar</button>
            <button class="btn btn-link">Enviar</button>
            <!-- FIN de BOTONES -->

            <hr>

            <!-- FORMULARIO -->
            <h2>Formulario</h2>

            <div class="form-row">

                <div class="form-group col-md-6">
                    <label>E-mail</label>
                    <input type="email" class="form-control" id="exampleInputPassword1" formControlName="clave" autocomplete="off">
                </div>

                <div class="form-group col-md-6">
                    <label>Código de empresa</label>
                    <input type="email" class="form-control" id="exampleInputPassword1" formControlName="clave" autocomplete="off">
                </div>

                <div class="form-group col-md-6">
                    <label>Estado</label>
                    <select class="form-control">
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                    </select>
                </div>

                <div class="form-group col-md-6">
                    <label for="empresa">Código de Empresa</label>
                    <div class="form-control">
                        <input  type="text" class=""/>
                        <button class="btn btn-primary">Agregar</button>
                    </div>
                </div>

            </div>
            <!-- FIN de FORMULARIO -->

            <hr>

            <!-- ALERTAS -->
            <h2>Alertas</h2>

            <div class="alerta alerta-1"><b>Bien hecho!</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>

            <div class="alerta alerta-2"><b>Atención!</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>

            <div class="alerta alerta-3"><b>Tuvimos un problema!</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit.again.</div>
            <!-- FIN de ALERTAS -->

            <hr>

            <!-- LOADING -->
            <h2>Loading</h2>

            <div class="loading"></div>
            <!-- FIN de LOADING -->

            <hr>

            <!-- ETIQUETAS -->
            <h2>Etiquetas</h2>

            <span class="etiqueta">Creado</span>
            <br><br>
            <span class="etiqueta check">Aprobado</span>
            <br><br>
            <span class="etiqueta"><i class="icono ic-truck-2"></i><b> Texto 1</b></span>
            <br><br>
            <span class="etiqueta"><i class="icono ic-store"></i><b> Texto 2</b></span>
            <br><br>
            <span class="etiqueta"><i class="icono ic-truck-3"></i><b> Texto 3</b></span>
            <br><br>
            <span class="etiqueta black">mail@gmail.com</span>
            <!-- FIN de ETIQUETAS -->

            <hr>

            <!-- ICONOS -->
            <h2>Iconos</h2>

            <i class="icono ic-copy"></i>

            <i class="icono ic-delete"></i>

            <i class="icono ic-edit"></i>

            <i class="icono ic-trash"></i>

            <i class="icono ic-store"></i>

            <i class="icono ic-truck-1"></i>

            <i class="icono ic-truck-2"></i>

            <i class="icono ic-truck-3"></i>
            <!-- FIN de ICONOS -->

            <hr>

            <!-- TABLE -->
            <h2>Tabla</h2>

            <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y">
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Perfil
                        </th>
                        <th>
                            Estado
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            1
                        </td>
                        <td>
                            Texto
                        </td>
                        <td>
                            Texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                        </td>
                        <td>
                            Texto
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2
                        </td>
                        <td>
                            Texto
                        </td>
                        <td>
                            Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                        </td>
                        <td>
                            Texto
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3
                        </td>
                        <td>
                            Texto
                        </td>
                        <td>
                            Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                        </td>
                        <td>
                            Texto
                        </td>
                    </tr>
                    <tr>
                        <td>
                            4
                        </td>
                        <td>
                            Texto
                        </td>
                        <td>
                            Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                        </td>
                        <td>
                            Texto
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- FIN de TABLA -->

            <hr>

            <!-- ESTRUCTURA 1 -->
            <h2>Estructura Ej. 1</h2>

            <div class="estructura">

                <div class="top-estructura">

                    <h2>Usuarios</h2>

                    <span class="etiqueta black">mail@gmail.com</span>

                </div>

                <div class="row inner-estructura">

                    <div class="col-md-6">

                    </div>

                    <div class="col-md-6">

                    </div>

                </div>

            </div>
            <!-- FIN de ESTRUCTURA 1 -->

            <!-- ESTRUCTURA 2 -->
            <h2>Estructura Ej. 2</h2>

            <div class="estructura">

                <div class="top-estructura">

                    <h2>Clientes</h2>

                    <span class="etiqueta black">mail@gmail.com</span>

                </div>

                <div class="row inner-estructura">

                    <div class="col-sm-12">

                        <table class="table table-sm table-responsive table-striped table-wrapper-scroll-y">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Email
                                    </th>
                                    <th>
                                        Perfil
                                    </th>
                                    <th>
                                        Estado
                                    </th>
                                    <th>
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        1
                                    </td>
                                    <td>
                                        Texto
                                    </td>
                                    <td>
                                        Texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                                    </td>
                                    <td>
                                        <i class="icono ic-truck-1"></i>
                                    </td>
                                    <td class="acciones">
                                        <a href="#"><i class="icono ic-edit"></i></a>
                                        <a href="#"><i class="icono ic-copy"></i></a>
                                        <a href="#"><i class="icono ic-delete"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2
                                    </td>
                                    <td>
                                        Texto
                                    </td>
                                    <td>
                                        Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                                    </td>
                                    <td>
                                        <i class="icono ic-truck-2"></i>
                                    </td>
                                    <td class="acciones">
                                        <a href="#"><i class="icono ic-edit"></i></a>
                                        <a href="#"><i class="icono ic-copy"></i></a>
                                        <a href="#"><i class="icono ic-delete"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3
                                    </td>
                                    <td>
                                        Texto
                                    </td>
                                    <td>
                                        Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                                    </td>
                                    <td>
                                        <i class="icono ic-truck-3"></i>
                                    </td>
                                    <td class="acciones">
                                        <a href="#"><i class="icono ic-edit"></i></a>
                                        <a href="#"><i class="icono ic-copy"></i></a>
                                        <a href="#"><i class="icono ic-delete"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4
                                    </td>
                                    <td>
                                        Texto
                                    </td>
                                    <td>
                                        Texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto
                                    </td>
                                    <td>
                                        <i class="icono ic-store"></i>
                                    </td>
                                    <td class="acciones">
                                        <a href="#"><i class="icono ic-edit"></i></a>
                                        <a href="#"><i class="icono ic-copy"></i></a>
                                        <a href="#"><i class="icono ic-delete"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
            <!-- FIN de ESTRUCTURA 2 -->

            <hr>

            <!-- MODAL -->
            <h2>Modals</h2>

            <!--<div class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">-->
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 class="modal-title">Mapa</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body p-0">
                            <!--MAPA-->
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>
                                <style>.mapouter{position:relative;text-align:right;}.gmap_canvas {overflow:hidden;background:none!important;}</style>
                            </div>
                        </div>

                    </div>
            <!--</div>
            </div>-->

            <br><br>

            <!--<div class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">-->
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 class="modal-title">Lorem ipsum dolor</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce egetorci vel turpis hendrerit pharetra. Nunc sit amet faucibus nunc.Quisque vel ultrices ipsum. Nullam vestibulum diam neque aliqueturna elementum ut. Sed nec arcu non magna feugiat.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Aceptar</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                        </div>

                    </div>
            <!--</div>
            </div>-->

            <!-- FIN de MODAL -->

        </div>
    </div>
</div>
