import { environment }            from '@environments/environment';

/*
 consoleLog {
    header      - muestra header de componentes
    funciones   - muestra ejecución de funciones, disparo de promesas, y respuesta de promesas
    variables   - muestra variables
    
 } 
 */

// export const logComponentes = environment.consoleLog.header    && ['componente','funcionComponente'].indexOf(this[0])!=-1;
// export const logFunciones   = environment.consoleLog.funciones && ['funcionGo','funcionGoPromesa','funcionEnd','funcionBack','listener','LogApiFuncion'].indexOf(this[0])!=-1;
// export const logVariables   = environment.consoleLog.variables;

// export const logOn    = function(value){  
//   console.log("this",value)
//   //console.log("arguments[0]",arguments[0]);
//   return (arguments[0]==false) ? false : true; 
// }; 

export const logOn   = environment.consoleLog.header || environment.consoleLog.funciones || environment.consoleLog.variables;

export const log      = (!logOn)  ? function(){} : console.log.bind(this) ;
export const logIf    = (!logOn)  ? function(){} : console.log.bind(this) ;
export const logTable = (!logOn)  ? function(){} : console.table.bind(this) ;

export function values(estilo:any, 
       msg1?: any, msg2?: any, msg3?: any, msg4?: any, msg5?: any, 
       msg6?: any, msg7?: any, msg8?: any, msg9?: any, msg10?: any) {
         
    // if(['componente','funcionComponente'].indexOf(estilo)!=-1) {
    //     if(environment.consoleLog.header==false) return [false];
        
    // } else if(['funcionGo','funcionGoPromesa','funcionEnd','funcionBack','listener','LogApiFuncion'].indexOf(estilo)!=-1) {
    //     if(environment.consoleLog.funciones==false) return [false];
    
    // } else if(environment.consoleLog.variables==false) {
    //     return [false];
    // }    
  
    /*  
     * Aplica estilos al console.log();
     * estilo puede ser:
     * a) objeto con uno o más de los siguientes atributos:
          - color, background, fontWeight, textDecoration, fontSize, border, padding
          Ejemplo
          log(...values(({ color: 'blue',  fontSize:'14px'},  'mensaje de prueba'));
          log(...values(({ color: 'error', fontWeight: 'bold'},  'mensaje1', 'mensaje2'));

       b) estilo predefinido: titulo1, titulo2, titulo3, titulo4, funcion
          Ejemplo
          log(...values(('titulo1',  'mensaje de prueba'));

       c) estilo predefinido "variables"
          En caso de optarse por esta opción, hay que encerrar con corchetes las partes del texto a resaltar   
          log(...values(('titulo1',  'Este es el [valor] fundamental. Este [dato] también !!!'));
      */                 


    var styles = ['color','background','font-weight','text-decoration','font-size','border','padding'];
    styles['color']           = 'black';
    styles['background']      = 'hidden';
    styles['font-weight']     = 'none';
    styles['text-decoration'] = 'none';
    styles['font-style']      = 'none';
    styles['font-size']       = '12px';
    styles['border']          = 'hidden';
    styles['padding']         = '0px';

    var br='\n';
    var tab='   ';

    let estiloFinal = '';
    for (let i=0; i<styles.length;i++) {
        let propiedad = styles[i];
        let valor     = styles[propiedad];
        // console.log(propiedad,':',valor,);
        estiloFinal += propiedad + ':' + valor + ';';
    }

    let estiloFinalMensajesSecundarios = 'font-style: italic; border:1px solid; color:#888; padding-left:3px; padding-right:3px;';

    if(isArray(estilo)) {   // logTable(...values(vecEjemplo,'ListadoDueños'));    

       let listado                  = estilo;
       let nombreListado            = (msg1!==undefined) ? msg1 : null;
       let arrayColumnasAdicionales = (msg2!==undefined) ? msg2 : null;
       let rtaTable;
       if(arguments.length===1) rtaTable=consoleTable(listado);
       if(arguments.length===2) rtaTable=consoleTable(listado, nombreListado);
       if(arguments.length===3) rtaTable=consoleTable(listado, nombreListado, arrayColumnasAdicionales);

       return rtaTable;

    } else if(typeof estilo === 'boolean') {  // consola(...logIf(i==1,"i==1","Se muestra el Mensaje - Valor i:",i));
       let condicion     = estilo;
       let condicionText = msg1;
       let rtaIf;
       let cantidadMensajes = arguments.length -2;
       if(cantidadMensajes==1)  rtaIf = consoleIf(condicion, condicionText, arguments[2]);
       if(cantidadMensajes==2)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3]);
       if(cantidadMensajes==3)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4]);
       if(cantidadMensajes==4)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5]);
       if(cantidadMensajes==5)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5],arguments[6]);
       if(cantidadMensajes==6)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5],arguments[6],arguments[7]);
       if(cantidadMensajes==7)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5],arguments[6],arguments[7],arguments[8]);
       if(cantidadMensajes==8)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5],arguments[6],arguments[7],arguments[8],arguments[9]);
       if(cantidadMensajes==9)  rtaIf = consoleIf(condicion, condicionText, arguments[2],arguments[3],arguments[4],arguments[5],arguments[6],arguments[7],arguments[8],arguments[9],arguments[10]);

       return rtaIf;

    } else if(typeof estilo === 'string') {

        if(estilo=='imagen') {
            let etiqueta    = msg1;
            let imagen:any  = msg2;
            let bytes       = getBytesFromImage(imagen);
            let typeImage   = getTypeFromImage(imagen);
            let imagenCorta = getImagenCortaFromImage(imagen);

            let textoFormateado = emojiImagen + ' ['+etiqueta+'] ' + imagenCorta 
                + ' bytes: ['+bytes+']'
                + ' type: ['+typeImage+']';

            return values('variables', textoFormateado);

        } else if(estilo=='link') {
            let etiqueta    = msg1;
            let link        = msg2;

            let folder      = getFolderFromUrlFile(link, true);
            let fileName    = getFileNameSinVars(link);
            let token       = getVarsFromUrlFile(link);

            let textoFormateado = emojiLink + ' ['+etiqueta+'] ' + link + br
                + ' path: ['+folder+']'
                + ' archivo: ['+fileName+']'
                + ' token: ['+token+']';

            // return values('variables', textoFormateado);

            return values('icono', emojiLink, etiqueta, link+br, 'FOLDER: '+folder, 'FILENAME: '+fileName, 'TOKEN: '+token);

        }

        if(     estilo=='titulo1')            estiloFinal = 'font-size:20px; font-weight:bold;'
        else if(estilo=='titulo2')            estiloFinal = 'font-size:16px; font-weight:bold;'
        else if(estilo=='titulo3')            estiloFinal = 'font-size:14px; font-weight:bold;'
        else if(estilo=='titulo4')            estiloFinal = 'font-size:12px; font-weight:bold;'
        else if(estilo=='componente')         estiloFinal = 'font-size:18px; font-weight:bold; color:white;  background:black; padding:5px;';
        else if(estilo=='funcionComponente')  estiloFinal = 'font-size:14px; font-weight:bold; color:blue;';
        else if(estilo=='funcionGo')          estiloFinal = 'font-size:12px; font-weight:bold; color:green;';
        else if(estilo=='funcionGoPromesa')   estiloFinal = 'font-size:12px; font-weight:bold; color:green;';
        else if(estilo=='funcionEnd')         estiloFinal = 'font-size:12px; font-weight:bold; color:green;';
        else if(estilo=='funcionBack')        estiloFinal = 'font-size:12px; font-weight:bold; color:green;';
        else if(estilo=='listener')           estiloFinal = 'font-size:12px; font-weight:bold; color:#b2a518;';
        else if(estilo=='LogApiFuncion')      estiloFinal = 'font-size:12px; font-weight:bold; color:#d114bd;';            
        else if(estilo=='variables')          estiloFinal = 'font-weight:bold; color:#74640f;';
        else if(estilo=='valores')            estiloFinal = 'font-weight:bold; color:#74640f;';
        else if(estilo=='icono')              estiloFinal = 'font-weight:bold; color:#74640f;';
        else if(estilo=='valoresDebug')       estiloFinal = 'font-weight:bold; color:#d114bd;';
        
        else if(estilo=='sintaxis')           estiloFinal = 'color:gray;';
        else if(estilo=='comentarios')        estiloFinal = 'color:gray;';
        else if(estilo=='success')            estiloFinal = 'font-size:12px; font-weight:bold; color:green;';
        else if(estilo=='error')              estiloFinal = 'font-size:12px; font-weight:bold; color:red;';
        else if(estilo=='warning')            estiloFinal = 'font-size:12px; font-weight:bold; color:orange;';
        else if(estilo=='info')               estiloFinal = 'font-size:12px; font-weight:bold; color:cyan;';
        else if(estilo=='log')                estiloFinal = 'font-size:12px; font-weight:bold; color:black;';
        else if(estilo=='lineaBlanca')        estiloFinal = 'font-weight:bold; color:white;';
        else if(estilo=='lineaNegra')         estiloFinal = 'font-weight:bold; color:black;';
        else if(estilo=='lineaVariables')     estiloFinal = 'font-weight:bold; color:blue;';
        else                                  estiloFinal = 'color:inherit;';

    } else if(typeof estilo === 'object') {  

        // Asigno Color de Letra Especial
        if(estilo.color!==undefined && estilo.color!=null) {

             let color = estilo.color;
             color = color.toLowerCase();

             if(['green','verde','success'].indexOf(color)!=-1) {
               styles['color'] = 'green';

             } else if(['blue','azul','info'].indexOf(color)!=-1) {
               styles['color'] = 'glue';  

             } else if(['red','rojo','error'].indexOf(color)!=-1) {
               styles['color'] = 'red';  

             } else if(['orange','naranja','warning'].indexOf(color)!=-1) {
               styles['color'] = 'orange';  

             } else if(['yellow','amarillo'].indexOf(color)!=-1) {
               styles['color'] = 'yellow';  

             } else if(['magenta','rosa'].indexOf(color)!=-1) {
               styles['color'] = 'magenta';  

             } else if(['cyan','celeste'].indexOf(color)!=-1) {
               styles['color'] = 'cyan';  

             } else if(['gray','grey'].indexOf(color)!=-1) {
               styles['color'] = 'grey';  

             } else if(['white','blanco'].indexOf(color)!=-1) {
               styles['color'] = 'white';  

             } else if(['black','negro'].indexOf(color)!=-1) {
               styles['color'] = 'black';  

             } else if(['debug'].indexOf(color)!=-1) {
               styles['color'] = '#ccc';  

             } else if(['valores','variables'].indexOf(color)!=-1) {
               styles['color'] = '#74640f';  

             } else {
               styles['color'] = color;  

             }
        }   

        // Asigno Color de Fondo Especial
        if(estilo.background!==undefined && estilo.background!=null) {

             let background = estilo.background;
             background = background.toLowerCase();

             if(['green','verde','success'].indexOf(background)!=-1) {
               styles['background'] = 'green';

             } else if(['blue','azul','info'].indexOf(background)!=-1) {
               styles['background'] = 'blue';  

             } else if(['red','rojo','error'].indexOf(background)!=-1) {
               styles['background'] = 'red';  

             } else if(['orange','naranja','warning'].indexOf(background)!=-1) {
               styles['background'] = 'orange';  

             } else if(['yellow','amarillo'].indexOf(background)!=-1) {
               styles['background'] = 'yellow';  

             } else if(['magenta','rosa'].indexOf(background)!=-1) {
               styles['background'] = 'magenta';  

             } else if(['cyan','celeste'].indexOf(background)!=-1) {
               styles['background'] = 'cyan';  

             } else if(['gray','grey'].indexOf(background)!=-1) {
               styles['background'] = 'grey';  

             } else if(['white','blanco'].indexOf(background)!=-1) {
               styles['background'] = 'white';  

             } else if(['black','negro'].indexOf(background)!=-1) {
               styles['background'] = 'black';  

             } else if(['debug'].indexOf(background)!=-1) {
               styles['background'] = '#ccc';  

             } else {
               styles['background'] = background;  

             }
        }   

        // Asigno Tamaño de Letra
        if(estilo.fontSize!==undefined && estilo.fontSize!=null) {
             styles['font-size'] = estilo.fontSize;
        }     

        // Asigno Negrita
        if(estilo.fontWeight!==undefined && estilo.fontWeight!=null) {
             styles['font-weight'] = estilo.fontWeight;
        }     

        // Asigno Subrayado
        if(estilo.textDecoration!==undefined && estilo.textDecoration!=null) {
             styles['text-decoration'] = estilo.textDecoration;
        }     

        // Asigno Italica / Cursiva
        if(estilo.fontStyle!==undefined && estilo.fontStyle!=null) {
             styles['font-style'] = estilo.fontStyle;
        }     

        // Asigno Padding
        if(estilo.padding!==undefined && estilo.padding!=null) {
             styles['padding'] = estilo.padding;
        }     

        // Asigno Borde
        if(estilo.border!==undefined && estilo.border!=null) {
             styles['border'] = estilo.border;
        }     

        for (let i=0; i<styles.length;i++) {
           let propiedad = styles[i];
           let valor     = styles[propiedad];
           // console.log(propiedad,':',valor,);
           estiloFinal += propiedad + ':' + valor + ';';
        }

    }   

    let mensajesCombinados  = '';
    let valoresPorSeparado  = [];
    let cssRules            = [];

    if(estilo=='<br>') {
        return [br];

    } else if(['lineaBlanca','lineaNegra','lineaVariables'].indexOf(estilo)!=-1) {
        mensajesCombinados = '%c-----------------------------------------';
        cssRules.push(estiloFinal);

    } else if(estilo=='variables') {
        mensajesCombinados += tab;
        for(let i=1; i< arguments.length; i++) {
           let valor=arguments [i];
           mensajesCombinados += `${valor} `;
        }    
        mensajesCombinados = mensajesCombinados.substr( 0, mensajesCombinados.length-1);

        var styledText = '';                 
        var splitText = mensajesCombinados.split(' ');
        for (var split of splitText)  {
              if (/^\[/.test(split)) {
              // if (/^\{/.test(split)) {  
                  cssRules.push(estiloFinal);
              } else {
                  cssRules.push('color:inherit')
              }
              let splitSinCorchetes = split.replace('[','');
              splitSinCorchetes = splitSinCorchetes.replace(']','');
              styledText += `%c${splitSinCorchetes} `
        }  
        styledText = styledText.substr( 0, styledText.length-1);
        mensajesCombinados = styledText;

    } else if(estilo=='valores') {

        for(let i=1; i< arguments.length; i++) {
           let valor=arguments[i];
           // if(valor.substr( (valor.length-1),1 )==':') {
           if(typeof valor==='string' && valor.substr( (valor.length-1),1 )==':') {
              valor = tab + valor.substr(0,valor.length-1);
              mensajesCombinados += `%c${valor} `;
              cssRules.push(estiloFinal);
           } else {
              valoresPorSeparado.push(br);
              valoresPorSeparado.push(tab + emojiAsterico);
              
              valoresPorSeparado.push(valor);  
           }
        }    
        mensajesCombinados = mensajesCombinados.substr( 0, mensajesCombinados.length-1);

    } else if(estilo=='icono') {

        let emojiIcono = arguments[1];
        arguments[2]=emojiIcono + ' '+arguments[2];
        for(let i=2; i< arguments.length; i++) {
           let valor=arguments[i];
           // if(valor.substr( (valor.length-1),1 )==':') {
           if(typeof valor==='string' && valor.substr( (valor.length-1),1 )==':') {
              valor = tab + valor.substr(0,valor.length-1);
              mensajesCombinados += `%c${valor} `;
              cssRules.push(estiloFinal);
           } else {
              valoresPorSeparado.push(br);
              valoresPorSeparado.push(tab + emojiAsterico);
              
              valoresPorSeparado.push(valor);  
           }
        }    
        mensajesCombinados = mensajesCombinados.substr( 0, mensajesCombinados.length-1);

    } else if(estilo=='valoresDebug') {

        for(let i=1; i< arguments.length; i++) {
           let valor=arguments[i];
           // if(valor.substr( (valor.length-1),1 )==':') {
           if(typeof valor==='string' && valor.substr( (valor.length-1),1 )==':') {
              valor = tab + valor.substr(0,valor.length-1);
              mensajesCombinados += `%c${valor} `;
              cssRules.push(estiloFinal);
           } else {
              valoresPorSeparado.push(br);
              valoresPorSeparado.push(tab + emojiOjos);
              
              valoresPorSeparado.push(valor);  
           }
        }    
        mensajesCombinados = mensajesCombinados.substr( 0, mensajesCombinados.length-1);

    }  else {

        for(let i=1; i< arguments.length; i++) {

             if(estilo=='funcionComponente' || estilo=='LogApiFuncion'  || 
                estilo=='funcionGoPromesa' || estilo=='funcionGo' || 
                estilo=='funcionEnd' || estilo=='funcionBack' || estilo=='listener') {
               if(i==1) {
                 cssRules.push(estiloFinal);  
               } else {
                 cssRules.push(estiloFinal+estiloFinalMensajesSecundarios);
               }
             } else {                   
               cssRules.push(estiloFinal);
             } 

             if(['sintaxis','comentarios'].indexOf(estilo)!=-1) {
                msg1 = '# '+ msg1;
             }  

             if(estilo=='funcionComponente')  msg1= emojiCuadradoRelleno+' '+msg1;
             if(estilo=='funcionGo')          msg1= tab +emojiFlechaDerechaRellena+' '+msg1;
             if(estilo=='funcionGoPromesa')   msg1= tab +emojiReloj+' '+msg1;
             if(estilo=='funcionEnd')         msg1= tab +emojiCheck+' '+msg1;
             if(estilo=='funcionBack')        msg1= tab +emojiFlechaIzquierda+' '+msg1;
             if(estilo=='listener')           msg1= tab +emojiMegafono+' '+msg1;
             if(estilo=='LogApiFuncion')      msg1= tab +emojiFlechaDerechaRellena+' '+msg1;

             // cssRules.push(estiloFinal);
             if(styles['border']=='hidden') {
                 if(i==1) mensajesCombinados += `%c${msg1},`;
                 if(i==2) mensajesCombinados += `%c${msg2},`;
                 if(i==3) mensajesCombinados += `%c${msg3},`;
                 if(i==4) mensajesCombinados += `%c${msg4},`;
                 if(i==5) mensajesCombinados += `%c${msg5},`;
                 if(i==6) mensajesCombinados += `%c${msg6},`;
             } else {
                 if(i==1) mensajesCombinados += `%c${msg1} `;
                 if(i==2) mensajesCombinados += `%c${msg2} `;
                 if(i==3) mensajesCombinados += `%c${msg3} `;
                 if(i==4) mensajesCombinados += `%c${msg4} `;
                 if(i==5) mensajesCombinados += `%c${msg5} `;
                 if(i==6) mensajesCombinados += `%c${msg6} `;
             }
        }    
        mensajesCombinados = mensajesCombinados.substr( 0, mensajesCombinados.length-1);

    }    

    if(estilo=='valores'|| estilo=='valoresDebug' || estilo=='icono'  || estilo=='imagen') {
        return [mensajesCombinados, ...cssRules, ...valoresPorSeparado];
    } else {
        return [mensajesCombinados, ...cssRules];
    }  

}

export function consoleTable(listado:any, nombreListado?:string, arrayColumnasAdicionales?: any) {
    /* console.table(obj) con mejoras */

    let columnas=[];

    if(listado.length==0) {  // Si viene el array vacío, no hago nada
      return [listado, columnas];  
    }

    let listado2=listado;

    if(nombreListado===undefined) nombreListado='';

    if(nombreListado.includes('Organizaciones')) {
        columnas.push('autoIncrement');
    }  

    // Asigno Columna Nombre, algunos datos de settings y de corresponder organizacionKNAI
    if(nombreListado.includes('listado')) {

       columnas.push('key');

       // Parche para arrays con nombre listado* que no tienen key 
       if(listado[0].key!==undefined) {
          for (let i=0; i<listado.length;i++) {
            listado[i].obj  = null;
            listado2[i].obj = null;
          }  
       }      
     
       if(listado[0].nombre!==undefined) {
          columnas.push('nombre');
       } if(listado[0].datosPersonales!==undefined && listado[0].datosPersonales.nombre!==undefined) {
          columnas.push('apellido','nombre');
       } else if(listado[0].field!==undefined) {  
          columnas.push('field');
       } else if(listado[0].title!==undefined) {  
          columnas.push('title');
       } else if(listado[0].etiqueta!==undefined) {  
          columnas.push('etiqueta');
       } else if(listado[0].descripcion!==undefined) {  
          columnas.push('descripcion');
       }   

       if(listado[0].organizacionKNAI!==undefined) {
            columnas.push('organizacionKNAI.key');
            for (let i=0; i<listado.length;i++) {
              let obj= listado[i];
              for(var propiedad in obj){
                   if(propiedad=='organizacionKNAI') {
                     if(obj['organizacionKNAI']!=null && obj['organizacionKNAI']['key']!==undefined) {
                        listado2[i]['organizacionKNAI.key'] = obj['organizacionKNAI']['key'];  
                     } else {
                        listado2[i]['organizacionKNAI.key'] = null;
                     }
                   }
              }               
           }
       }  

       // Agrego Columnas Adicionales
       if(arrayColumnasAdicionales===undefined) arrayColumnasAdicionales=[];
       for(let i=0; i<arrayColumnasAdicionales.length; i++) {

           let nameField=arrayColumnasAdicionales[i];  // direccion.calle

           if(nameField.includes('.')) {
             let field1Nivel = nameField.split('.')[0];
             let field2Nivel = nameField.split('.')[1];
             let fieldFinal  = field2Nivel+'('+field1Nivel+')';

             columnas.push( fieldFinal ) ;

           } else {
             columnas.push( nameField );
           } 

       }

       // Agrego Campos de Settings
      //  if(listado[0].settings!==undefined) {
      //      columnas.push('documentosAsociados(settings)');
      //      columnas.push('sizeOfDocumento(settings)');

      //      for (let i=0; i<listado.length;i++) {
      //         let obj= listado[i];
      //         for(var propiedad in obj){
      //              if(propiedad=='settings') {
      //                listado2[i]['documentosAsociados(settings)'] = obj['settings'].documentosAsociados;
      //                listado2[i]['sizeOfDocumento(settings)'] = obj['settings'].sizeOfDocumento;
      //              }

      //              } if(listado[i].datosPersonales!==undefined && listado[i].datosPersonales.nombre!==undefined) {
      //                  listado2[i]['apellido'] = obj['datosPersonales'].apellido;
      //                  listado2[i]['nombre']   = obj['datosPersonales'].nombre;
      //              }               

      //              for(let z=0; z<arrayColumnasAdicionales.length; z++) {
      //                  let nameField=arrayColumnasAdicionales[z];  // direccion.calle
      //                  if(nameField.includes('.')) {
      //                    let field1Nivel = nameField.split('.')[0];
      //                    let field2Nivel = nameField.split('.')[1];
      //                    let fieldFinal  = field2Nivel+'('+field1Nivel+')';
      //                    listado2[i][fieldFinal] = obj[field1Nivel][field2Nivel];
      //                  }  
      //              }
      //      }
      //  }  

    }

    return [listado2, columnas];

}    

export function consoleIf(condicion: boolean, condicionText:string, 
          msg1?: any, msg2?: any, msg3?: any, msg4?: any, msg5?: any,
          msg6?: any, msg7?: any, msg8?: any, msg9?: any)  {
    /* Muestra el/los mensaje/s sólo si se cumple una condición */

    let texto; 
    let estilo;
    if(condicion) {
      estilo = "success";
      texto  = "Se cumple: "+condicionText;
    } else {
      estilo = "warning";
      texto  = "No se cumple: "+condicionText;      
    }  

    let cantidadMensajes = arguments.length -2;
    if(cantidadMensajes>=1) {
        texto  += "\n";  
    }
    
    if(cantidadMensajes==0) return values(estilo, texto);
    if(cantidadMensajes==1) return values(estilo, texto, msg1);
    if(cantidadMensajes==2) return values(estilo, texto, msg1, msg2);
    if(cantidadMensajes==3) return values(estilo, texto, msg1, msg2, msg3);
    if(cantidadMensajes==4) return values(estilo, texto, msg1, msg2, msg3, msg4);
    if(cantidadMensajes==5) return values(estilo, texto, msg1, msg2, msg3, msg4, msg5);
    if(cantidadMensajes==6) return values(estilo, texto, msg1, msg2, msg3, msg4, msg5, msg6);
    if(cantidadMensajes==7) return values(estilo, texto, msg1, msg2, msg3, msg4, msg5, msg6, msg7);
    if(cantidadMensajes==8) return values(estilo, texto, msg1, msg2, msg3, msg4, msg5, msg6, msg7, msg8);
    if(cantidadMensajes==9) return values(estilo, texto, msg1, msg2, msg3, msg4, msg5, msg6, msg7, msg8, msg9);
}    

/* --------------------- Emojis -------------------------------------
 * Listado.: https://www.w3schools.com/charsets/ref_emoji.asp
 * Buscador: https://www.emojicopy.com/
 * Otras...: https://unicode.org/emoji/charts/full-emoji-list.html
 */

export const emojiQuestion               = "❓";  // "&#10067;";
export const emojiEsclamacion            = "❗";  // "&#10071;";
export const emojiRestringido            = "⛔";  // "&#9940;"; 
export const emojiWarning                = "⚠";  // "&#9888;"; 
export const emojiWarningAltoVotaje      = "⚡";  // "&#9889;"; 

export const emojiFlechaDerechaHueca     = "⇨";   // "&#8680;";
export const emojiFlechaDerechaRellena   = "➜";   // "&#10140;";
export const emojiFlechaDerechaSymbol    = "➤";   // "&#10148;";
export const emojiFlechaDerechaBox       = "➡️"; 
export const emojiFlechaIzquierda        = "⬅";  // "&11013"
export const emojiFlechaIzquierdaHueca   = "⇦";   // "&#8678;";
export const emojiFlechaEnter            = "↳";   // "&#8627;";
export const emojiFlechaTripleRight      = "⋙";  // "&#8921;";

export const emojiCuadradoRelleno        = "■";   // "&#9632;";
export const emojiCuadradoHueco          = "□";   // "&#25A1;";
export const emojiCuadradoBordesRedondos = "▢";  // "&#9634;";
export const emojiTrianguloRelleno       = "▶";  // "&#9654;";
export const emojiTrianguloHueco         = "▷";  // "&#9655;";
export const emojiBulletCirculoBorde     = "◉";  // "&#9673;";
export const emojiCirculoRelleno         = "●";   // "&#9679;";
export const emojiCirculoRellenoGrande   = "🔴"; // "&#128308;";
export const emojiCirculoHuecoGrande     = "⚪"; // "&#9898;";
export const emojiBandera                = "⚑";   // "&#9873;";
export const emojiAsterico               = "✱";  // "&#10033;";
export const emojiEstrellaRellanaFondo   = "✪";  // "&#10026;";
export const emojiEstrellaRellana        = "★";  // "&#9733;";
export const emojiEstrellaHueca          = "☆";  // "&#9734;";
export const emojiCheckBorde             = "☑"; // "&#9745;";
export const emojiCheck                  = "✔"; // "&#9745;";
export const emojiTachado                = "❌"; // "&#10060;";
export const emojiCorazonHueco           = "❤"; // "&#10084;";
export const emojiCorazonRelleno         = "💙"; // "&#128153;";
export const emojiOjos                   = "👀"; // "&#128064;";
export const emojiPisadas                = "🐾"; // "&#128062;";
export const emojiBeso                   = "💋"; // "&#128139;";
export const emojiMensajeCuadrado        = "💬"; // "&#128172;";
export const emojiMensajeCuadreado2      = "🗨"; // "&#128488;";
export const emojiMensajeRedondo         = "💭"; // "&#128173;";
export const emojiTrianguloTopRight      = "◥";  // "&#9701;";
export const emojiPelota                 = "⚽"; // "&#9917;";
export const emojiFarmacia               = "⛨";  // "&#9960;";
export const emojiEmail                  = "✉";  // "&#9993;";
export const emojiAvion                  = "✈";  // "&#9992;";
export const emojiSignoMas               = "✚";  // "&#10010;";
export const emojiTrebol                 = "✤";  // "&#10020;";
export const emojiRombo                  = "✦";  // "&#10022;";
export const emojiPesos                  = "💲";  // "&#128178;";
export const emojiFolder                 = "📂"; // "&#128194;";
export const emojiArchivo                = "📄"; // "&#128196;";
export const emojiTelefono               = "📞"; // "&#128222;";
export const emojiMegafono               = "📢"; // "&#128226;";
export const emojiUpload                 = "📤"; // "&#128228;";
export const emojiDownload               = "📥"; // "&#128229;";
export const emojiCampana                = "🔔"; // "&#128276;";
export const emojiReloj                  = "🕑"; // "&#128337;";
export const emojiComillas               = "❞";   // "&#10175;";
export const emojiImagen                 = "🖼️"; // "&#9999999;";
export const emojiLink                   = "🔗"; // "&#9999999;";

export const emojiNro1                   = "➊"; // "&#10122;";
export const emojiNro2                   = "➋"; // "&#10123;";
export const emojiNro3                   = "➌"; // "&#10124;";
export const emojiNro4                   = "➍"; // "&#10125;";
export const emojiNro5                   = "➎"; // "&#10126;";
export const emojiNro6                   = "➏"; // "&#10127;";
export const emojiNro7                   = "➐"; // "&#10128;";
export const emojiNro8                   = "➑"; // "&#10129;";
export const emojiNro9                   = "➒"; // "&#10130;";
export const emojiNro10                  = "➓"; // "&#10131;";

export const emojiFackou                 = "🖕";  // "&#128405;";
export const emojiManoArriba             = "👍";  // "&#128077;";
export const emojiManoAbajo              = "👎";  // "&#128078;";
export const emojiManoIzquierda          = "👈";  // "&#128072;";
export const emojiManoDerecha            = "👉"; // "&#128073;";

export const emojiAlegre             = "🙂";  // "&#128512;";
export const emojiNegativo           = "🙁";  // "&#128577;";
export const emojiDivertido          = "😁";  // "&#128513;";
export const emojiTriste             = "😥";  // "&#128549;";
export const emojiEnojado            = "😡";  // "&#128545;";
export const emojiAsustado           = "😦";  // "&#128550;";
export const emojiPicaro             = "😉";  // "&#128521;";
export const emojiDiablo             = "😈";  // "&#128520;";
export const emojiAngel              = "👼";  // "&#128124;";
export const emojiPirata             = "😎";  // "&#128526;";


// funciones de fn.funciones que no importo desde ahí porque me da error de dependencia circular
export function isArray(obj){
   return !!obj && obj.constructor === Array;
}    

export function getTypeFromFile(file:File):string {
    log(...values("valoresDebug","funcion:","getTypeFromFile"));
    log(...values("valoresDebug","file:",file));
    log(...values("valoresDebug","file[0]:",file[0]));
    log(...values("valoresDebug","type:",file.type));
    let rta='error';
    
    if(file==null) return rta;

    // if(file[type]===undefined)
    // rta=file[type] 
    // if(file.includes('image/jpg'))  rta='image/jpg'; 
    // if(file.includes('image/jpeg')) rta='image/jpeg';   // No se si existe
    // if(file.includes('image/gif'))  rta='image/gif'; 

    // log(...values("valoresDebug","rta:",rta));

    return rta;
}      

export function getImagenCortaFromImage(image:any):string {
    // log(...values("valoresDebug","funcion:","getImagenCortaFromImage"));

    let rta=null;
    if(isFile(image)) {  // File
        if(image.name!==undefined) rta = image.name;

    } else {  // blob
        rta = image.substr(0,80)+'...';
    }
    // log(...values("valoresDebug","rta:",rta));

    return rta;
}

export function getBytesFromImage(image:any):string {
    // log(...values("valoresDebug","funcion:","getBytesFromImage"));

    let rta=null;
    if(isFile(image)) {  // File
        if(image.size!==undefined) rta = image.size.toString();

    } else {  // blob
        rta = image.length.toString();
    }
    // log(...values("valoresDebug","rta:",rta));

    return rta;
}

export function getTypeFromImage(image:any):string {
    // log(...values("valoresDebug","funcion:","getTypeFromImage"));

    let rta=null;

    if(image==null) return rta;

    if(isFile(image)) {  // File
        if(image.type!==undefined) rta = image.type;

    } else {            // Blob  
        image=image.toLowerCase();

        if(image.includes('image/png'))  rta='image/png'; 
        if(image.includes('image/jpg'))  rta='image/jpg'; 
        if(image.includes('image/jpeg')) rta='image/jpeg';   // No se si existe
        if(image.includes('image/gif'))  rta='image/gif'; 
    }    
    // log(...values("valoresDebug","rta:",rta));

    return rta;
}      

export function getFileNameFromArchivo(archivo:any):string {
    // log(...values('valoresDebug','getFileNameFromArchivo archivo:',archivo));

    let rta=null;

    if(archivo==null) return null;

    if(isFile(archivo)) {  // File
        if(archivo.name!==undefined) rta = archivo.name;

    } else {            // Blob  
        archivo = decodeURIComponent(archivo);
        rta = archivo.substring(archivo.lastIndexOf('/')+1);      
    }    
    // log(...values('valoresDebug','getFileNameFromUrlFile fileName:',fileName));
    return rta;
}

export function getFolderFromUrlFile(urlFile:string, ignoreLeft:boolean):string {
    // log(...values('valoresDebug','getFolderFromUrlFile urlFile:',urlFile));
    if(urlFile==null) return null;
    urlFile = decodeURIComponent(urlFile);
    let fileName = getFileNameFromArchivo(urlFile);
    let pos = urlFile.indexOf(fileName);
    let folder = urlFile.substr(0, pos);
    if(ignoreLeft) {
        folder = folder.replace('https://firebasestorage.googleapis.com/v0/b/formulasnutralmix.appspot.com/o/','');          
    }
    // log(...values('valoresDebug','getFolderFromUrlFile folder:',folder));
    return folder;
}

export function getVarsFromUrlFile(urlFile:string):string {
    // log(...values('valoresDebug','getVarsFromUrlFile urlFile:',urlFile));
    if(urlFile==null) return null;
    let fileName = getFileNameFromArchivo(urlFile);
    let pos = fileName.indexOf('?');
    let vars = fileName.substr(pos, 1000);
    // log(...values('valoresDebug','getFolderFromUrlFile vars:',vars));
    return vars;
}

export function getFileNameSinVars(urlFile:string):string {
    // log(...values('valoresDebug','getFileNameSinVars urlFile:',urlFile));
    if(urlFile==null) return null;
    let fileName = getFileNameFromArchivo(urlFile);
    let pos = fileName.indexOf('?');
    let fileNamesinVars = fileName.substr(0, pos);
    // log(...values('valoresDebug','getFileNameSinVars fileNamesinVars:',fileNamesinVars));
    return fileNamesinVars;
}

export function isFile(file:any) {
  // log(...values('valoresDebug','isFile urlFile:',file));
  // log(...values('valoresDebug','rta:',file && file['name']!==undefined && file['type']!==undefined && file['size']!==undefined));
  if(file && file['name']!==undefined && file['type']!==undefined && file['size']!==undefined) {
    return true;
  } else {
    return false;
  } 
}





