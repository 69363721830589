import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';

import { Observable,  Subscription } from 'rxjs';

import { NgbActiveModal, NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {MapaComponent} from '../../mapa/cliente/mapa.component';

import { AuthService } from '../../services/firebase/auth.service';
import { MensajesService }  from '../../services/mensajes/mensajes.service';

import {SqlserviceService} from '../../services/sql/sqlservice.service';
import {SpinnerComponent} from '../../util/spinner/spinner.component';
import {RemitosServiceService} from'../../util/remitos/remitos-service.service'
@Component({
  selector: 'app-remito-detalle-por-id',
  templateUrl: './remito-detalle-por-id.component.html',
  styleUrls: ['./remito-detalle-por-id.component.css']
})

// se utiliza en un link de los mails.
export class RemitoDetallePorIdComponent implements OnInit {
	 subscriptionPedidoSelected: Subscription;
    perfilUsuario:any=null;
    empresaSelected:any=null;
    pedidoSelected:any=null;
    remitos:any=null;
    vehiculos:any=null;
    mensajeSpinner = "mensaje spinner";
    showSpinner:boolean = false;
    mensajeError:String = '';


  constructor( private route: ActivatedRoute,
    private router: Router,
    private authService:AuthService,
    private mensageService:MensajesService,
    private _modal: NgbModal,
    public sql:SqlserviceService,
     private remitosServcice:RemitosServiceService
    ) { }

  ngOnInit() {
  	   // this.heroes$ = this.route.paramMap.pipe(
  	   console.log('onInit');
  	   console.log('onInit',this.route.snapshot);
  	   console.log('onInit',this.route.snapshot.params);
  	   let remito=this.route.snapshot.params.remito;
  	   this.buscarRemitoDetalle(remito);
  	
  }





buscarRemitoDetalle(remito){
    console.log('buscarRemitoDetalle');
    console.log('buscarRemitoDetalle remito', remito);


    this.showSpinner=true;


    this.sql.getRemitoXNumero(remito).subscribe(data=>

    	 
         {
    	if(data.length==0){
    		  this.showSpinner=false;
  			this.mensajeError=" No hay datos para mostrar.";
    	}else {

          this.remitosServcice.acomodaRemitos(data).then(  
              remitosAcomodado=> {
                // return remitosAcomodado;
                this.remitos=remitosAcomodado;
              
              console.log(this.remitos);  
               if(this.remitos.length==0){
                this.mensajeError="No hay remitos para mostrar";
                }
                this.showSpinner=false;

              }).catch(
              error=>this.mensajeError=error) 
 


        	// console.log(data);
        	// this.remitos=this.acomodaRemitos(data);
        	// console.log(this.remitos);
        	// this.showSpinner=false;
          }
      	},
          error =>{console.log(error);
              this.showSpinner=false;
              this.mensajeError=" buscarRemitos Se produjo un error: /n nombre: " +error.name+"/n Mensaje: "+error.message+"/n";

         }
         
   	);
 }





acomodaRemitos(remitos){
  console.log('acomodaRemitos remitos',remitos);

   let remitoAcomodado=new Array();
   let indexRemitoAcomodado=0;
   let safe=0;
   for( let i=0;i<remitos.length;i++){
     console.log('acomodaRemitos remitos i',i);
     console.log('acomodaRemitos remitos remitos[i]',remitos[i]);
     safe++;
     if(safe>100){
         break;
     }
     let r={
       N_REMITO:remitos[i].N_REMITO,
       FECHA_REMITO:remitos[i].FECHA_MOV,
       ESTADO:remitos[i].ESTADO_MOV,
       ACEPTA_ENTREGA_SIN_CLIENTE:remitos[i].ACEPTA_ENTREGA_SIN_CLIENTE,
       ID_INFOTRAK:remitos[i].ID_INFOTRAK,
       Desc_Estado:remitos[i].Desc_Estado,
       NOMBRE_TRA:remitos[i].NOMBRE_TRA,
        ESTADO_ENTREGA_TRANSPORTISTA:remitos[i].ESTADO_ENTREGA_TRANSPORTISTA,
       detalle:new Array()
     };

     remitoAcomodado.push(r);
         console.log('acomodaRemitos remitoAcomodado[indexRemitoAcomodado][N_REMITO]',remitoAcomodado[indexRemitoAcomodado]['N_REMITO']);
        let idAProcesar=0;
      for( let j=0;j<remitos.length-i;j++){ //la longitud que falta recorrer es el total menos i (es lo recorrido)
        idAProcesar=i+j;
             console.log('acomodaRemitos pedidos j',j);
        console.log('acomodaRemitos remitos[(i+j)].N_REMITO',remitos[(idAProcesar)].N_REMITO);

      if(remitoAcomodado[indexRemitoAcomodado]['N_REMITO']==remitos[(idAProcesar)].N_REMITO){
         remitoAcomodado[indexRemitoAcomodado]['detalle'].push(remitos[(idAProcesar)]);
          console.log('acomodaRemitos encontro Iguales',remitos[(idAProcesar)]);
          continue;
      } else {
        console.log('acomodaRemitos  sale i+j',(idAProcesar));
        indexRemitoAcomodado++;

        break; // esto lo puedo hacer porque el listado de pedidos viene ordenado por Nro pedido
      }
     }
      // para que no tome el último caso para por acá cuando agrega el último objeto y sale del for
      // no sale por el else porque ya no hay mas remitos en otros casos siempre hay un objeto para evaluar
      // y sale por el else porque se trata de distintos objetos.
     if(idAProcesar==remitos.length-1){
         break;
     }
     i=idAProcesar-1;

   }
  console.log('acomodaRemitos pedidoAcomodado',remitoAcomodado);
  return remitoAcomodado;
 }




mostrarMapa(idInfotrak){
console.log('buscarEmpresa');

const modalRef =    this._modal.open(MapaComponent);
    modalRef.componentInstance.idInfotrak = idInfotrak;
    modalRef.result.then(result=>{
            console.log("result: "+result);
            console.log("result.empresa: "+result.empresa);
            console.log("result.empresa: "+result.empresa.COD_CLIENT);
            console.log("result.cause: "+result.cause);
            if(result.empresa){

               let e={"COD_CLIENT": result.empresa.COD_CLIENT,
                   "RAZON_SOCI": result.empresa.RAZON_SOCI,
                   "NOM_COM": result.empresa.NOM_COM,
                   "CUIT": result.empresa.CUIT,
                   "LOCALIDAD": result.empresa.LOCALIDAD};

            }

          },reason=>{
            console.log("rison: "+reason);
             if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return  `with: ${reason}`;
          }
          } );
           console.log('ss');

}





ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
         // this.subscriptionEmpresaSelected.unsubscribe();
        }


}
